import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { authRoles } from "../../auth/authRoles";
import Documentos from "./Documentos";
/*const Documentos = Loadable(lazy(() => import('./Documentos')));*/

const documentosRoutes = [
  { path: "/documentos/:Identificador", element: <Documentos /> },
  { path: "/documentos/:Identificador/junta/:IdJunta", element: <Documentos /> },
];

export default documentosRoutes;
