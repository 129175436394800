import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { beforeAuthStateChanged, getAuth } from "firebase/auth";

import {
  getDatabase,
  ref,
  onValue,
  query,
  orderByChild,
  limitToLast,
  equalTo,
  serverTimestamp,
} from "firebase/database";
import { getStorage, ref as refSt, listAll } from "firebase/storage";
import { f } from "html2pdf.js";
import { sub } from "date-fns";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
let databaseUrl = process.env.REACT_APP_DATABASE_URL; // Base de datos por defecto
let adminDatabaseUrl = process.env.REACT_APP_DATABASE_ADMIN_URL; // Base de datos datos

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: databaseUrl,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
let app1 = initializeApp(firebaseConfig);

// Initialize Firebase Admin
firebaseConfig.databaseURL = adminDatabaseUrl;

let appAdmin = initializeApp(firebaseConfig, "appAdmin");

class Firebase {
  constructor() {
    const app = app1;
    const analytics = getAnalytics(app);
    this.auth = getAuth(app);

    try {
      this.uid = this.auth.currentUser.uid;
    } catch {
      //console.log('NO TENGO UID???', JSON.stringify(this.auth));
    }

    // Initialize Realtime Database and get a reference to the service
    this.db = getDatabase(app);
    this.dbAdmin = getDatabase(appAdmin);
    this.serverTimestamp = serverTimestamp(app);
    // Initialize Cloud Storage and get a reference to the service
    this.storage = getStorage(app);
    // Initialize the functions
    //this.functions = getFunctions(app);
  }

  /*
    storage_propio_completo = (uid) => { return (uid ? this.storage_propio.ref(`${uid}`) : this.storage_propio.ref()) };
  
    //almacen =(url) => app.storage(url);
  
    doCreateUserWithEmailAndPassword = (email, password) =>
      this.auth.createUserWithEmailAndPassword(email, password);
  
    doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);
  
    doSignOut = () => this.auth.signOut();
  
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  
    doPasswordUpdate = password =>
      this.auth.currentUser.updatePassword(password);
  */

  carpetas = () => ref(this.db, "Carpetas");
  fichar = (uid) => ref(this.db, `Fichar/${uid}`);
  rol = (uid) => ref(this.db, `Roles/${uid}`);
  roles = () => ref(this.db, "Roles");
  rol2 = (uid) => ref(this.db, `Roles2/${uid}`);
  roles2 = () => ref(this.db, "Roles2");
  categorias = () => ref(this.db, "Categorias");
  categoria = (uid) => ref(this.db, `Categorias/${uid}`);
  actividad_tipo = () => ref(this.db, "Actividad_Tipo");
  despacho = () => ref(this.db, "Despacho");
  despachos = () => ref(this.db, "Despachos");
  flujoCorreos = () => ref(this.db, "Flujocorreos");
  flujoCorreo = (uid) => ref(this.db, `Flujocorreos/${uid}`);
  reservaszona = () => ref(this.db, "Reservaszona");
  reservazona = (uid) => ref(this.db, `Reservaszona/${uid}`);
  zonascomunes = () => ref(this.db, "Zonascomunes");
  zonacomun = (uid) => ref(this.db, `Zonascomunes/${uid}`);
  dispositivos = () => ref(this.db, "Dispositivos");
  //dispositivo = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Dispositivos/${uid}`):this.db.ref(`Dispositivos/${uid}`));// this.db.ref(`Dispositivos/${uid}`);
  userss = (uid) => ref(this.db, `Users/${uid}`);
  user = (uid) => ref(this.db, `Usuarios/${uid}`);
  users = () => ref(this.db, "Usuarios");
  inci = (uid) => ref(this.db, `Incidencias/${uid}`);
  incis = () => ref(this.db, "Incidencias");
  comu = (uid) => ref(this.db, `Comunidad/${uid}`);
  comus = () => ref(this.db, "Comunidad");
  activ = (uid) => ref(this.db, `Actividad/${uid}`);
  votaciones = () => ref(this.db, "Votaciones");
  votacion = (uid) => ref(this.db, `Votaciones/${uid}`);
  activs = () => ref(this.db, "Actividad");
  gremio = (uid) => ref(this.db, `Gremio/${uid}`);
  gremios = () => ref(this.db, "Gremio");
  dispo = (uid) => ref(this.db, `Dispositivos/${uid}`);
  estados = () => ref(this.db, "Estados");
  tipos = () => ref(this.db, "Tipos");
  puestosdeTrabajo = () => ref(this.db, "PuestoTrabajo");
  puestodeTrabajo = (uid) => ref(this.db, `PuestoTrabajo/${uid}`);
  soloPresi = (uid) => ref(this.db, `SoloPresi/${uid}`);
  SoloPresis = () => ref(this.db, "SoloPresi");
  GrupoRoles = () => ref(this.db, "GrupoRoles");
  notificaciones = () => ref(this.db, "Notificaciones");
  notificacion = (uid) => ref(this.db, `Notificaciones/${uid}`);
  doSignOut = () => this.auth.signOut();

  getReferencia = (tabla, identifier = null, limit = 0, filter = null) => {
    let referencia = null;
    if (tabla) {
      if (this.codeDespacho) {
        if (!identifier) {
          referencia = ref(this.db, this.codeDespacho + "/" + tabla);
        } else {
          referencia = ref(this.db, this.codeDespacho + "/" + tabla + "/" + identifier);
        }
      } else {
        if (!identifier) {
          referencia = ref(this.db, tabla);
        } else {
          referencia = ref(this.db, tabla + "/" + identifier);
        }
      }
    }

    if (filter) {
      referencia = query(referencia, orderByChild(filter.field));
      referencia = query(referencia, equalTo(filter.value));
    }
    // Si recibo un límite hago una query pasándole el límite de rtdos
    if (limit > 0) {
      referencia = query(referencia, limitToLast(limit));
    }

    return referencia;
  };

  getDatosTabla = (tabla, identifier = null) => {
    let datos = [];
    const referencia = this.getReferencia(tabla, identifier);
    if (referencia) {
      onValue(referencia, (snapshot) => {
        datos.push(snapshot.val());
      });
    }
    return datos;
  };

  // Ver si haría falta o no...
  getDatosTablaAsync = async (tabla, identifier = null) => {
    try {
      let datos = null;
      const referencia = this.getReferencia(tabla, identifier);
      if (referencia) {
        datos = [];

        const queryT = query(referencia, orderByChild("Cif"));

        onValue(referencia, (snapshot) => {
          datos.push(snapshot.val());
        });
      }
      return datos;
    } catch (error) {
      console.error("Error getting data:", error);
    }
  };
  generateUniqueId = () => {
    // Generate a random number and convert it to a base36 string
    const randomPart = Math.random().toString(36).slice(2, 7);

    // Get the current timestamp to add uniqueness
    const timestampPart = Date.now().toString(36);

    // Combine the random and timestamp parts to create the unique ID
    const uniqueId = randomPart + timestampPart;

    return uniqueId;
  };

  getFolderContents = async (path = "", first = true) => {
    try {
      // Reference to the folder in Firebase Storage
      const folderRef = refSt(this.storage, path);
      const items = await listAll(folderRef);

      let contents = [];

      // Extract files in the current folder
      const files = items.items.map((fileRef) => fileRef._location.path);

      // Extract subfolders and their contents recursively
      await Promise.all(
        items.prefixes.map(async (subfolderRef) => {
          const subfolderPath = subfolderRef._location.path;
          const subfolderContents = await this.getFolderContents(subfolderPath, false);
          let ficheros = [];
          if (subfolderContents.files) {
            const leaves = subfolderContents.files;
            leaves.map((nombreFichero) => {
              ficheros.push({
                id: nombreFichero,
                name: nombreFichero.split("/").pop(),
                isLeaf: true,
                isFolder: false,
              });
            });
          }
          let carpetas = [];
          if (subfolderContents.folders) {
            const folds = subfolderContents.folders;
            folds.map((fichero) => {
              carpetas.push({
                id: fichero.name,
                name: fichero.name.split("/").pop(),
                children: this.getFolderContents(fichero.name, false),
                isFolder: true,
              });
            });
          }

          const superCombinado = [];
          carpetas.map((carpeta) => {
            superCombinado.push(carpeta);
          });
          ficheros.map((fichero) => {
            superCombinado.push(fichero);
          });

          // Combine files and folders from the subfolder
          contents.push({
            id: subfolderPath,
            name: subfolderPath.split("/").pop(),
            children: subfolderContents.files,
            path: subfolderPath,
            files: subfolderContents.files,
            folders: subfolderContents.folders,
          });
        })
      );

      let currPathElements = null;
      // Orden alfabético
      const orderedContents = contents.sort((a, b) => a.path.localeCompare(b.path));

      const superCombinado = [];
      contents.map((carpeta) => {
        superCombinado.push(carpeta);
      });
      files.map((fichero) => {
        if (!fichero.includes("temporal.txt")) {
          const megaFile = {
            id: fichero,
            name: fichero.split("/").pop(),
            isFile: true,
          };
          superCombinado.push(megaFile);
        }
      });

      if (!first) {
        currPathElements = {
          path: path,
          files: files,
          folders: superCombinado,
          name: path.split("/").pop(),
          id: path,
          children: contents,
        };
      } else {
        currPathElements = superCombinado;
        //console.log('currPathElements', currPathElements);
      }
      return currPathElements;
    } catch (error) {
      console.error("Error listing items:", error);
    }
  };

  changeDatabase(database = null) {
    if (database) {
      this.db = getDatabase(app1, database);
    } else {
      this.db = getDatabase(app1);
    }
  }
  changeStorage(storage = null) {
    if (storage) {
      this.storage = getStorage(app1, storage);
    } else {
      this.storage = getStorage(app1);
    }
  }
}

export default Firebase;
