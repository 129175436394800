import moment from "moment";
import {
  getDatabase,
  ref,
  onValue,
  query,
  orderByChild,
  limitToLast,
  equalTo,
  orderByValue,
  get,
  push,
  update,
} from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { use } from "echarts";
import { diccionario_cat } from "lenguajes/lenguaje_cat";
const aplanar = (data) => {
  return data.reduce((acc, item) => {
    acc[item.DNI] = item;
    return acc;
  }, {});
};
function enviarAvisos(
  comunidades,
  texto_envio,
  texto_titulo,
  texto_url,
  firebase,
  path,
  pdfEnviar,
  id,
  Categorias,
  logueado,
  despacho,
  roles,
  Votacion,
  Pdfs,
  ConfirmacionPorEmail = false,
  usuariosSeleccionados = null,
  setIdJunta = null,
  Junta = null,
) {

  //id = es el tipo de nodificacion que vamos a mandar, =1 envio notificacion de docubuscarDispositivosComunidadmentación
  return new Promise((resolve) => {
    //por cada comunidad buscamos sus usuarios y sus dispostivos
    var tipo = id === "1" ? "documento" : Votacion ? "votacion" : null;
    const getData = async () => {
      return Promise.all(
        comunidades.map((comunidad) => {
          return buscarDispositivosComunidad(
            comunidad,
            firebase,
            Categorias,
            roles,
            tipo,
            usuariosSeleccionados
          );
        })
      );
    };

    getData()
      .then((data) => {
        //         const usuari = data[0].usuarios
        var dispositivos = [];
        var usuarios = [];
        //       if(usuari&&usuari.length>0){
        data.forEach((registro) => {
          if (
            registro.dispositivos.length > 0 ||
            registro.usuarios.length > 0 ||
            (Array.isArray(usuariosSeleccionados) && usuariosSeleccionados.length > 0)
          ) {
            dispositivos = dispositivos.concat(registro.dispositivos);
            usuarios = usuarios.concat(registro.usuarios);
          }
        });
        //     }
        //if (usuarios.length > 0) {
        // REVISAR ESTA EXTRAÑA CONDICION
        if (1 === 1) {
          buscarDispositivosAdmin(firebase, despacho).then((listaUsuarios) => {
            usuarios = usuarios.concat(listaUsuarios.usuarios);

            //if (window.confirm('¿Deseas envíar el aviso también a todos los implicados por email?')) {
            if (ConfirmacionPorEmail) {
              let Pdfss = Pdfs;
              if (Pdfss && Pdfss.length > 0) {
                //Tenemos que incluir en el path la lista de enlaces
                if (pdfEnviar !== "") {
                  Pdfss = Pdfs.filter((pdff) => pdff.Nombre === pdfEnviar);
                  //el mensaje va asociado sobre solo 1 documento
                }
              }

              const despachoCode = firebase.despachoCode;

              // Seguir por aquí
              //var envioEmailAviso = firebase.getTheFunctions.httpsCallable('envioEmailAviso');
              const functions = getFunctions(firebase.app, process.env.REACT_APP_FIREBASE_ZONE);

              //var envioEmailAviso = httpsCallable(functions, "envioEmailAviso");
              var sendEmailNotification = httpsCallable(functions, "sendEmailNotification");
              

              //const envioEmailAviso = httpsCallableFromURL(functions, 'http://192.168.254.51:5000/conecta-c365/europe-west1/envioEmailAviso')
              // COMENTADO DE MOMENTO QUITAR EN UN RATO
              //return 0;

              /*
              //return 0;
              */
              console.log(
                "BaseDatos",
                logueado.currentDB,
                "titulo",
                texto_titulo,
                "texto",
                texto_envio,
                "url",
                texto_url,
                "path",
                path,
                "usuarios",
                usuarios,
                "Code",
                despachoCode,
                "Pdfs",
                Pdfss
              );
              /*
              envioEmailAviso ===> function antigua...
              */


              sendEmailNotification({
                BaseDatos: logueado.currentDB,
                titulo: texto_titulo,
                texto: texto_envio,
                url: texto_url,
                path: path,
                usuarios: usuarios,
                Code: logueado.despacho.Codigo,
                Pdfs: Pdfss,
              })
                .then((res) => {
                  console.log(
                    "Se ha realizado en el envío de email correctamente" + JSON.stringify(res)
                  );

                  //('Envío correcto',res);
                })
                .catch((e) => {
                  //alert(e);
                  console.log("Envío fallido", e);
                });
            }

            dispositivos = dispositivos.concat(listaUsuarios.dispositivos);

            if (usuarios.length > 0) {
              //Aqui meter el envío de email

              guardarNotificaciones(
                firebase,
                usuarios,
                texto_envio,
                texto_titulo,
                texto_url,
                path,
                pdfEnviar,
                logueado,
                comunidades,
                Votacion,
                Junta
              ).then((key) => {
                setIdJunta && setIdJunta(key);
                //if (dispositivos.length > 0 && usuarios.length > 0) {
                if (dispositivos.length > 0 || usuarios.length > 0) {
                  enviaAviso(
                    dispositivos,
                    usuarios,
                    texto_envio,
                    texto_titulo,
                    firebase,
                    path,
                    pdfEnviar,
                    id,
                    key
                  ).then((result) => {
                    const resultado = cargarResultado(result, "", "", logueado);
                    update(firebase.notificacion(key), { Resultado: resultado.archivo_final });
                    resolve(result);
                  });
                } else {
                  resolve(false);
                }
              });
            } else {
              const re = [];
              resolve(re);
            }
          });
        } else {
          resolve(false);
        }
        //})
      })
      .catch((e) => {
        console.log("OOOO", e);
      });
  });
}

export function buscarUsuariosAdmin(firebase, despacho) {
  var usuariosList = [];
  var usuariosList2 = [];
  return new Promise((resolve) => {
    firebase
      .users()
      .orderByChild("Administrador")
      .equalTo(true)
      .once("value", (snapshot) => {
        const usersObject = snapshot.val();
        if (usersObject) {
          usuariosList = Object.keys(usersObject).map((key) => ({
            ...usersObject[key],
            uid: key,
          }));
          //            usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === false )
          usuariosList = usuariosList.filter((usuario) => usuario.Borrado === false);

          usuariosList.forEach((usuario) => {
            usuario.Comunidad = despacho.Com_interna;
            usuariosList2 = usuariosList2.concat(usuario);
          });

          //   usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === true )
          resolve(usuariosList2);
        } else {
          resolve([]);
        }
      });
  });
}

export async function guardarNotificaciones(
  firebase,
  usuarios,
  texto_envio,
  texto_titulo,
  texto_url,
  path,
  pdfEnviar,
  logueado,
  comunidades2,
  Votacion,
  Junta
) {
  const refUsuarioRegistro = firebase.getReferencia(`Usuarios/${logueado.uid}`);

  const snapUsu = await get(refUsuarioRegistro);
  const usuario1 = snapUsu.val() ? snapUsu.val() : logueado.uid;
  const NombreApellidoRegistrador = usuario1.Nombre + " " + usuario1.Apellido;
  return new Promise((resolve) => {
    // var path1=''
    // if(path&&path!==''){path1=path}
    var doc = "";
    if (pdfEnviar && pdfEnviar !== "") {
      doc = pdfEnviar;
    }
    let comus = [];
    let usur = [];
    usuarios.forEach((usu) => {
      const lacomu = comus.find((com) => com === usu.Comunidad);
      if (!lacomu && usu.Comunidad) {
        comus = comus.concat(usu.Comunidad);
      }

      usur = usur.concat({ Usuario: usu.uid, Fecha_lectura: "", Leida: false });
    });

    push(firebase.notificaciones(), {
      Usuarios: usur,
      Tipo: "AVISO",
      Doc: doc,
      Path: path,
      Fecha: moment(new Date()).format("DD-MM-YYYY HH:mm"),
      Texto: texto_envio,
      Titulo: texto_titulo,
      Usuario_Registro: NombreApellidoRegistrador,
      Comunidades: comunidades2,
      Url: texto_url ? texto_url : "",
      Votacion: Votacion ? Votacion : null,
      Junta: Junta ? Junta : null,
    })
      .then((key) => {
        const key1 = key.key;
        resolve(key1);
      })
      .catch((e) => console.log(e));
  });
}

export function buscarDispositivosAdmin(firebase, despacho) {
  var usuariosList = [];
  var usuariosList2 = [];

  return new Promise((resolve) => {
    let dbRef = firebase.getReferencia("Usuarios");
    dbRef = query(dbRef, orderByChild("Administrador"));
    dbRef = query(dbRef, equalTo(true));

    get(query(dbRef))
      .then((snapshot) => {
        const usersObject = snapshot.val();
        if (usersObject) {
          usuariosList = Object.keys(usersObject).map((key) => ({
            ...usersObject[key],
            uid: key,
          }));
          //            usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === false )
          usuariosList = usuariosList.filter((usuario) => usuario.Borrado === false);

          //   usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === true )
          usuariosList.forEach((usuario) => {
            usuario.Comunidad = despacho && despacho.Com_interna;
            usuariosList2 = usuariosList2.concat(usuario);
          });
        }
      })
      .then(() => {
        const getData = async () => {
          return Promise.all(
            usuariosList2.map((usuario) => buscarDispositivosUsuario(usuario.uid, firebase))
          );
        };

        getData().then((data) => {
          var dispositivos = [];
          data.map((dispositivo) => (dispositivos = dispositivos.concat(dispositivo)));
          const registro = {
            dispositivos: dispositivos,
            usuarios: usuariosList2,
          };
          resolve(registro);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  });
}
export function enviarDispositivosAdmin(
  texto_envio,
  texto_titulo,
  firebase1,
  PathDirectorioActual,
  pdfEnviar,
  id,
  envioPrevio
) {
  return new Promise((resolve) => {
    buscarDispositivosAdmin(firebase1).then((dispo) => {
      var dispositivos = [];
      var usuarios = [];

      dispositivos = dispositivos.concat(dispo.dispositivos);
      usuarios = usuarios.concat(dispo.usuarios);

      //Envíamos aviso a los administradores
      guardarNotificaciones(
        firebase1,
        usuarios,
        texto_titulo,
        texto_envio,
        PathDirectorioActual,
        pdfEnviar
      ).then((key) => {
        //     resolve (enviaAviso(dispositivos,usuarios,texto_envio,texto_titulo, firebase,path,pdfEnviar,id,key))

        // SEGUIR POR AQUI MAÑANA
        enviaAviso(
          dispositivos,
          usuarios,
          texto_envio,
          texto_titulo,
          firebase1,
          PathDirectorioActual,
          pdfEnviar,
          id,
          key
        ).then((result) => {
          update(firebase1.notificacion(key), { Resultado: result });
          resolve(result);
        });
      });
    });
  });
}

export function enviaAviso(
  dis,
  usuarios,
  texto_envio,
  texto_titulo,
  firebase,
  path,
  pdfEnviar,
  id,
  key
) {
  var tokensList = [];
  dis.map((dispo) => (tokensList = tokensList.concat(dispo.Dispositivos)));

  const functions = getFunctions(firebase.app, process.env.REACT_APP_FIREBASE_ZONE);
  var messaging = httpsCallable(functions, "messaging");

  //var messaging = firebase.functions.httpsCallable('messaging');
  // alert(id)

  //dividimos token list en vectores de 400 unidades
  var tokens = [];
  const size = 400;

  while (tokensList.length > 0) {
    tokens.push(tokensList.splice(0, size));
  }

  return new Promise((resolve, reject) => {
    const getData = async () => {
      return Promise.all(
        tokens.map((tokenes) => {
          return new Promise((resolve) => {
            //       const resul ={'Data':[path]}
            //     resolve (resul)
            messaging({
              texto: texto_envio,
              titulo: texto_titulo,
              tokens: tokenes,
              path: path,
              pdfEnviar: pdfEnviar,
              id: id,
              key: key,
            })
              .then(function (result) {
                // Read result of the Cloud Function.
                const resul = { Data: result.data };

                resolve(resul);
              })
              .catch(function (error) {
                // Getting the Error details.
                console.log("Error:", error, {
                  texto: texto_envio,
                  titulo: texto_titulo,
                  tokens: tokenes,
                  path: path,
                  pdfEnviar: pdfEnviar,
                  id: id,
                  key: key,
                });
                //return error;
                reject(error);
                // ...
              });
          });
        })
      );
    };

    getData().then((data) => {
      var Data = [];
      data.forEach((registro) => {
        const drata = registro.Data;
        if (drata && drata.length > 0) {
          Data = Data.concat(drata);
        }
      });

      const registro = { Dispositivos: dis, Usuarios: usuarios, Data: Data };
      resolve(registro);
    });
  });
}
const buscarDispositivosComunidad = async (
  comunidad,
  firebase,
  Categorias,
  roles,
  tipo,
  usuariosSeleccionados
) => {
  try {
    let usuariosList = [];

    console.log("oooo", comunidad, firebase, Categorias, roles, tipo, usuariosSeleccionados);

    // Cambiar al nuevo modo de asociación con comunidad
    /*
    const usersSnapshot = await get(
      query(
        firebase.getReferencia("Usuarios"),
        orderByChild("Comunidad"),
        equalTo(comunidad)
      )
    );

    let usersObject = usersSnapshot.val();
    */
    let usuariosTmp = [];
    let usersObject = null;

    // Cambiar al nuevo modo de asociación con comunidad
    const usuariosRef = firebase.getReferencia("Usuarios");
    let usersVal = await get(usuariosRef);
    if (usuariosSeleccionados && usuariosSeleccionados.length > 0) {
      const usuariosFiltrados = usuariosSeleccionados.filter(
        (usuario) => usuario.Comunidades && usuario.Comunidades[comunidad]
      );
      usersVal = aplanar(usuariosFiltrados);
    } else {
      usersVal = usersVal.val();
    }
    for (const key in usersVal) {
      if (Object.hasOwnProperty.call(usersVal, key)) {
        const obj = usersVal[key];
        if (obj.Comunidades && Object.keys(obj.Comunidades).includes(comunidad)) {
          //usuariosTmp.push(obj);
          usuariosTmp[key] = obj;
        } else if (usuariosSeleccionados && usuariosSeleccionados.length > 0) {
          usuariosTmp[key] = obj;
        }
      }
    }

    usersObject = usuariosTmp;

    if (usersObject) {
      usuariosList = Object.keys(usersObject).map((key) => ({
        ...usersObject[key],
        uid: key,
      }));
      usuariosList = usuariosList.filter((usuario) => !usuario.Borrado && !usuario.Administrador);
      let listU = [];
      if (roles && roles.length > 0) {
        usuariosList = usuariosList.filter((usuario) => {
          //const RolesUsuario = usuario.Roles;
          // Cambio roles a su posición actual
          const RolesUsuario = usuario["Comunidades"][comunidad]["Roles"];
          //console.log("kaka",usuario.Nombre,RolesUsuario);
          //if (!RolesUsuario || RolesUsuario.length < 1) return false;

          if (RolesUsuario && RolesUsuario.some((rol) => roles.includes(rol))) {
            listU.push(usuario);
          }

          //return RolesUsuario && RolesUsuario.some((rol) => roles.includes(rol));
        });
      } else {
        listU = usuariosList;
      }
      usuariosList = listU;
      if (Categorias && Categorias.length > 0) {
        usuariosList = usuariosList.filter((usu) => {
          if (usu.Categorias && usu.Categorias.length > 0) {
            return usu.Categorias.some((catt) => Categorias.includes(catt));
          }
          return false;
        });
      }
    }

    //const roles2Snapshot = await firebase.database().ref('roles2').once('value');
    const roles2Snapshot = await get(query(firebase.getReferencia("Roles2")));
    const roles2Object = roles2Snapshot.val();

    if (tipo && usuariosList.length > 0 && roles2Object) {
      let roles2 = Object.keys(roles2Object).map((key) => ({
        ...roles2Object[key],
        uid: key,
      }));
      roles2 = roles2.filter(
        (rol) => !rol.Todo && (tipo === "votacion" ? !rol.Juntas : !rol.Documentos)
      );

      if (roles2.length > 0) {
        const elroles = roles2.map((rola) => rola.Nombre);

        usuariosList = usuariosList.filter((usuario) => {
          //const RolesUsuario = usuario.Roles;
          // Cambio roles a su posición actual
          const RolesUsuario = usuario["Comunidades"][comunidad]["Roles"];
          if (!RolesUsuario || RolesUsuario.length < 1) return true;

          return !RolesUsuario.some((rol) => elroles.includes(rol));
        });
      }
    }

    if (usuariosList.length > 0) {
      const getData = async () => {
        return Promise.all(
          usuariosList.map(
            (
              usuario // 666 REVISAR POR AQUI
            ) => buscarDispositivosUsuario(usuario.uid, firebase)
          )
        );
      };

      const data = await getData();
      const dispositivos = data.reduce((acc, dispositivo) => acc.concat(dispositivo), []);
      const registro = { dispositivos, usuarios: usuariosList };
      return registro;
    } else {
      return { dispositivos: [], usuarios: [] };
    }
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};

export function buscarDispositivosUsuario(id, firebase) {
  var dispositivosList = [];

  return new Promise((resolve) => {
    const dbRef = firebase.getReferencia(`Dispositivos/${id}`);
    get(dbRef)
      .then((snapshot) => {
        const usersObject = snapshot.val();
        if (usersObject) {
          dispositivosList = Object.entries(usersObject).map(([key, val]) => ({
            Dispositivos: key + ":" + val,
            uid: id,
          }));
          resolve(dispositivosList);
        } else resolve(dispositivosList);
      })
      .catch((error) => {
        console.error(error);
      });
  });
}

/*
    firebase.dispo(id).once('value', (snapshot) => {
      const usersObject = snapshot.val();
      if (usersObject) {
        dispositivosList = Object.entries(usersObject).map(([key, val]) => ({
          Dispositivos: key + ':' + val,
          uid: id,
        }));
        resolve(dispositivosList);
      } else resolve(dispositivosList);
    });
    
  });
}
*/
export function cargarResultado(data2, data3, firebase, logueado) {
  var Usuarios = [];
  var Dispositivos = [];
  var Data = [];

  if (data2) {
    if (data2.Dispositivos && data2.Dispositivos.length > 0) {
      Dispositivos = Dispositivos.concat(data2.Dispositivos);
    }
    if (data2.Data && data2.Data.length > 0) {
      Data = Data.concat(data2.Data);
    }
    if (data2.Usuarios && data2.Usuarios.length > 0) {
      Usuarios = Usuarios.concat(data2.Usuarios);
    }
  }
  var usuarios_enviar = Usuarios;

  if (!logueado.SuperUser) {
    usuarios_enviar = Usuarios.filter((usuario) => usuario.SuperUser !== true);
  }

  var dispositivos = [];
  Dispositivos.forEach((dispo) => {
    const disposi = Data.filter((di) => di.tokens === dispo.Dispositivos);
    var dispo2 = dispo;
    if (disposi && disposi.length > 0) {
      dispo2.error = true;
      dispo2.codigo = disposi[0].error.errorInfo.code;
      dispo2.mensaje = disposi[0].error.errorInfo.message;
    } else {
      dispo2.error = false;
      dispo2.codigo = "";
      dispo2.mensaje = "";
    }
    dispositivos = dispositivos.concat(dispo2);
  });

  var lista_final = [];
  var lista_sinerror = [];
  usuarios_enviar.forEach((usuario) => {
    const usu = dispositivos.filter((dispo) => dispo.uid === usuario.uid);
    if (usu && usu.length > 0) {
      usu.forEach((usu2) => {
        var Obj = { uid: usuario.uid, DNI: usuario.DNI, Nombre: usuario.Nombre, ...usu2 };
        lista_final = lista_final.concat(Obj);
        if (usu2.error === false) {
          lista_sinerror = lista_sinerror.concat(Obj);
        }
      });
    } else {
      const usu2 = {
        error: true,
        Dispositivos: "NO DISPONE DE DISPOSITIVO ASOCIADO",
      };

      var Obj = { uid: usuario.uid, DNI: usuario.DNI, Nombre: usuario.Nombre, ...usu2 };
      lista_final = lista_final.concat(Obj);
    }
  });

  var archivo_final = lista_final;
  // Esta grupo comprueba que si algun usuario se le ha enviado el aviso a mas de un dispositivo, y al menos uno ha ido bien, el resto los descarta, deberiamos borrar estos tokens malos
  /* lista_final.forEach((dispo) => {
    if (dispo.error) {
      const esta = lista_sinerror.filter(
        (disposi) => disposi.Nombre === dispo.Nombre && disposi.DNI === dispo.DNI
      );
      if (esta && esta.length > 0) {
        //borrar el dispositivo de la tabla para que no vuelva a dar error, hay al menos un dispositivo que si le ha llegado
        const tokens = dispo.Dispositivos.split(":");
        // firebase.dispo(dispo.uid + '/' + tokens[0]).remove();
        archivo_final = archivo_final.filter((archi) => archi !== dispo);
      }
    }
  });*/
  const registro = { usuariosTotal: Usuarios, archivo_final: archivo_final };
  return registro;
}
export default enviarAvisos;
