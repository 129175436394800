import React, { useState } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { useParams } from "react-router-dom";

export const Tutorial = () => {
  const { Identificador } = useParams();
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const [expandedItem, setExpandedItem] = useState(null);
  const navigate = useNavigate();
  const circleBadgeStyle = {
    display: "inline-block",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "#172983",
    color: "#fff",
    textAlign: "center",
    lineHeight: "30px",
    marginRight: "15px",
    marginLeft: "15px",
  };

  const handleItemClick = (index) => {
    setExpandedItem((prevItem) => (prevItem === index ? null : index));
  };
  const routeDetalle = `${ROUTES.DETALLE_COMUNIDAD}/${Identificador}`;
  const isItemExpanded = (index) => index === expandedItem;

  const pasoTextos = [
    {
      1: diccionario.ConfigurarProveedores,
      titulo: diccionario.ConfigurarProveedores2,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/proveedores#h.28ayf9wmvvng",
    },
    {
      2: diccionario.MarcarActividades,
      titulo: diccionario.MarcarActividades2,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/actividades#h.azn0b0ffayl5",
    },
    {
      3: diccionario.IncidenciasYproveedores,
      titulo: diccionario.AsociarProveedorAactividad,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/proveedores#h.8tho3jlzy0wa",
    },
    {
      4: diccionario.CompletaDocumentos,
      titulo: diccionario.SubirDocumentos,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/documentaci%C3%B3n#h.zeodwgg2yz43",
    },
    {
      5: diccionario.CreaVotacionesJuntas,
      titulo: diccionario.CrearJuntasYvotaciones,
      descripcionTitulo1: diccionario.CrearJuntas,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/juntas#h.o7s4mxjsoadr",
      descripcionTitulo2: diccionario.CrearVotaciones,
      descripcion2:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/votaciones#h.qdfi79fhmz62",
    },
    {
      6: diccionario.CreaZonasComunes,
      titulo: diccionario.CrearZonas,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/zonas-comunes#h.imzc2liim9wj",
    },
    {
      7: diccionario.CrearCentrosTrabajo,
      titulo: diccionario.AñadirZonasComunes2,
      descripcion:
        "https://manual.comunidad365.es/centro-de-ayuda/despacho-administrador/centros-de-trabajo-registro-horario#h.syl2w93itq60",
    },
    {
      8: diccionario.HabilitaFichajes,
      titulo: diccionario.RegistroDeUsuarios,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/usuarios#h.f6qv6in9ikd2",
    },
  ];

  return (
    <div className="container-detalleRol">
      <p>
        <strong>{diccionario.BienvenidoC365}</strong>
      </p>
      <p>{diccionario.AltaComunidadConfigurar}</p>
      <hr />
      <ol className="list-group">
        {pasoTextos.map((paso, index) => (
          <li key={index} className="list-group-item">
            <div style={circleBadgeStyle} className="mr-2">
              {index + 1}
            </div>
            <span
              className="cursor-pointer"
              onClick={() => handleItemClick(index + 1)}
              style={{ textDecoration: "underline" }}
            >
              <span style={{ fontWeight: "bold" }}>{paso.titulo}</span>
            </span>
            <br />
            <p style={{ marginLeft: "60px", marginBottom: "0px" }}>
              {" "}
              {Object.values(paso)[0] + " "}{" "}
            </p>
            <div style={{ display: "flex", marginLeft: "40px" }}>
              <div
                style={{ marginLeft: "20px", display: "flex" }}
                //   dangerouslySetInnerHTML={{ __html: Object.values(paso)[1] }}
              >
                {" "}
                {paso.descripcionTitulo1 && (
                  <p style={{ paddingRight: "5px" }}>{paso.descripcionTitulo1 + " :"}</p>
                )}
                <a href={paso.descripcion} target="_blank" style={{ paddingRight: "20px" }}>
                  {diccionario.AbrirEnlacePestanaNueva + "   "}
                </a>
                {paso.descripcion2 && (
                  <>
                    <br />
                    {paso.descripcionTitulo2 && (
                      <p style={{ paddingRight: "5px" }}>{paso.descripcionTitulo2 + " :"}</p>
                    )}

                    <a href={paso.descripcion2} target="_blank" style={{ paddingRight: "20px" }}>
                      {diccionario.AbrirEnlacePestanaNueva + "   "}
                    </a>
                  </>
                )}
              </div>
            </div>
          </li>
        ))}
      </ol>
      <div style={{ marginTop: "20px" }}>
        <button className="btn btn-outline-primary" onClick={() => navigate(routeDetalle)}>
          {diccionario.Volver}
        </button>
      </div>
    </div>
  );
};

export default Tutorial;
