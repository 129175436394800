import {diccionario_cat,tableLocation_cat} from './lenguaje_cat';
import {diccionario_es,tableLocation_es} from './lenguaje_es';
import {diccionario_en,tableLocation_en} from './lenguaje_en';
import {diccionario_eu,tableLocation_eu} from './lenguaje_eu';
import {diccionario_gl,tableLocation_gl} from './lenguaje_gl';

export const lenguaje={
    ESP :{
        diccionario:diccionario_es,
        lenguaje:'ESP',
        traduccionTabla: tableLocation_es,

    },
    CAT :{
        diccionario:diccionario_cat,
        lenguaje:'CAT',
        traduccionTabla: tableLocation_cat,

    },
    ENG :{
        diccionario:diccionario_en,
        lenguaje:'ENG',
        traduccionTabla: tableLocation_en,

    },
    EUS :{
        diccionario:diccionario_eu,
        lenguaje:'EUS',
        traduccionTabla: tableLocation_eu,

    },
    GAL :{
        diccionario:diccionario_gl,
        lenguaje:'GAL',
        traduccionTabla: tableLocation_gl,

    }
}