import Loadable from "app/components/Loadable";
import { lazy } from "react";

const JuntasTabla = Loadable(lazy(() => import("./JuntasTabla")));
const AnadirJunta = Loadable(lazy(() => import("./AnadirJunta")));
const JuntaDetalle = Loadable(lazy(() => import("./JuntaDetalle")));
//const ImagenQRJunta = Loadable(lazy(() => import("./ImagenQRJunta")));

const JuntasRoutes = [
  { path: "/juntas/:Identificador", element: <JuntasTabla /> },
  { path: "/addjunta/:Identificador", element: <AnadirJunta /> },
  { path: "/detalle_junta/:IdComu/:Identificador", element: <JuntaDetalle /> },
  { path: "/detalle_junta/:IdComu/:Identificador/:volver", element: <JuntaDetalle /> },
  //{ path: "/img_qr_junta/:IdComu/:Identificador", element: <ImagenQRJunta /> },
];

export default JuntasRoutes;
