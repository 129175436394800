import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { useStore } from "react-redux";

export const MenuCentralContenido = ({
  contenido,
  nombreMenu,
  activarBuscador,
  modoGigante = "",
}) => {
  /* estado que filtra por el buscador y lo muestra en el return con un map */
  const [filtro, setFiltro] = useState("");
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;

  /* este objeto contiene los datos que se muestran en el return */

  /* funcion coge el valor del input y actualiza el estado */
  const buscador = (e) => {
    setFiltro(e.target.value);
  };

  const contenido2 = contenido.filter((elemento) => {
    return (
      (elemento.titulo && elemento.titulo.toLowerCase().includes(filtro.toLowerCase())) ||
      (elemento.texto && elemento.texto.toLowerCase().includes(filtro.toLowerCase()))
    );
  });

  const classNam = `contenedorAdmin${modoGigante} contenedorAdminHome`;

  return (
    <div className={classNam}>
      {nombreMenu && (
        <row className="filaAdmin">
          <p className="textoAdmin"> {nombreMenu}</p>
        </row>
      )}
      {activarBuscador && (
        <row className="filaAdmin">
          <TextField
            name="buscador"
            placeholder={diccionario.Buscar}
            onChange={buscador}
          ></TextField>
        </row>
      )}

      <div className="cards">
        {contenido2.map((elemento, index) => {
          return (
            <div key={index}>
              {
                <Link to={`..${elemento.ruta}`}>
                  <i className={`icon-${elemento.icono}`}></i>
                  <h5>{elemento.titulo}</h5>
                  <p>{elemento.texto}</p>
                </Link>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
};
