export const diccionario_gl = {
  hola: 'Hola',
  empleados: 'Empleados',
  empleado: 'Empleado',
  empezar: 'empezar',
  empezada: 'empezada',
  organizacion: 'Organización',
  estructura: 'Estructura',
  organizativa: 'organizativa',
  crear: 'Crear',
  visualizar: 'Visualizar',
  modificar: 'Modificar',
  listados: 'Listados',
  listado: 'Listado',
  recursos: 'Recursos Humanos',
  resumen: 'Resumen',
  evaluados: 'Evaluados',
  evaluado: 'Evaluado',
  evaluaciones: 'Evaluaciones',
  evaluacion: 'Evaluación',
  evaluada: 'Evaluada',
  pruebas: 'Pruebas',
  areas: 'Áreas',
  evainiciadas: 'Eva. iniciadas',
  evarealizadas: 'Eva. realizadas',
  ranking: 'Ranking empleados por evaluación',
  abstencion:'Abstención',
  DetalleIncidencia: "Detalle de la incidencia",
  DetalleVotacion:"Detalle de la votación",
  nombre: 'Nombre',
  Nombre: 'Nombre',
  apellidos: 'Apellidos',
  area: 'Área',
  estado: 'Estado',
  fecha: 'Fecha',
  resultado: 'Resultado',
  todas: 'Todas',
  año: 'año',
  competencias: 'competencias',
  destacadas: 'destacadas',
  buenas: 'buenas',
  normales: 'normales',
  alejadas: 'alejadas',
  submitida: 'submitida',
  submitidas: 'submitidas',
  submitir: 'submitir',
  completas: 'completas',
  periodos: 'Periodos',
  fomulario: 'Formulario',
  actualizar: 'Actualizar',
  busqueda: 'Campos de búsqueda',
  rellenar: 'Rellena un campo con el valor del empleado que deseas recuperar',
  rellenar2: 'Rellena un campo con el valor dela estructura que deseas recuperar',
  limpiarcampos: 'Limpiar campos',
  seleccionado: 'seleccionado',
  hombre: 'Hombre',
  mujer: 'Mujer',
  otros: 'Otros',
  genero: 'Género',
  cargo: 'Cargo',
  acceso: 'Acceso',
  herramienta: 'herramienta',
  registro: 'registro',
  telefono: 'Teléfono',
  inicio: 'inicio',
  Bueno: 'Bueno',
  Destacado: 'Destacato',
  Alejado: 'Alejado',
  Normal: 'Normal',
  sin: 'Sin',
  finalizar: 'finalizar',
  aviso0: 'Debes al menos responder alguna pregunta del cuestionario para empezar la evaluación',
  aviso1: 'Para poder finalizar la evaluación debes de responder todas las cuestiones',
  aviso2: "Para submitir la evaluación debes activar el botón 'Submitir'",
  aviso3: 'EVALUACÍON SUBMITIDA, cualquier modificación ponte en contacto con el administrador',
  gerente: 'Gerente',
  observaciones: 'Observaciones',
  aviso4: '¿Quieres reabrir la evaluación?',
  aviso5: '¿Quieres submitir la evaluación?, Una vez submitida ya no la podrás modificar',
  deLaOrganizacion: 'DE LA ORGANIZACIÓN (CORE)',
  PERSONALES: 'PERSONALES',
  delPuesto: 'DEL PUESTO',
  funcionales: '(FUNCIONALES)',
  INTERPERSONALES: 'INTERPERSONALES',
  delPuestoFuncionales: 'DEL PUESTO (FUNCIONALES)',
  Email: 'Email',
  Fecha_Creacion: 'F. alta',
  Id: 'Identificador',
  SOSBloqueo: 'SOS',
  Codigo_seguridad: 'Código seguridad (max 5 dígitos)',
  Codigo_vecino: 'Código del vecino para su registro',
  Cp: 'C.P.',
  Poblacion: 'Población',
  Seguro_nombre: 'Compañía de Seguros',
  tooltip: 'Compañia de seguros asociada a la Comunidad',
  Poliza: 'Póliza',
  IBAN: 'Cuenta IBAN',
  Vecinos: 'Nº Vecinos',
  Pago_App: 'Pago Mensual APP',
  Cif: 'CIF',
  tip_comunidad: 'Compañia de seguros asociada a la Comunidad',
  listaComunidades: 'Lista de comunidades',
  Direccion: 'Dirección',
  Provincia: 'Provincia',
  estadoComunidad: 'Estado',
  Altac365: 'Alta c365',
  Fecha_Baja: 'F. Baja',
  Bloqueada: 'Bloqueada',
  tip_Bloqueada: 'Pulsa para bloquear/desbloquear el acceso de los usuarios a la app',
  tip_Alta: 'Pulsa para contratar Comunidad 365',
  tip_Baja: 'Pulsa para dar de baja Comunidad 365',
  tituloBloquear: 'Bloquear comunidad',
  tituloDesBloquear: 'Desbloquear comunidad',
  cuerpoBloquear: 'Pulse aceptar para confirmar el bloqueo de la comunidad',
  cuerpoDesBloquear: 'Pulse aceptar para confirmar el desbloqueo de la comunidad',
  tip_EnvioComunidades: 'Envía avisos a las comunidades previamente seleccionadas',
  ENVIAR_AVISO: 'Enviar aviso',
  Buscar: 'Buscar',
  Buscador: 'Buscador',
  Inicio: 'Inicio',
  Comunidades: 'Comunidades',
  Incidencias: 'Incidencias',
  Gremios: 'Gremios',
  Roles: 'Roles',
  Notificaciones: 'Notificaciones',
  Despacho: 'Despacho',
  Documentos: 'Documentos',
  Usuarios: 'Usuarios',
  DescripcionMenuInicial: 'Descripción Menú Inicial',
  CIF: 'CIF',
  Administracion: 'Administración',
  AvisoLegal: 'Aviso Legal',
  PoliticaPrivacidad: 'Política de Privacidad',
  PoliticaCookies: 'Política de Cookies',
  DescComunidades: 'Gestión de comunidades',
  DescIncidencias: 'Gestión de incidencias',
  DescGremios: 'Gestión de proveedores',
  DescRoles: 'Gestión de roles',
  DescUsuarios: 'Gestión de usuarios',
  DescNotificaciones: 'Gestión de Notificaciones',
  DescAdministracion: 'Gestión de Administración',
  Juntas: 'Juntas',
  Votaciones: 'Votaciones',
  ZonasComunes: 'Zonas Comunes',
  Documentacion: 'Documentación',
  Actividades: 'Actividades',
  DescJuntas: 'Gestión de Juntas',
  DescVotaciones: 'Gestión de Votaciones',
  DescZonasComunes: 'Gestión de Zonas Comunes',
  DescDocumentacion: 'Gestión de Documentacion',
  DescActividades: 'Gestión de Actividades',
  Detalle: 'Detalle',
  Mostrando: 'Mostrando',
  a: 'a',
  de: 'de',
  resultados: 'resultados',
  HasSeleccionado: 'Has seleccionado',
  comunidades: 'comunidades',
  paraAviso: 'para aviso',
  comunidad: 'comunidad',
  Comunidad: 'Comunidad',
  exportarPdf: 'Exportar a PDF',
  exportarCsv: 'Exportar a CSV',
  filasPagina: 'Filas por página',
  comunidadesSeleccionadas: 'comunidad/es seleccionada/s para aviso.',
  CERRAR: 'CERRAR',
  Restaurar: 'Restaurar',
  tituloFormAvisos: 'Envío avisos',
  asunto: 'Asunto',
  textoAviso: 'Texto del aviso',
  URLopcional: 'URL opcional',
  diagoloTitulo: 'Baja de la comunidad',
  diagoloBody: '¿Está seguro de que desea dar de baja la comunidad?',
  cerrar: 'Cerrar',
  guardarCambios: 'Guardar cambios',
  diagoloBodyAviso: '¿Está seguro de que desea enviar el aviso?',
  diagoloTituloAviso: 'Envío de aviso',
  Guardar: 'Guardar',
  CNAE: 'CNAE',
  TlfnPrincipal: 'Teléfono principal',
  TlfnSecundario: 'Teléfono secundario',
  Email1: 'Email 1',
  Email2: 'Email 2',
  idProveedor: 'Id Proveedor',
  idComunidad: 'Id Comunidad',
  fechaInicioContrato: 'Fecha inicio contrato',
  fechaFinContrato: 'Fecha fin contrato',
  observaciones: 'Observaciones',
  sinresultados: 'No se encontraron resultados.',
  cancelar: 'Cancelar',
  aceptar: 'Aceptar',
  Grupos: 'Grupos',
  AccesoATodo: 'Acceso a todo',
  Documentacion: 'Documentacion',
  ZonasReserva: 'Zonas de reserva',
  JuntasYVotaciones: 'Juntas y votaciones',
  Observaciones: 'Observaciones',
  Opciones: 'Opciones',
  Acciones: 'Acciones',
  tip_BorrarRol: 'Pulsa para borrar un rol',
  confirmarBorradoRol: 'Has marcado un rol para darlo de baja, ¿deseas continuar?',
  tituloConfirmarBorradoRol: 'Confirmar borrado',
  Anadir: 'Añadir',
  Proveedores: 'Proveedores',
};

export const tableLocation_gl = {
  pagination: {
    labelRowsSelect: 'Filas por página',
    labelDisplayedRows: '{from}-{to} de {count}',
    firstAriaLabel: 'Primera página',
    firstTooltip: 'Primera página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    nextAriaLabel: 'Siguiente página',
    nextTooltip: 'Siguiente página',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página',
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
    nRowsSelected: '{0} filas(s) seleccionada(s)',
  },
  header: {
    actions: 'Acciones',
  },
  body: {
    emptyDataSourceMessage: 'No hay datos disponibles',
    filterRow: {
      filterTooltip: 'Filtrar',
    },
    editRow: {
      saveTooltip: 'Guardar',
      cancelTooltip: 'Cancelar',
      deleteText: '¿Estás seguro de querer borrar esta fila?',
    },
    addTooltip: 'Agregar fila',
    deleteTooltip: 'Borrar fila',
  },
  edit: {
    saveTooltip: 'Guardar',
    cancelTooltip: 'Cancelar',
    deleteText: '¿Estás seguro de querer borrar esta fila?',
  },
  group: {
    deleteTooltip: 'Borrar grupo',
    editTooltip: 'Editar grupo',
    addTooltip: 'Agregar grupo',
  },
};
