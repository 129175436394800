import Loadable from "app/components/Loadable";
import { lazy } from "react";

export const NotFound = Loadable(lazy(() => import("./NotFound")));
export const ForgotPassword = Loadable(lazy(() => import("./ForgotPassword")));
export const JwtLogin = Loadable(lazy(() => import("./JwtLogin")));
export const JwtLoginConecta = Loadable(lazy(() => import("./JwtLoginConecta")));
export const JwtRegister = Loadable(lazy(() => import("./JwtRegister")));

const sessionRoutes = [
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signin/:Token", element: <JwtLoginConecta /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },
  { path: "/session/404", element: <NotFound /> },
];

export default sessionRoutes;
