import { set } from 'lodash';
import { AiFillFolder, AiFillFile } from 'react-icons/ai';
import { MdArrowRight, MdArrowDropDown, MdEdit } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { SiHtml5, SiJavascript, SiCss3, SiMarkdown } from 'react-icons/si';
import { LuFileJson } from 'react-icons/lu';
import { FaFileExcel } from 'react-icons/fa';
import { FaFilePdf } from 'react-icons/fa';
import { BsFiletypeJson } from 'react-icons/bs';
import { FaFileImage } from 'react-icons/fa';
import { FaFileWord } from 'react-icons/fa6';
import { useState } from 'react';
import { handleDrop } from './funcionesDocs';
import { ref, uploadBytes } from 'firebase/storage';
import { fileTypes, maxArchivos, maxFileSizeMB } from './funcionesDocs';
import { useFirebase } from 'app/firebase/context';
import { toast } from 'react-toastify';
import getCustomToastOptions from 'app/components/helpers/getCustomToastOptions';
import { is } from 'date-fns/locale';
import { getDownloadURL } from 'firebase/storage';
import Tooltip from "react-tooltip-lite";
const Node = ({
  node,
  style,
  dragHandle,
  tree,
  setArchivosEnCarpetaArborist,
  refrescar,
  setRefrescar,
  refresh,
  setSeleccionNuevaCarpeta,
  refrescarCarpetaNueva,
  Identificador,
  setLoading,
}) => {
  const CustomIcon = node.data.icon;
  const iconColor = node.data.iconColor;
  const [isDragging, setIsDragging] = useState(false);
  const extension = node.data.name.split('.').pop();
  const firebase = useFirebase();

  const handleDescargar = async (path) => {
    try {
      const url = await getDownloadURL(ref(firebase.storage, path));

      // Crear un enlace de descarga
      const link = document.createElement('a');
      link.href = url;
      link.download = path;
      link.target = '_blank'; // Abrir enlace en una nueva ventana/tabla
      // Agregar el enlace al cuerpo del documento
      document.body.appendChild(link);

      // Simular un clic en el enlace para iniciar la descarga
      link.click();

      // Eliminar el enlace del cuerpo del documento
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al obtener la URL de descarga:', error);
    }
  };

  const handleDropped = async (e, node) => {
    const path = node.data.id;

    if (node.data.isFile) {
      const lastSlashIndex = path.lastIndexOf('/');

      // Quedarse con la parte de la cadena antes de la última barra incluida
      const newPath = lastSlashIndex !== -1 ? path.substring(0, lastSlashIndex) : path;

      e.preventDefault();
      await handleDrop(
        e,
        newPath,
        firebase,
        setSeleccionNuevaCarpeta,
        refrescarCarpetaNueva,
        Identificador,
        setArchivosEnCarpetaArborist,
        setLoading
      );
    } else {
      e.preventDefault();
      await handleDrop(
        e,
        path,
        firebase,
        setSeleccionNuevaCarpeta,
        refrescarCarpetaNueva,
        Identificador,
        setArchivosEnCarpetaArborist,
        setLoading
      );
    }
  };


  const descargarFachero = () => {
    if (node.data.isFile) {
      handleDescargar(node.id);
    }

  }

  const handleClick = () => {
    /*if (node.data.isFile) {
      handleDescargar(node.id);
    }
*/
    if (node.isInternal) {
      const nombreCarpeta = node.data.name;

      setArchivosEnCarpetaArborist({
        carpeta: nombreCarpeta,
        archivos: node.children.map((child) => child.data.name),
        path: node.data.path,
      });
    }

    node.toggle();
  };

  let iconComponent;

  if (node.children === null) {
    switch (extension) {
      case 'json':
        iconComponent = <BsFiletypeJson />;
        break;
      case 'csv':
        iconComponent = <FaFileExcel color="green" />;
        break;
      case 'html':
        iconComponent = <SiHtml5 />;
        break;
      case 'js':
        iconComponent = <SiJavascript />;
        break;
      case 'css':
        iconComponent = <SiCss3 />;
        break;
      case 'md':
        iconComponent = <SiMarkdown />;
        break;
      case 'txt':
      case 'doc':
      case 'docx':
        iconComponent = <FaFileWord color="blue" />;
        break;
      case 'xls':
      case 'xlsx':
        iconComponent = <FaFileExcel />;
        break;
      case 'pdf':
        iconComponent = <FaFilePdf color="red" />;
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        iconComponent = <FaFileImage color="orange" />;
        break;
      case 'mp3':
      case 'wav':
      case 'mp4':
      case 'avi':
      case 'zip':
      case 'rar':
        iconComponent = <AiFillFile />;
        break;
      default:
        iconComponent = CustomIcon ? (
          <CustomIcon color={iconColor ? iconColor : '#f6cf60'} />
        ) : (
          <AiFillFile color="#888888" />
        );
    }
  } else {
    iconComponent = <AiFillFolder color="#f6cf60" />;
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  function truncarCadena(cadena, longitudMaxima) {
    if (cadena.length <= longitudMaxima) {
      return cadena;
    } else {
      return cadena.slice(0, longitudMaxima) + "...";
    }
  }
  return (
 
 
    
    <div
      className={`node-container ${node.isLeaf ? 'isArchivo' : ''}  ${node.state.isSelected ? 'isSelected' : ''} ${isDragging ? 'isDragging' : ''}`}
      ref={dragHandle}
      style={style}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
    >
     
      {node.depth > 0 && (
        <div className="connector-line" style={{ width: `${node.depth * 20}px` }} />
      )}

      <div className="node-content" onClick={handleClick} onDoubleClick={descargarFachero} onDrop={(e) => {handleDropped(e, node);} }>
        <div className="node-connector" />
        {node.isLeaf ? (
          <>
            <span className="arrow"></span>
            <span className="file-folder-icon">{iconComponent}</span>
          </>
        ) : (
          <>
            <span className="arrow">{node.isOpen ? <MdArrowDropDown /> : <MdArrowRight />}</span>
            <span className="file-folder-icon">{iconComponent}</span>
          </>
        )}
        <span className="node-text">
          {node.isEditing ? (
            <input
              type="text"
              defaultValue={node.data.name}
              onFocus={(e) => e.currentTarget.select()}
              onBlur={() => node.reset()}
              onKeyDown={(e) => {
                if (e.key === 'Escape') node.reset();
                if (e.key === 'Enter') node.submit(e.currentTarget.value);
              }}
              autoFocus
            />
          ) : (
            node.data.name.length > 30 ?
            <span><Tooltip content={node.data.name}>
            {truncarCadena(node.data.name, 30)}
          </Tooltip></span>  :   node.data.name==="10 Documentación general" ? "Documentación general": node.data.name
          )}
        </span>
      </div>
    </div>
  );
};

export default Node;
