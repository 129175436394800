import React from 'react';

export const getDesprecio = (numVecinos) => {
    let desPrecio = '';
    switch (true) {
      case numVecinos <= 20:
        desPrecio = "20";
        break;
      case numVecinos <= 50:
        desPrecio = "50";
        break;
      case numVecinos <= 100:
        desPrecio = "100";
        break;
      case numVecinos <= 200:
        desPrecio = "200";
        break;
      case numVecinos <= 300:
        desPrecio = "300";
        break;
      default:
        desPrecio = "Más de 300";
        break;
    }

  return desPrecio;
};

export const CurrencyFormatter = (value) => {
  // Convert value to a number or default to 0 if not a valid number
  const numericValue = typeof value === 'number' ? value : +value;

  // Check if the numericValue is a valid number
  if (isNaN(numericValue)) {
    return 'Invalid value';
  }

  // Format the numeric value as a currency with Euro symbol
  const formattedCurrency = numericValue.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedCurrency;
};