import React from 'react';
import ReactDOM from 'react-dom';
import html2pdf from 'html2pdf.js';

const ContratoComponent = ({ Disabilitado, Contenido, NombreFichero, width }) => {
  const handleDownloadPDF = () => {
    const element = Contenido;

    const htmlElement = document.createElement('div');
    ReactDOM.render(element, htmlElement);

    html2pdf(htmlElement, {
      margin: 10,
      filename: NombreFichero + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    });
  };

  return (
    <button
      className="btn btn-outline-primary px-4"
      onClick={handleDownloadPDF}
      disabled={Disabilitado}
      style={{ width: width }}
    >
      Descargar Contrato
    </button>
  );
};

export default ContratoComponent;
