import { get } from "firebase/database";
import { Semaforito } from "app/components/Semaforito/Semaforito";

export const getNombreComunidad = async (firebase, idComunidad) => {
  const snapshot = await get(
    firebase.getReferencia(`Comunidad/${idComunidad}/Nombre`, null)
  );
  const nombre = snapshot.val();
  return nombre;
};

export const getComunidad = async (firebase, idComunidad) => {
  const snapshot = await get(
    firebase.getReferencia(`Comunidad/${idComunidad}`, null)
  );
  const comunidad = snapshot.val();
  return comunidad;
};



export const getTodasComunidades = async (firebase) => {
  const comunidades = [];
  const snapshot = await get(firebase.getReferencia("Comunidad", null));
  snapshot.forEach((childSnapshot) => {
    let cm = childSnapshot.val();
    cm.idInterno = childSnapshot.key;
    comunidades.push(cm); 
  });
  return comunidades;
};

export const getComunidades = async (firebase) => {
  const comunidades = [];
  const snapshot = await get(firebase.getReferencia("Comunidad", null));
  snapshot.forEach((childSnapshot) => {
    comunidades[childSnapshot.key] = childSnapshot.val().Nombre;
  });
  return comunidades;
};

export const getSemaforoComunidad = (rowData, diccionario) => {


  let colorSeleccionado = "green";
  let textoExplicativo = diccionario.comunidadActiva;

  if (rowData.Alta === false) {
    colorSeleccionado = "yellow";
    textoExplicativo = diccionario.comunidadInactiva;
  }
  if (rowData.bloqueada === true) {
    colorSeleccionado = "red";
    textoExplicativo = diccionario.comunidadBloqueada;
  }

  rowData.colorSeleccionado = colorSeleccionado;
  rowData.textoExplicativo = textoExplicativo;
  return (
    <Semaforito
      rowdata={rowData}
      colorSeleccionado={colorSeleccionado}
      textoExplicativo={textoExplicativo}
    />
  );
};
