import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ActividadesTabla = Loadable(lazy(() => import('./ActividadesTabla')));
const ActividadDetalle = Loadable(lazy(() => import('./ActividadDetalle')));
const AnadirActividad = Loadable(lazy(() => import('./AnadirActividad')));
const actividadesRoutes = [
  { path: '/actividades/:Identificador', element: <ActividadesTabla /> },
  { path: '/detalle_actividad/:IdComu/:Identificador', element: <ActividadDetalle /> },
  { path: '/detalle_actividad/:IdComu/:Identificador/:volver', element: <ActividadDetalle /> },
  { path: '/addactividad/:IdComu', element: <AnadirActividad /> },
];

export default actividadesRoutes;
