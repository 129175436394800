import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const RolesTabla = Loadable(lazy(() => import('./RolesTabla')));
const RolDetalle = Loadable(lazy(() => import('./RolDetalle')));
const AnadirRol = Loadable(lazy(() => import('./AnadirRol')));
const rolesRoutes = [
  { path: '/roles', element: <RolesTabla /> },
  { path: '/detalle_rol', element: <RolDetalle /> },
  { path: '/detalle_rol/:Identificador', element: <RolDetalle /> },
  { path: '/detalle_rol/:Identificador/:volver', element: <RolDetalle /> },
  { path: '/addrol', element: <AnadirRol /> },
];

export default rolesRoutes;
