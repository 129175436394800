import { useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "./../../constants/routes";
import { useStore } from "react-redux";
import { MenuCentralContenido } from "app/components/MenusCentralContenido/MenuCentralContenido";

export const MenuInicial = () => {
  /* estado que filtra por el buscador y lo muestra en el return con un map */
  const [filtro, setFiltro] = useState("");
  const store = useStore();
  const lengu = store.getState().lenguajes;
  let diccionario = lengu.diccionario;

  /* este objeto contiene los datos que se muestran en el return */
  const contenido = [
    {
      id: 1,
      ruta: ROUTES.LISTA_COMUNIDADES,
      icono: "comunidad",
      titulo: diccionario.Comunidades,
      texto: diccionario.DescComunidades,
    },
    {
      id: 2,
      ruta: ROUTES.LISTA_INCIDENCIAS,
      icono: "incidencias",
      titulo: diccionario.Incidencias,
      texto: diccionario.DescIncidencias,
    },
    {
      id: 3,
      ruta: ROUTES.GREMIOS,
      icono: "gremios",
      titulo: diccionario.Proveedores,
      texto: diccionario.DescGremios,
    },
    {
      id: 4,
      ruta: ROUTES.ROLES,
      icono: "roles",
      titulo: diccionario.Roles,
      texto: diccionario.DescRoles,
    },

    {
      id: 6,
      ruta: ROUTES.NOTIFICACIONES,
      icono: "notificaciones",
      titulo: diccionario.Notificaciones,
      texto: diccionario.DescNotificaciones,
    },
    {
      id: 7,
      ruta: ROUTES.DESPACHO,
      icono: "adminstracion",
      titulo: diccionario.Despacho,
      texto: diccionario.DescAdministracion,
    },
  ];

  const nombreMenu = "";

  /* funcion coge el valor del input y actualiza el estado */
  const buscador = (e) => {
    setFiltro(e.target.value);
  };

  /* funcion que filtra el contenido por el buscador */
  const elementosFiltrados = contenido.filter((elemento) => {
    return (
      (elemento.titulo && elemento.titulo.toLowerCase().includes(filtro.toLowerCase())) ||
      (elemento.texto && elemento.texto.toLowerCase().includes(filtro.toLowerCase()))
    );
  });

  return (
    <MenuCentralContenido contenido={contenido} nombreMenu={nombreMenu} activarBuscador={false} />
  );
};
