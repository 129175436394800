import React, { useRef, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QRCodeComponent = forwardRef(({ url, diccionario, nombreJunta ,tipo=null}, ref) => {
  const qrRef = useRef();

  // Save as PDF without the button
  const saveAsPDF = async () => {
    // Hide elements you don't want to appear in the PDF
    const button = document.querySelector(".no-pdf");
    if (button) button.style.display = "none";

    const input = qrRef.current;
    const canvas = await html2canvas(input, {
      scale: 2, // Adjust scale for better quality
      useCORS: true, // Use CORS to handle cross-origin requests
      logging: true, // Enable logging to check for issues
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [canvas.width + 20, canvas.height + 20], // Add padding to the PDF format
    });

    pdf.addImage(imgData, "PNG", 10, 10, canvas.width, canvas.height); // Add margin to the PDF content
    pdf.save("qrcode.pdf");

    // Restore visibility of the button after generating the PDF
    if (button) button.style.display = "block";
  };

  const getQRBlob = async () => {
    const input = qrRef.current;
    const canvas = await html2canvas(input, {
      scale: 2,
      useCORS: true,
      logging: true,
    });
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Failed to create blob"));
        }
      }, "image/png");
    });
  };

  useImperativeHandle(ref, () => ({
    getQRBlob,
  }));

  return (
    <div style={{ textAlign: "center" }}>
      <div
        ref={qrRef}
        style={{
          display: "inline-block",
          padding: "20px",
          backgroundColor: "#ffffff",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#172983",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {tipo!=="fichar" ? diccionario.QRasistencia: diccionario.QRfichar} {tipo!=="fichar" ? diccionario.a: diccionario.enPrep} {nombreJunta}
          </div>
          <QRCode
            value={url}
            size={256} // Size of the QR code
            bgColor="#ffffff" // Background color
            fgColor="#172983" // Foreground color
            level="H" // Error correction level (L, M, Q, H)
            includeMargin={true} // Include margin
          />
          {/* Button with class `no-pdf` to exclude from PDF */}
          <Button variant="outline-primary" onClick={saveAsPDF} className="no-pdf">
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: "5px" }} />
            {diccionario.guardarEnPdf}
          </Button>
        </div>
      </div>
    </div>
  );
});

QRCodeComponent.propTypes = {
  url: PropTypes.string.isRequired,
  diccionario: PropTypes.shape({
    guardarEnPdf: PropTypes.string.isRequired,
  }).isRequired,
  nombreJunta: PropTypes.string.isRequired,
};

export default QRCodeComponent;
