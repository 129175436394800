import ContratoComponent from "app/components/Contrato/ContratoComponent";
import { ContratoContenido } from "app/components/Contrato/ContratoContenido";
import React, { useState } from "react";
import { useFirebase } from "app/firebase/context";
import { update, push } from "firebase/database";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { toast } from "react-toastify";
import { setRef } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "app/hooks/useAuth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useStore } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

const circleBadgeStyle = {
  display: "inline-block",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "#172983",
  color: "#fff",
  textAlign: "center",
  lineHeight: "30px",
};

export const Step4 = ({
  onPrev,
  setPlan,
  plan,
  onNext,
  setStep,
  step,
  Comunidad,
  isModalOpen,
  setIsModalOpen,
  setModalOpen,
  resetFormContrato,
  setRefrescar,
  refrescar,
  datosCompletosComunidad,
  planHistorico,
  esModificar,
}) => {
  const firebase = useFirebase();
  const [aceptarTerminos, setAceptarTerminos] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = () => {
    setAceptarTerminos(!aceptarTerminos);
  };
  const volverFirstStep = () => {
    setStep(1);
  };

  const handleContratar = async (Comunidad) => {
    setLoading(true);
    try {
      // Actualizar el estado con la información obtenida
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString("es-ES", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });

      const formattedDateStripe = currentDate
        .toLocaleString("es-ES", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, "-");

      const updatedData = {
        Alta: true,
        fechaAlta: formattedDate,
        ContratoActivo: {
          fechaInicio: formattedDate,
          fechaFin: null,
          formapago: plan.formapago,
          iban: plan.iban, // MIRAR SI HABRÍA QUE GUARDAR EL IBAN EN EL MOMENTO DE CONTRATAR (SEGURAMENTE SI, Y TENER EN CUENTA )
          plan: plan.plan,
          precioSelected: plan.precioSelected,
          vecinos: plan.vecinos,
          cuentaBancaria: plan.cuentaBancaria,
        },
      };

      // Primero paso por stripe y si es correcto doy de alta y genero el contrato

      const functions = getFunctions(firebase.app, process.env.REACT_APP_FIREBASE_ZONE);
      var startCommunityPayment = httpsCallable(functions, "startCommunityPayment");

      const deskId = user.despacho.Codigo;
      const communityId = Comunidad;
      const type = plan.iban === "administrador" ? "Despacho" : "Comunidad";
      const price = plan.precioSelected;
      const name = `Pack ${plan.plan} - Com. ${Comunidad} (${plan.vecinos} vecinos) ${formattedDateStripe}`;
      const recurringRate = plan.formapago === "anual" ? plan.formapago : "monthly";

      startCommunityPayment({
        deskId: deskId,
        communityId: communityId,
        type: type,
        price: price,
        name: name,
        recurringRate: recurringRate,
      })
        .then(async (res) => {
          if (res.data.status !== "success") {
            toast.error(diccionario.FalloPago, getCustomToastOptions(toast, "error"));
            throw diccionario.FalloPago;
          }
          // Si el IBAN es nuevo en stripe tendremos que confirmarlo
          if (res.data.newAccount === true) {
            onNext();
          } else {
            const comUpdatear = firebase.getReferencia(`Comunidad/${Comunidad}`);

            if (esModificar == true) {
              const updatedData2 = {
                fechaInicio: planHistorico.fechaInicio,
                fechaFin: formattedDate,
                formapago: planHistorico.formapago,
                iban: planHistorico.iban, // MIRAR SI HABRÍA QUE GUARDAR EL IBAN EN EL MOMENTO DE CONTRATAR (SEGURAMENTE SI, Y TENER EN CUENTA )
                plan: planHistorico.plan,
                precioSelected: planHistorico.precio,
                vecinos: planHistorico.vecinos,
                cuentaBancaria: planHistorico.cuentaBancaria,
              };
              const comUpdatear2 = firebase.getReferencia(
                `Comunidad/${Comunidad}/HistoricoContratos`
              );

              await push(comUpdatear2, updatedData2);
            }

            await update(comUpdatear, updatedData);

            toast.success(diccionario.ContratoAceptadoPagosIniciados, getCustomToastOptions(toast));
            setRefrescar(!refrescar);
            setModalOpen(false);
            //setIsModalOpen(false);
            // RESETEAR EL FORM DE CONTRATACION
            setLoading(false);
            navigate("/tutorial" + "/" + Comunidad);
          }
        })
        .catch((e) => {
          toast.error(diccionario.FalloEnElPago, getCustomToastOptions(toast, "error"));
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error al bloquear/desbloquear comunidad", error);
    }
  };

  return (
    <>
      <div style={{ display: loading === true ? "block" : "none" }}>
        <ClipLoader
          size={100}
          color={"#123abc"}
          loading={loading}
          cssOverride={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          speedMultiplier={1}
        />
      </div>
      <div style={{ display: loading !== true ? "block" : "none" }}>
        <div className="alta365-administradores" style={{ overflow: "auto !important" }}>
          <div className="container mt-4">
            <div className="d-flex align-items-center">
              <div style={circleBadgeStyle} className="mr-2">
                4
              </div>

              <h5 style={{ marginLeft: "15px", marginBottom: "0px" }}>Paso final:</h5>
            </div>
          </div>
          <hr />

          <ContratoContenido
            ContenidoPlan={plan.plan}
            ContenidoIban={plan.iban}
            ContenidoFormaPago={plan.formapago}
            PrecioSelected={plan.precioSelected}
          />
        </div>

        <div className="row form-actions-fixed" id="footerAltac65">
          <div style={{ width: "65%", display: "flex", gap: "20px" }}>
            <button className="btn btn-outline-secondary px-4" onClick={onPrev}>
              Volver
            </button>
            <button
              className="btn btn-outline-secondary px-4"
              onClick={volverFirstStep}
              style={{ width: "140px" }}
            >
              {diccionario.VolverPrimerPaso}
            </button>
          </div>
          <div style={{ width: "35%", display: "flex", gap: "20px" }}>
            <label style={{ display: "flex", alignItems: "center", width: "auto" }}>
              <input
                type="checkbox"
                checked={aceptarTerminos}
                onChange={handleCheckboxChange}
                style={{ marginRight: "8px" }}
              />
              {diccionario.AceptarTerminosContratacion}
            </label>
            <button
              onClick={() => handleContratar(Comunidad)}
              className="btn btn-primary px-4"
              disabled={!aceptarTerminos}
            >
              {diccionario.Contratar}
            </button>
            <ContratoComponent
              width={"165px"}
              Contenido={
                <ContratoContenido
                  ContenidoPlan={plan.plan}
                  ContenidoIban={plan.iban}
                  ContenidoFormaPago={plan.formapago}
                  PrecioSelected={plan.precioSelected}
                />
              }
              NombreFichero={plan.nombre + "_" + plan.plan + "_" + plan.formapago}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Step4;
