import React, { useEffect, useState, useRef } from "react";
import { ImEnter } from "react-icons/im";
import { getFunctions, httpsCallable } from "firebase/functions";
import "bootstrap/dist/css/bootstrap.css";
import { TextField, Checkbox } from "@mui/material";
import { useStore } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import * as ROUTES from "./../../constants/routes";
import BackButton from "app/components/helpers/BackButton";
import { useFirebase } from "../../../app/firebase/context";
import { get } from "firebase/database";
import { RefreshButton } from "app/components/helpers/Refresh";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import useAuth from "app/hooks/useAuth";
import QRCodeComponent from "app/components/QRgenerator/QRCodeComponent";
import {
  Paper,
  Box,
  Grid,
} from "@material-ui/core";
const ImagenQRCentroTrabajo = () => {
  const firebase = useFirebase();
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const [Titulo, setTitulo] = useState("");
  const [enlaceQR, setEnlaceQR] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { IdComu, Identificador, volver } = useParams();
  const qrCodeRef = useRef();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  
  const BaseDatos = user.despacho.Codigo;
  const { protocol, hostname, port } = window.location;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const myRef = firebase.getReferencia(
          `PuestoTrabajo/${Identificador}`,
          null
        );
        const snapshot = await get(myRef);
        const datos = snapshot.val();
        setTitulo(datos.Nombre);
 
        setEnlaceQR(`centroTrabajo:${Identificador}`);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [firebase, IdComu, Identificador, protocol, hostname, port, BaseDatos]);

  const copiarTexto = (e, texto) => {
    navigator.clipboard.writeText(texto);
    toast.success(
      `${diccionario.CodigoCopiadoPortapapeles}   ${texto}`,
      getCustomToastOptions(toast)
    );
    e.stopPropagation();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <img src="/assets/images/comunidad365.png" alt="" />
      </div>

      {loading && (
        <ClipLoader
          size={150}
          color={"#123abc"}
          loading={loading}
          cssOverride={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          speedMultiplier={1}
        />
      )}

      {!loading && enlaceQR && (


<div className="contenedor-comunidades">
<Grid>
  <div>
    {" "}
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Paper
        elevation={4}
        style={{
          maxWidth: 800,
          marginLeft: 10,
          marginRight: 10,
          marginTop: 10,
          padding: 20
        }}
      >
        <QRCodeComponent
            ref={qrCodeRef}
            url={enlaceQR}
            diccionario={diccionario}
            nombreJunta={Titulo || ""}
            tipo={"fichar"}
          />{" "}
      </Paper>
    </Grid>
    <Box mt={40}></Box>
  </div>
</Grid>
</div>
      )}
    </>
  );
};

export default ImagenQRCentroTrabajo;
