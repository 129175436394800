import React from "react";

export const CajaInputs = ({
  titulo,
  contenido,
  margenBot = "0px",
  estiloAlerta = null,
}) => {

  let estiloCajaInputs = { marginBottom: margenBot };

  if(estiloAlerta){
    estiloCajaInputs.color = "red";
  }

  return (
    <div className="contenedor-inputs" style={estiloCajaInputs}>
      <div className="header">
        <h5>{titulo}</h5>
      </div>
      <div className="body-caja" style={{ padding: "15px" }}>
        {contenido}
      </div>
    </div>
  );
};
