import { Card } from  '@mui/material';
import { Box,styled} from '@mui/material';


const CardRoot = styled(Card)((data) => ({
  height: '100%',
  padding: '20px 24px',
  width:(data.anchura ? data.anchura:null),
  marginBottom:data.margenbajo,
  background: data.colores,
}));

const CardTitle = styled('div')(({ subtitle }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  marginBottom: !subtitle && '16px',
}));

const SimpleCard = ({ children, title, subtitle, icon, colores, elevacion,margenbajo,anchura }) => {
  return (
    <CardRoot elevation={(elevacion ? elevacion:6)} margenbajo={(margenbajo ? margenbajo: null)} colores={colores} anchura={anchura} >
      <CardTitle subtitle={subtitle}>{title}</CardTitle>
      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
