import { get } from "firebase/database";

export const validarEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
export const getFallosDatosUsuario = (datosUsuario, diccionario) => {
  let fallosUsuario = [];
  const datosAComprobar = Array("Nombre", "Apellido", "Telefono", "DNI", "Email");
  datosAComprobar.map((dato) => {
    dato = dato.trim();
    if (!dato || dato === "") {
      fallosUsuario.push(diccionario[dato]);
    }
  });
  return fallosUsuario;
};

export const getFallosFichajes = (usuario, fichajes, diaActual = null, diccionario) => {
  let fallosFichajes = [];
  const dias = (fichajes && Object.keys(fichajes)) || [];

  if (Array.isArray(dias) && dias.length > 0) {
    const diasMenores = dias.filter((dia) => dia < diaActual);
    if (diasMenores) {
      diasMenores.map((diaRes) => {
        const fich = fichajes[diaRes];
        if (Array.isArray(fich)) {
          fich.map((fic) => {
            if (fic.Entrada !== "" && (fic.Salida === "" || fic.Salida === undefined)) {
              const diaFormat = `${diaRes.slice(6, 8)}-${diaRes.slice(4, 6)}-${diaRes.slice(0, 4)}`;
              if (!fallosFichajes.includes(diaFormat)) {
                fallosFichajes.push(diaFormat);
              }
            }
          });
        }
      });
    }
  }
  return fallosFichajes;
};
export const getEstadoSemaforoUsuarios = (datosUsuario, diccionario) => {
  console.log("E vose un mamapinga", datosUsuario);
  let colorSeleccionado = "";
  let textoExplicativo = "";
  const fallosDatosUsuario = getFallosDatosUsuario(datosUsuario);
  const diaActual = new Date().toISOString().slice(0, 10).replace(/-/g, "");
  const fallosFichajes = getFallosFichajes(datosUsuario, datosUsuario.fichajes, diaActual);
  let arrFallos = [];

  if (fallosDatosUsuario.length > 0) {
    colorSeleccionado = "yellow";
    textoExplicativo = diccionario.amarillo_faltanDatosUsuario.replace(
      "${var1}",
      fallosDatosUsuario.join(", ")
    );
    arrFallos.push(textoExplicativo);
  }
  // Usuario no registrado en C365

  if (datosUsuario.requestedInstance && datosUsuario.requestedInstance) {
    colorSeleccionado = "yellow";
    textoExplicativo = diccionario.usuarioConInstanciaSinAdjudicar;
    arrFallos.push(textoExplicativo);
  }
  if (datosUsuario.AltaC365 !== true) {
    colorSeleccionado = "yellow";
    textoExplicativo = diccionario.amarillo_usuarioNoRegistrado;
    arrFallos.push(textoExplicativo);
    // Incidencia abierta o en curso desde hace 8 días o más
    // Usuario sin vivienda asignada
  }
  if (
    datosUsuario.Direcciones &&
    datosUsuario.Direcciones.length <= 0 &&
    !datosUsuario.requestedInstance
  ) {
    colorSeleccionado = "yellow";
    textoExplicativo = diccionario.amarillo_usuarioViviendaNoAsignada;
    arrFallos.push(textoExplicativo);
    // Fallos en los fichajes
  }
  if (fallosFichajes.length > 0) {
    colorSeleccionado = "yellow";
    textoExplicativo = diccionario.amarillo_usuarioFalloFichaje.replace(
      "${var1}",
      fallosFichajes.join(",")
    );
    arrFallos.push(textoExplicativo);
    // Usuario sin fallos
  }

  // Usuario bloqueado
  if (datosUsuario.Bloqueado === true) {
    colorSeleccionado = "red";
    textoExplicativo = diccionario.rojo_usuarioBloqueado;
    arrFallos.push(textoExplicativo);
    // Faltan datos de usuario
  }

  if (arrFallos.length === 0) {
    colorSeleccionado = "green";
    textoExplicativo = diccionario.verde_usuarioCorrecto;
    arrFallos.push(textoExplicativo);
  }

  return {
    colorSeleccionado: colorSeleccionado,
    textoExplicativo: arrFallos.join(" || "),
  };
};

const validateDNI = (dni) => {
  const number = dni.substr(0, 8);
  const letter = dni.substr(8, 1);
  const validLetters = "TRWAGMYFPDXBNJZSQVHLCKE";
  const calculatedLetter = validLetters[number % 23];
  return letter === calculatedLetter;
};

const validateNIE = (nie) => {
  let niePrefix = nie.charAt(0);

  // Replace X, Y, Z with their corresponding numbers for validation
  let nieNumber = nie.replace("X", "0").replace("Y", "1").replace("Z", "2");

  return validateDNI(nieNumber);
};

export const validateCIF = (cif) => {
  const letters = "JABCDEFGHI"; // Letters used for control characters
  const type = cif.charAt(0); // First letter of CIF
  const digits = cif.substr(1, 7); // Digits in the CIF
  const control = cif.substr(-1); // Last character (digit or letter)

  let evenSum = 0;
  let oddSum = 0;

  // Calculate the sum of odd and even positions
  for (let i = 0; i < digits.length; i++) {
    const digit = parseInt(digits[i]);

    if (i % 2 === 0) {
      // Odd positions (0-indexed) are doubled, sum their digits
      let double = digit * 2;
      oddSum += double > 9 ? double - 9 : double;
    } else {
      // Even positions are added directly
      evenSum += digit;
    }
  }

  // Calculate total sum and control digit
  const totalSum = oddSum + evenSum;
  const controlDigit = (10 - (totalSum % 10)) % 10;

  // Determine the expected control character
  if (/[ABEH]/.test(type)) {
    // Expected control should be a digit
    return control === controlDigit.toString();
  } else if (/[KPQS]/.test(type)) {
    // Expected control should be a letter
    return control === letters[controlDigit];
  } else {
    // Types with either digit or letter (CIF types like N, F, etc.)
    return control === controlDigit.toString() || control === letters[controlDigit];
  }
};

export const validateSpanishDocument = (document) => {
  let result = false;
  // Remove whitespace and convert to uppercase
  const doc = document.trim().toUpperCase();

  // Regular expressions for each document type
  const dniRegex = /^[0-9]{8}[A-Z]$/;
  const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;
  const cifRegex = /^[ABCDEFGHJKLMNPQRSUVW][0-9]{7}[0-9A-J]$/;

  // Validate DNI
  if (dniRegex.test(doc)) {
    result = validateDNI(doc) ? "NIF" : false;
  } else if (nieRegex.test(doc)) {
    // Validate NIE
    result = validateNIE(doc) ? "NIE" : false;
  } else if (cifRegex.test(doc)) {
    // Validate CIF
    result = validateCIF(doc) ? "CIF" : false;
  }

  return result;
};

export const validarDNI = (dni) => {
  // Expresión regular para validar el formato del DNI
  const dniRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;

  // Comprobar si el DNI cumple con el formato
  if (!dniRegex.test(dni)) {
    return false;
  }

  // Obtener la letra del DNI (la última posición)
  const letras = "TRWAGMYFPDXBNJZSQVHLCKET";
  const letraCalculada = letras.charAt(parseInt(dni, 10) % 23);
  const letraDNI = dni.charAt(8).toUpperCase();

  // Comprobar si la letra del DNI es correcta
  if (letraDNI !== letraCalculada) {
    return false;
  }

  // Si pasa todas las validaciones, el DNI es válido
  return true;
};

// Function to check if the identifier corresponds to a company
const isCompanyIdentifier = (identifier) => {
  // Regular expression to match Spanish company identification numbers
  const companyRegex = /^[A-Z]{1}[0-9]{8}$/;
  return companyRegex.test(identifier);
};

// Function to check if the identifier corresponds to a person
const isPersonIdentifier = (identifier) => {
  // Regular expression to match Spanish personal identification numbers
  const personRegex = /^[0-9]{8}[A-Z]$/;
  return personRegex.test(identifier);
};

// Function to determine the type of identifier
export const tipoDNI = (identifier) => {
  if (isCompanyIdentifier(identifier)) {
    return "empresa";
  } else if (isPersonIdentifier(identifier)) {
    return "persona";
  } else {
    return "desconocido"; // If neither a company nor a person identifier
  }
};

export const yaExisteDNI = async (firebase, nombreComprobar, idPropio = null) => {
  const snapshot = await get(firebase.getReferencia("Usuarios", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    childData.idInterno = childKey;
    datos.push(childData);
  });
  let otros = [];

  if (!idPropio) {
    otros = datos.filter(
      (usuarios) => (usuarios.DNI && usuarios.DNI.toLowerCase()) === nombreComprobar.toLowerCase()
    );
  } else {
    otros = datos.filter(
      (usuarios) =>
        usuarios.DNI &&
        usuarios.DNI.toLowerCase() === nombreComprobar.toLowerCase() &&
        idPropio != usuarios.idInterno
    );
  }

  return otros.length <= 0 ? false : true;
};

export const yaExisteEmail = async (firebase, nombreComprobar, idPropio = null) => {
  const snapshot = await get(firebase.getReferencia("Usuarios", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    childData.idInterno = childKey;
    datos.push(childData);
  });
  let otros = null;

  if (!idPropio) {
    otros = datos.filter(
      (usuarios) =>
        (usuarios.Email && usuarios.Email.toLowerCase()) === nombreComprobar.toLowerCase()
    );
  } else {
    otros = datos.filter(
      (usuarios) =>
        usuarios.Email &&
        usuarios.Email.toLowerCase() === nombreComprobar.toLowerCase() &&
        idPropio != usuarios.idInterno
    );
  }

  return otros.length > 0 ? true : false;
};

export const traerInstancias = async (comunidad, firebase) => {
  const snapshot = await get(firebase.getReferencia(`/Comunidad/${comunidad}/Instancias`, null));
  let instancias = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    childData.idInterno = childKey;
    instancias.push(childData);
  });

  return instancias;
};
