import React from 'react';
import { useStore } from 'react-redux';

export const RefreshButton = ({ onRefresh }) => {
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  return (
    <button className="btn btn-outline-primary mx-2" onClick={onRefresh}>
      {diccionario.Refrescar}
    </button>
  );
};
