import { ref, uploadBytes, getDownloadURL, uploadString } from "firebase/storage";
import { toast } from "react-toastify";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";

export const fileTypes = [
  "image/jpeg",
  "image/png",
  "image/gif", // Images
  "text/plain", // Text
  "application/pdf", // PDF
  "application/zip",
  "text/csv",
  "application/x-rar-compressed", // Archives (ZIP, RAR)
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word documents (DOC, DOCX)
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel spreadsheets (XLS, XLSX)
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PowerPoint presentations (PPT, PPTX)
];

export const maxArchivos = 5;
export const maxFileSizeMB = 20;

export const getSupportedFileTypes = () => {
  return fileTypes;
};

export const getUploadLimits = () => {
  return {
    maxArchivos: 5,
    maxFileSizeMB: 20,
  };
};

export const crearCarpetaJunta = async (firebase, rutaCompleta) => {
  if (rutaCompleta === "") {
    // Error Carpeta vacía
    return;
  }

  try {
    const spaceRef = ref(firebase.storage, rutaCompleta + "/temporal.txt");
    const message = "mensajesublemuinal";
    uploadString(spaceRef, message).then(async (snapshot) => {
      // Exito
    });
  } catch (error) {
    // Error
  }
};

export const handleDescargar = async (archivo, pathArchivo, firebase) => {
  let filePath = "";
  if (pathArchivo) {
    filePath = `${pathArchivo}/${archivo}`.replace(/\/\//g, "/");
  } else {
    filePath = `${archivo}`.replace(/\/\//g, "/");
  }

  try {
    const url = await getDownloadURL(ref(firebase.storage, filePath));

    // Crear un enlace de descarga
    const link = document.createElement("a");
    link.href = url;
    link.download = archivo;
    link.target = "_blank"; // Abrir enlace en una nueva ventana/tabla

    // Agregar el enlace al cuerpo del documento
    document.body.appendChild(link);

    // Simular un clic en el enlace para iniciar la descarga
    link.click();

    // Eliminar el enlace del cuerpo del documento
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error al obtener la URL de descarga:", error);
  }
};

export const truncarCadena = (cadena, longitudMaxima) => {
  if (cadena.length <= longitudMaxima) {
    return cadena;
  } else {
    return cadena.slice(0, longitudMaxima) + "...";
  }
};

export const handleDrop = async (
  e,
  carpetaArboristPath,
  firebase,
  setSeleccionNuevaCarpeta,
  refrescarCarpetaNueva,
  Identificador,
  setArchivosEnCarpetaArborist,
  setLoading
) => {
  e.preventDefault();

  if (!e.dataTransfer.files.length > 0) {
    return "zoquete";
  }
};
