import React, { createContext, useContext, useReducer, useState } from 'react';

const LenguageStateContext = createContext();

const initialState = {
    value: 'initial value',
};

const lenguageStateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_VALUE':
            return { ...state, value: action.payload };
        default:
            return state;
    }
};

const LenguageStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(lenguageStateReducer, initialState);

    return (
        <LenguageStateContext.Provider value={{ state, dispatch }}>
            {children}
        </LenguageStateContext.Provider>
    );
};

const UseLenguageState = () => {
    const context = useContext(LenguageStateContext);
    if (!context) {
        throw new Error('use LenguageState must be used within a LenguageStateProvider');
    }
    return context;
};

export { LenguageStateProvider, UseLenguageState };
