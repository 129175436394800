// Wizard.jsx
import React, { useState, useEffect } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { useFirebase } from 'app/firebase/context';
import { get,ref } from 'firebase/database';

export const Wizard = ({ esModificar,planHistorico, Comunidad, Vecinos, step, setStep,isModalOpen,setModalOpen,plan,setPlan,resetFormContrato, refrescar, setRefrescar, datosCompletosComunidad}) => {
  
  const firebase = useFirebase();
  const nextStep = (e) => {
    setStep(step + 1);

    if (step === 1) {
      setPlan({ ...plan, plan: e.target.value });
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    const tablaPrecios = async () => {
      try {
        // Tabla de precios sacada de la bbdd admin
        const snapshot = await get(ref(firebase.dbAdmin,'TablaPrecios'));
        //const snapshot = await get(firebase.getReferencia('TablaPrecios', null));
        let precios = [];
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val();
          precios.push(childData);
        });
        setPlan({ ...plan, tablaPrecios: precios });
      } catch (error) {
        console.error('Error al cargar precios', error);
      }
    };
    tablaPrecios();
  }, []);

  return (
    <div className="container mt-5">
      {step === 1 && <Step1 onNext={nextStep} plan={plan} planHistorico={planHistorico} />}
      {step === 2 && <Step2 onPrev={prevStep} onNext={nextStep} setPlan={setPlan} plan={plan} planHistorico={planHistorico} />}
      {step === 3 && <Step3 onPrev={prevStep} setPlan={setPlan} plan={plan} onNext={nextStep} datosCompletosComunidad={datosCompletosComunidad}  planHistorico={planHistorico} />}
      {step === 4 && (
        <Step4 onPrev={prevStep} onNext={nextStep} setPlan={setPlan} plan={plan} setStep={setStep} step={step} Comunidad={Comunidad} isModalOpen={isModalOpen} setModalOpen={setModalOpen} resetFormContrato={resetFormContrato} refrescar={refrescar}setRefrescar={setRefrescar}  datosCompletosComunidad={datosCompletosComunidad} esModificar={esModificar} planHistorico={planHistorico}/>
      )}
      {step === 5 && (
        <Step5 onPrev={prevStep} setPlan={setPlan} plan={plan} setStep={setStep} step={step} Comunidad={Comunidad} isModalOpen={isModalOpen} setModalOpen={setModalOpen} resetFormContrato={resetFormContrato} refrescar={refrescar}setRefrescar={setRefrescar}  datosCompletosComunidad={datosCompletosComunidad}  />
      )}
    </div>
  );
};

export default Wizard;
