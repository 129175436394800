// components/BackButton.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "react-redux";

const BackButton = ({ marchaAtras = -1 }) => {
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const navigate = useNavigate();

  const handleVolverAtras = () => {
    navigate(marchaAtras, { replace: true });
  };

  return (
    <button className="btn btn-outline-secondary px-4" onClick={handleVolverAtras}>
      {diccionario.Volver}
    </button>
  );
};

export default BackButton;
