import AuthGuard from "app/auth/AuthGuard";
import HomeRoutes from "app/views/home/HomeRoutes";
import chartsRoute from "app/views/charts/ChartsRoute";
import dashboardRoutes from "app/views/dashboard/DashboardRoutes";
import materialRoutes from "app/views/material-kit/MaterialRoutes";
import NotFound from "app/views/sessions/NotFound";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import ComunidadRoutes from "app/views/comunidad/ComunidadRoutes";
import GremiosRoutes from "app/views/gremios/GremiosRoutes";
import IncidenciasRoutes from "app/views/incidencias/IncidenciasRoutes";

import DocumentosRoutes from "app/views/documentos/DocumentosRoutes";
import UsuariosRoutes from "app/views/usuarios/UsuariosRoutes";
import LogoutRoutes from "app/views/logout/LogoutRoutes";
import { Navigate } from "react-router-dom";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import SidecoRoutes from "app/views/sideco/SidecoRoutes";
import * as ROUTES from "./constants/routes";
import TutorialRoutes from "./views/tutorial/TutorialRoutes";
import RolesRoutes from "app/views/roles/RolesRoutes";
import DespachoRoutes from "app/views/adminDesk/adminDeskRoutes";
import ActividadesRoutes from "app/views/actividades/ActividadesRoutes";
import VotacionesRoutes from "app/views/votaciones/VotacionesRoutes";
import FormularioPublicoIncidencias from "app/views/formularioincidencias/FormularioPublicoIncidencias";
import NotificacionesRoutes from "app/views/centroNotificaciones/NotificacionesRoutes";
import ZonasComunesRoutes from "app/views/zonascomunes/ZonasComunesRoutes";
import UsuariosCongresoCNAF from "app/views/congreso_cnaf2024/CongresoCNAFRoutes";
import FormularioAsistenciaQR from "./views/juntas/FormularioAsistenciaQR";
import JuntasRoutes from "./views/juntas/JuntasRoutes";
import VideoJitsi from "app/views/juntas/VideoJitsi";
import ImagenQRJunta from "./views/juntas/ImagenQRJunta";
import ImagenQRCentroTrabajo from "./views/adminDesk/ImagenQRCentroTrabajo";


const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...SidecoRoutes,
      ...ComunidadRoutes,
      ...GremiosRoutes,
      ...IncidenciasRoutes,
      ...DocumentosRoutes,
      ...UsuariosRoutes,
      ...LogoutRoutes,
      ...HomeRoutes,
      ...TutorialRoutes,
      ...RolesRoutes,
      ...DespachoRoutes,
      ...NotificacionesRoutes,
      ...ActividadesRoutes,
      ...VotacionesRoutes,
      ...ZonasComunesRoutes,
      ...UsuariosCongresoCNAF,
      ...JuntasRoutes,
    ],
  },
  ...sessionRoutes,
  // Route not protected by auth
  { path: "/incidencias_gremios/:parametros", element: <FormularioPublicoIncidencias /> },
  { path: "/qr_junta/:IdComu/:Identificador/:BaseDatos", element: <FormularioAsistenciaQR /> },

  // Redirection to home page
  { path: "/", element: <Navigate to={ROUTES.HOME} /> },
  { path: "*", element: <NotFound /> },
  {
    path: "/video_jitsi/:IdComu/:IdJunta/:NumeroJunta",
    element: (
      <AuthGuard>
        <VideoJitsi />
      </AuthGuard>
    ),
  },
  {
    path: "/video_jitsi/:IdComu/:IdJunta",
    element: (
      <AuthGuard>
        <VideoJitsi />
      </AuthGuard>
    ),
  },
  {
    path: "/img_qr_junta/:IdComu/:Identificador",
    element: (
      <AuthGuard>
        <ImagenQRJunta />
      </AuthGuard>
    ),
  },
  ,
  {
    path: "/img_qr_centrotrabajo/:Identificador",
    element: (
      <AuthGuard>
        <ImagenQRCentroTrabajo />
      </AuthGuard>
    ),
  },
];

export default routes;
