import React from 'react';

export const ContratoContenido = ({
  ContenidoPlan,
  ContenidoFormaPago,
  ContenidoIban,
  PrecioSelected,
}) => {
  return (
    <div className="contract">
      <div className="contract-header">
        <h1>Contrato de Servicios</h1>
        <p>Fecha: [Fecha]</p>
      </div>

      <div className="contract-body">
        <p>
          Este contrato se celebra entre [Nombre del Cliente], en adelante dsenominado el "Cliente",
          y [Nombre de la Empresa], en adelante denominado el "Proveedor".
        </p>

        <p>1. Descripción de los servicios:</p>
        <p>El Proveedor se compromete a proporcionar los siguientes servicios al Cliente:</p>
        <ul>
          <li>{ContenidoPlan}</li>
          <li>{ContenidoFormaPago}</li>
          <li>{ContenidoIban}</li>
          <li>{PrecioSelected} €</li>
        </ul>

        <p>2. Condiciones de pago:</p>
        <p>El Cliente pagará al Proveedor de la siguiente manera: [Detalles de pago].</p>

        <p>3. Duración del contrato:</p>
        <p>
          Este contrato tendrá una duración de [Número de meses/años] y entrará en vigencia a partir
          de [Fecha de inicio].
        </p>
      </div>

      <div className="signature">
        <p>Firma del Cliente: ________________________</p>
        <p>Firma del Proveedor: ______________________</p>
      </div>
    </div>
  );
};
