import Loadable from "app/components/Loadable";
import { lazy } from "react";

const ZonasComunesTabla = Loadable(lazy(() => import("./ZonasComunesTabla")));
const ZonaComunDetalle = Loadable(lazy(() => import("./ZonaComunDetalle")));
const AnadirZonaComun = Loadable(lazy(() => import("./AnadirZonaComun")));
const ZonasComunesHistorial = Loadable(lazy(() => import("./ZonasComunesHistorial")));

const zonascomunesRoutes = [
  { path: "/zonascomunes/:Identificador", element: <ZonasComunesTabla /> },
  { path: "/historial_reservas/:IdComu/:Identificador", element: <ZonasComunesHistorial /> },
  {
    path: "/historial_reservas/:IdComu/:Identificador/:bloquear",
    element: <ZonasComunesHistorial />,
  },
  { path: "/detalle_zonacomun", element: <ZonaComunDetalle /> },
  { path: "/detalle_zonacomun/:IdComu/:Identificador", element: <ZonaComunDetalle /> },
  { path: "/detalle_zonacomun/:IdComu/:Identificador/:volver", element: <ZonaComunDetalle /> },
  { path: "/addzonacomun/:Identificador", element: <AnadirZonaComun /> },
];

export default zonascomunesRoutes;
