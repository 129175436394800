import { useEffect, useState, useRef } from "react";
import { get } from "firebase/database";
import { TextField, Checkbox } from "@mui/material";
import * as ROUTES from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import { useStore } from "react-redux";
import { useFirebase } from "../../../app/firebase/context";
import { Semaforito } from "app/components/Semaforito/Semaforito";
import React from "react";
import Grid from "@mui/material/Grid";
import "font-awesome/css/font-awesome.min.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import { MultiSelect } from "react-multi-select-component";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BackButton from "app/components/helpers/BackButton";
import { RefreshButton } from "app/components/helpers/Refresh";
import { ExportToCSV, ExportToPDF } from "app/library/ExportCsvPdf";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { onValue, update, push, set, remove } from "firebase/database";
import { loadAdminUsers } from "./FuncionesAdmin";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import {
  ref,
  getDownloadURL,
  deleteObject,
  getStorage,
  uploadBytes,
  uploadString,
  listAll,
  copyTo,
} from "firebase/storage";
import { TbArrowAutofitDown } from "react-icons/tb";

const AdminAnadirCentroDeTrabajo = () => {
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  let [adminUsersList, setAdminUsersList] = useState([]);
  let [comunidadesSeleccionadas, setComunidadesSeleccionadas] = useState([]);
  let [numRtdos, setNumRtdos] = useState([]);
  const datosIniciales = useRef(adminUsersList);
  const listaFiltros = ["Nombre", "Id", "Cif", "Direccion"];
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const { Identificador, IdComu, volver } = useParams();
  const [enableGuardar, setEnableGuardar] = useState(false);
  const routeAnadir = ROUTES.ANADIR_INCIDENCIA;
  const [coordinates, setCoordinates] = useState(null);

  const defaultCenter = {
    lat: 41.649693,
    lng: -0.887712,
  };

  const [state, setState] = useState({
    nombre: "",
    latitud: "",
    longitud: "",
    distancia: "",
    descripcion: "",
    usuarios: [],
    UsuariosSeleccionados: [],
    UsuariosDisponibles: [],
  });
  const apiKey = "";
  const maxArchivos = 1;
  const maxFileSizeMB = 20; // Maximum file size in megabytes
  const fileTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
  });
  const [refrescar, setRefrescar] = useState(false);

  const firebase = useFirebase();

  const navigate = useNavigate();

  const routeDetalle = ROUTES.DETALLE_CENTRO_TRABAJO;

  const traerUsuarios = async () => {
    try {
      let snapshot = await get(firebase.getReferencia("Usuarios", null));
      let datos = [];

      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        let childData = childSnapshot.val();
        childData.idInterno = childKey;
        if (
          Identificador &&
          childData.Comunidades &&
          Object.keys(childData.Comunidades).includes(Identificador)
        ) {
          datos.push({
            label: childData.Nombre + " " + childData.Apellido,
            value: childData.idInterno,
          });
        }
      });

      return datos;
    } catch (error) {
      console.error("Error al cargar usuarios administradores", error);
      throw error; // Puedes lanzar nuevamente el error para que se maneje en el nivel superior si es necesario
    }
  };

  const guardarDatos = async () => {
    const comUpdatear = await get(firebase.getReferencia(`PuestoTrabajo`, null));
    /*const datos = comUpdatear.val();
    if (
      Object.keys(datos)
        .map((puesto) => datos[puesto].Nombre)
        .includes(state.nombre)
    ) {
      toast.error(
        diccionario.CentroDeTrabajoCreadoAnteriormente,
        getCustomToastOptions(toast, "error")
      );
    } else { */
    const updatedData2 = {
      Nombre: state.nombre,
      Coordenadas: {
        lat: state.latitud,
        lng: state.longitud,
      },
      Descripcion: state.descripcion,
      Distancia: state.distancia / 1000,
      Usuarios: state.UsuariosSeleccionados && state.UsuariosSeleccionados.map((usu) => usu.value),
    };
    Identificador && (updatedData2.Comunidad = Identificador);

    const comUpdatear2 = firebase.getReferencia(`PuestoTrabajo`);

    const newRef = await push(comUpdatear2, updatedData2);
    const newId = newRef.key;
    const extraParam = Identificador ? "/" + Identificador : "";
    navigate(routeDetalle + "/" + newId + extraParam);
    toast.success(diccionario.Completado, getCustomToastOptions(toast));
  };

  const handleMapClick = (event) => {
    const newCoordinates = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setCoordinates(newCoordinates);
    setState({ ...state, latitud: event.latLng.lat(), longitud: event.latLng.lng() });
    setEnableGuardar(true);
  };

  const loadUsuariosCentroTrabajo = async (users) => {
    try {
      let snapshot = await get(firebase.getReferencia(`PuestoTrabajo/`, null));
      const datos = snapshot.val();

      datos.UsuariosDisponibles = users.map((usuario) => {
        return {
          value: usuario.idInterno,
          label: usuario.Nombre + " " + usuario.Apellido,
        };
      });

      setState({
        ...state,
        UsuariosDisponibles: datos.UsuariosDisponibles ? datos.UsuariosDisponibles : [],
        UsuariosSeleccionados: datos.Usuarios
          ? datos.UsuariosDisponibles.filter((usu) => datos.Usuarios.includes(usu.value))
          : [],
      });
    } catch (error) {
      console.error("Error al cargar usuarios administradores", error);
    }
  };
  useEffect(async () => {
    let listaUsuarios = "";

    if (Identificador) {
      listaUsuarios = await traerUsuarios();
      setState({ ...state, UsuariosDisponibles: listaUsuarios });
    } else {
      listaUsuarios = await loadAdminUsers(firebase);
      loadUsuariosCentroTrabajo(listaUsuarios);
    }
  }, [refrescar]);

  useEffect(async () => {
    if ((state.nombre != "" && state.latitud != "", state.longitud != "", state.distancia != "")) {
      setEnableGuardar(true);
    } else {
      setEnableGuardar(false);
    }
  }, [state]);

  /* objeto que guarda los valores de los inputs */
  const [expanded, setExpanded] = useState(true);

  /* objeto que guarda los valores de los inputs */
  const filterObject = {};

  for (const field of listaFiltros) {
    filterObject[field] = "";
  }

  const [filter, setFilter] = useState({
    Id: "",
    Nombre: "",
    Cif: "",
    Poblacion: "",
    Cp: "",
    Vecinos: "",
    Fecha_Creacion: "",
    Provincia: "",
    Direccion: "",
    Alta: "",
    fechaAlta: "",
  });

  return (
    <div className="container-detallecomunidad">
      <h2 style={{ fontWeight: "400", marginLeft: "-6px" }}>{diccionario.AnadirCentroDeTrabajo}</h2>
      <p />
      <div>
        <Grid container spacing={3} className="datos-detalle" style={{ marginTop: "10px" }}>
          <Grid className="Buscador" item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <p>
              <label>{diccionario.Nombre}</label>
            </p>
            <TextField
              value={state.nombre}
              name="nombreCentroDeTrabajo"
              onChange={(e) => {
                setState({ ...state, nombre: e.target.value });
              }}
            />
          </Grid>{" "}
          <Grid className="Buscador" item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <p>
              <label>{diccionario.descripcion}</label>
            </p>
            <TextField
              value={state.descripcion}
              name="descripcion"
              onChange={(e) => {
                setState({ ...state, descripcion: e.target.value });
              }}
            />
          </Grid>{" "}
          <Grid className="Buscador" item lg={4} md={4} sm={12} xs={12} sx={{ mt: 2 }}>
            <p>
              <label>{diccionario.Latitud}</label>
            </p>
            <TextField
              value={state.latitud}
              name="latitud"
              onChange={(e) => {
                setState({ ...state, latitud: e.target.value });
              }}
            />
          </Grid>{" "}
          <Grid className="Buscador" item lg={4} md={4} sm={12} xs={12} sx={{ mt: 2 }}>
            <p>
              <label>{diccionario.Longitud}</label>
            </p>
            <TextField
              value={state.longitud}
              name="longitud"
              onChange={(e) => {
                setState({ ...state, longitud: e.target.value });
              }}
            />
          </Grid>{" "}
          <Grid className="Buscador" item lg={4} md={4} sm={12} xs={12} sx={{ mt: 2 }}>
            <p>
              <label>{diccionario.Distancia + " (mt)"}</label>
            </p>
            <TextField
              value={state.distancia}
              name="distancia"
              onChange={(e) => {
                setState({ ...state, distancia: e.target.value });
              }}
            />
          </Grid>{" "}
          <Grid className="Buscador" item lg={4} md={4} sm={12} xs={12} sx={{ mt: 2 }}>
            <p>
              <label>{diccionario.Usuarios}</label>
            </p>
            {/*Aqui, ¿hay que añadir todos los usuarios para que pueda seleccionar o que? */}
            <MultiSelect
              options={state.UsuariosDisponibles}
              value={state.UsuariosSeleccionados}
              onChange={(selected) => {
                const selectedValues = selected.map((item) => ({
                  label: item.label,
                  value: item.value,
                }));
                setState({ ...state, UsuariosSeleccionados: selectedValues });
              }}
              labelledBy="Select"
            />
          </Grid>{" "}
        </Grid>
        <div className="AppGooglo" style={{ marginTop: "20px" }}>
          {/* Search input 
          <input
            type="text"
            value={address}
            onChange={handleAddressChange}
            placeholder="Enter address"
          />
          <button onClick={handleSearch}>Search</button>*/}
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              mapContainerStyle={{ height: "100%", width: "100%" }}
              zoom={8}
              center={coordinates || defaultCenter}
              onClick={handleMapClick}
              // Use your API key here
              apiKey={apiKey}
            >
              {/* Marker */}
              {coordinates && <Marker position={coordinates} />}
            </GoogleMap>
          )}
        </div>
      </div>
      <div className="row form-actions-fixed" id="footerInsert">
        <hr className="col-12 p-0 m-0 mb-3" id="LineBottom"></hr>
        <div className="text-left px-0 col-6">
          <BackButton />
        </div>
        <div className="text-right px-0 col-5">
          <button
            onClick={() => guardarDatos()}
            className="btn btn-outline-primary mx-2"
            disabled={!enableGuardar}
          >
            {diccionario.Anadir}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminAnadirCentroDeTrabajo;
