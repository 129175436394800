import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { authRoles } from "../../auth/authRoles";
import ComunidadesTabla from "./ComunidadesTabla2";
import ComunidadDetalle from "./ComunidadDetalle";

const ComunidadFormModificar = Loadable(lazy(() => import("./ComunidadFormModificar")));
/*const ComunidadDetalle = Loadable(lazy(() => import('./ComunidadDetalle')));*/

const comunidadRoutes = [
  { path: "/listacomunidades", element: <ComunidadesTabla /> },
  { path: "/comunidad", element: <ComunidadFormModificar /> },
  { path: "/detalle_comunidad", element: <ComunidadDetalle /> },
  { path: "/detalle_comunidad/:Identificador", element: <ComunidadDetalle /> },
];

export default comunidadRoutes;
