import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'; // Importar jsPDF Autotable

export const ExportToPDF = (arrData, header, fields, fileName, title,rowWidths=null) => {

  let config = {
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4', // or another format as needed
    compress: true,
    putOnlyUsedFonts: true,
    floatPrecision: 16, // or another value
  };

  let configAutoTable = {
    head: [header],
    body: arrData.map((data) => fields.map((field) => data[field])),
    startY: 20, // Ajustar la posición inicial de la tabla
  }

  if(rowWidths){
    configAutoTable.columnStyles = rowWidths;
  }

  // Specify the orientation as 'landscape' and set a wider width
  const doc = new jsPDF(config);

  // Agregar título
  doc.text(title, 10, 10);

  // Crear una tabla usando autoTable
  autoTable(doc, configAutoTable);

  const finalFilename = fileName ? fileName : new Date().toISOString().slice(0, 10) + '.pdf';

  doc.save(finalFilename);
};

export const ExportToCSV = (arrData, header, fields, fileName, title, diccionario) => {

  let c = 0;
  const csvHeaders = header.map((fieldName) => {
    const headerField = { label: fieldName, key: fields[c] };
    c++;
    return headerField;
  });
  const customSeparator = ';';
  const finalFilename = fileName ? fileName : new Date().toISOString().slice(0, 10) + '.csv';

  return (
    <button className="btn btn-outline-primary mx-2">
      {diccionario.exportarCSV}
      <CSVLink
        data={arrData}
        headers={csvHeaders}
        filename={finalFilename}
        separator={customSeparator}
      >
        {diccionario.exportarCsv}
      </CSVLink>
    </button>
  );
};
