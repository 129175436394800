import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { authRoles } from "../../auth/authRoles";

const Votaciones = Loadable(lazy(() => import("./Votaciones")));
const VotacionDetalle = Loadable(lazy(() => import("./VotacionDetalle")));
const AddVotacion = Loadable(lazy(() => import("./AddVotacion")));

const VotacionesRoutes = [
  { path: "/votaciones/:IdComu", element: <Votaciones /> },
  { path: "/votaciones/:IdComu/junta/:IdJunta", element: <Votaciones /> },
  {
    path: "/detalle_votacion/:IdComu/:Identificador",
    element: <VotacionDetalle />,
  },
  {
    path: "/detalle_votacion/:IdComu/:Identificador/:volver/",
    element: <VotacionDetalle />,
  },
  {
    path: "/detalle_votacion_junta/:IdComu/:IdJunta/:Identificador",
    element: <VotacionDetalle />,
  },
  {
    path: "/detalle_votacion_junta/:IdComu/:IdJunta/:Identificador/:volver/",
    element: <VotacionDetalle />,
  },
  /*
  {
    path: "/detalle_votacion/:IdComu/:Identificador/:volver/:IdJunta",
    element: <VotacionDetalle />,
  },
*/

  { path: "/addvotacion/:IdComu", element: <AddVotacion /> },
  { path: "/addvotacion/:IdComu/:IdJunta", element: <AddVotacion /> },
  { path: "/addvotacion", element: <AddVotacion /> },
];

export default VotacionesRoutes;
