import { get } from "firebase/database";
import { IsDateOlderThanNDays, getDireccionCompleta } from "app/components/helpers/Various";

const dateStringToTimestamp = (dateString) => {
  try {
    let timestamp = "";
    if (dateString) {
      // Split the date and time
      const [datePart, timePart] = dateString.split(" ");
      // Split the date into day, month, and year
      const [day, month, year] = datePart.split("-");
      // Combine the parsed values into a valid date string for JavaScript
      const formattedDateStr = `${year}-${month}-${day}T${timePart}:00`; // Add seconds as ":00"
      // Create a new Date object
      const dateObj = new Date(formattedDateStr);
      // Convert to timestamp (milliseconds since Unix epoch)
      timestamp = dateObj.getTime();
    }
    return timestamp;
  } catch (error) {
    console.log("ERROR:" + error);
  }
};

export const getEstadoIncidencia = (rowData, diccionario) => {
  let colorSeleccionado = "";
  let textoExplicativo = "";
  const fechaComp = rowData.Fecha_Creacion;
  /*
ABIERTA: activándose en la tabla de ventana principal y ventana detalle el (ICONO 27) SEMÁFORO en color rojo.
CERRADA: activándose en la tabla de ventana principal y ventana detalle el (ICONO 27) SEMÁFORO en color  verde.
CANCELADA:activándose en la tabla de ventana principal y ventana detalle el (ICONO 27) SEMÁFORO en color verde.
EN CURSO:activándose en la tabla de ventana principal y ventana detalle el (ICONO 27) SEMÁFORO en color amarillo. Cuando una incidencia esté en curso durante 8 días ó más, el sistema lo detectará y activará el semáforo a color rojo.
*/
  // Incidencia cerrada o cancelada
  if (rowData.Estado === "CERRADA" || rowData.Estado === "CANCELADA") {
    colorSeleccionado = "green";
    textoExplicativo = diccionario.incidenciaGreen;
    // Incidencia abierta o en curso desde hace 8 días o más
  } else if (rowData.Estado === "ABIERTA") {
    colorSeleccionado = "red";
    textoExplicativo = diccionario.incidenciaRed2;
    // Incidencia en curso desde hace menos de 8 días
  } else if (rowData.Estado === "EN CURSO" && fechaComp && !IsDateOlderThanNDays(fechaComp)) {
    colorSeleccionado = "yellow";
    textoExplicativo = diccionario.incidenciaYellow;
    // Incidencia abierta o en curso desde hace 8 días o más
  } else {
    colorSeleccionado = "red";
    textoExplicativo = diccionario.incidenciaRed;
  }

  return {
    colorSeleccionado: colorSeleccionado,
    textoExplicativo: textoExplicativo,
  };
};

export const getIdIncidencia = async (firebase, codIncidencia) => {
  let idIncidencia = null;
  if (codIncidencia) {
    const snapshot = await get(firebase.getReferencia("Incidencias", null));

    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;
      if (childData.Codigo === codIncidencia) {
        idIncidencia = childKey;
      }
    });
  }

  return idIncidencia;
};

export const cargarIncidencias = async (
  IdComu = null,
  firebase,
  listaComunidades,
  listaUsuRegistradores,
  diccionario
) => {
  try {
    const snapshot = await get(firebase.getReferencia("Incidencias", null));
    let datos = [];

    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;
      //childData.Codigo = childData.Codigo ? " " + childData.Codigo : " ";
      childData.Codigo = childData.Codigo ? childData.Codigo : "";
      childData.timestampCreacion = dateStringToTimestamp(childData.Fecha_Creacion);
      childData.timestampModificacion = dateStringToTimestamp(childData.Fecha_Modificacion);
      const comuAsociada = listaComunidades.filter((comu) => comu.value === childData.Comunidad);
      const usuAsociado = listaUsuRegistradores.filter(
        (usu) => usu.value === childData.IdRegistrador
      );

      childData.NombreComunidad = comuAsociada[0] && comuAsociada[0].label;
      childData.Registrador = usuAsociado[0] && usuAsociado[0].label;

      //childData.ActividadTrad = diccionario[childData.Actividad];
      childData.TipoTrad = diccionario[childData.Tipo];
      childData.EstadoTrad = diccionario[childData.Estado.replace(/\s/g, "")];

      //if (!childData.Borrado) {
      if (!IdComu || (IdComu && childData.Comunidad === IdComu)) {
        datos.push(childData);
      }
      //}
    });

    return datos;
  } catch (error) {
    console.error("Error al cargar las incidencias", error);
  }
};

export const traerActividades = async (firebase) => {
  const snapshot = await get(firebase.getReferencia("Actividad", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    datos.push({
      label: childData.Nombre,
      value: childData.Nombre,
      idActividad: childKey,
    });
  });

  return datos;
};

export const traerProveedores = async (firebase) => {
  const snapshot = await get(firebase.getReferencia("Gremio", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    datos.push({
      label: childData.Nombre,
      value: childData.Nombre,
      idProveedor: childKey,
      email: childData.email,
      email2: childData.email2,
      Telefono1: childData.Telefono1,
      Telefono2: childData.Telefono2,
    });
  });

  return datos;
};
export const validarCaracteresCarpeta = (event) => {
  // Disallow "/" and "." characters
  if (event.key === "/" || event.key === ".") {
    event.preventDefault();
  }
};
export const validarCaracteresCarpetaPaste = (event) => {
  // Disallow "/" and "." characters for pasting
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData("text");
  if (pastedText.includes("/") || pastedText.includes(".")) {
    event.preventDefault();
  }
};

export const traerUsuariosComunidad = async (firebase, idComunidad = null) => {
  try {
    const snapshot = await get(firebase.getReferencia("Usuarios", null));

    const dirRef = idComunidad ? `Comunidad/${idComunidad}` : "Comunidad";
    const snapshotComunidades = await get(firebase.getReferencia(dirRef));
    let comuComus = [];

    if (!idComunidad) {
      snapshotComunidades.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        let childData = childSnapshot.val();
        childData.idInterno = childKey;
        comuComus.push(childData);
      });
    } else {
      let childData = snapshotComunidades.val();
      childData.idInterno = idComunidad;
      comuComus.push(childData);
    }

    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;

      const nombreProp =
        childData.Roles && childData.Roles.length > 0
          ? childData.Nombre + " " + childData.Apellido + " " + " | " + childData.Roles.join(",")
          : childData.Nombre + " " + childData.Apellido;
      let strCompDirec = "";
      let arrDirecciones = [];
      let arrStrDirecciones = [];
      if (idComunidad) {
        const instancias = comuComus[0]["Instancias"] && comuComus[0]["Instancias"];

        arrDirecciones =
          Array.isArray(instancias) &&
          instancias.filter(
            (instancia) => instancia.Usuario === childKey && instancia.Tipo !== "empleadocomunidad"
          );
        Array.isArray(arrDirecciones) &&
          arrDirecciones.map((direcc) => {
            arrStrDirecciones.push(getDireccionCompleta(direcc));
          });
        arrStrDirecciones = arrStrDirecciones.filter((element) => element !== "");
        strCompDirec = arrStrDirecciones.length > 0 ? arrStrDirecciones.join(", ") : "";
      } else {
        // Si no nos llega idComunidad tendré que recorrer todas a las que pertenezca el usuario
        let instanciasMergeadas = [];
        comuComus.map((comu) => {
          const insts = comu.Instancias && comu.Instancias;

          arrDirecciones =
            Array.isArray(insts) &&
            insts.filter(
              (instancia) =>
                instancia.Usuario === childKey && instancia.Tipo !== "empleadocomunidad"
            );
          instanciasMergeadas = instanciasMergeadas.concat(arrDirecciones);
        });
        arrDirecciones = instanciasMergeadas;
        Array.isArray(arrDirecciones) &&
          arrDirecciones.map((direcc) => {
            arrStrDirecciones.push(getDireccionCompleta(direcc));
          });

        arrStrDirecciones = arrStrDirecciones.filter((element) => element !== "");
        strCompDirec = arrStrDirecciones.length > 0 ? arrStrDirecciones.join(", ") : "";
      }

      if (
        idComunidad &&
        childData.Comunidades &&
        Object.keys(childData.Comunidades).includes(idComunidad)
      ) {
        datos.push({
          label: nombreProp + " | " + strCompDirec,
          value: childKey,
          nombre: childData.Nombre,
          Nombre: childData.Nombre,
          DNI: childData.DNI,
          apellido: childData.Apellido,
          Apellido: childData.Apellido,
          idInterno: childData.idInterno,
          direcciones: arrDirecciones,
          email: childData.Email,
          Email: childData.Email,
          Comunidades: childData.Comunidades,
        });
      } else if (!idComunidad) {
        datos.push({
          label: nombreProp + " | " + strCompDirec,
          value: childKey,
          nombre: childData.Nombre,
          Nombre: childData.Nombre,
          DNI: childData.DNI,
          apellido: childData.Apellido,
          Apellido: childData.Apellido,
          idInterno: childData.idInterno,
          direcciones: arrDirecciones,
          email: childData.Email,
          Email: childData.Email,
          Comunidades: childData.Comunidades,
        });
      }
    });

    return datos;
  } catch (error) {
    console.error("Error al cargar usuarios", error);
  }
};

export const traerUsuariosRegistradores = async (firebase, idComunidad = null, userReg = null) => {
  try {
    const snapshot = await get(firebase.getReferencia("Usuarios", null));

    const dirRef = idComunidad ? `Comunidad/${idComunidad}` : "Comunidad";
    const snapshotComunidades = await get(firebase.getReferencia(dirRef));
    let comuComus = [];

    if (!idComunidad) {
      snapshotComunidades.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        let childData = childSnapshot.val();
        childData.idInterno = childKey;
        comuComus.push(childData);
      });
    } else {
      let childData = snapshotComunidades.val();
      childData.idInterno = idComunidad;
      comuComus.push(childData);
    }

    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;
      if (!childData.Borrado) {
        const nombreProp =
          childData.Roles && childData.Roles.length > 0
            ? childData.Nombre + " " + childData.Apellido + " " + " | " + childData.Roles.join(",")
            : childData.Nombre + " " + childData.Apellido;
        let strCompDirec = "";
        let arrDirecciones = [];
        let arrStrDirecciones = [];
        if (idComunidad) {
          const instancias = comuComus[0]["Instancias"] && comuComus[0]["Instancias"];

          arrDirecciones =
            Array.isArray(instancias) &&
            instancias.filter(
              (instancia) =>
                instancia.Usuario === childKey && instancia.Tipo !== "empleadocomunidad"
            );

          console.log("Como la copa de un pino", arrDirecciones);
          Array.isArray(arrDirecciones) &&
            arrDirecciones.map((direcc) => {
              arrStrDirecciones.push(getDireccionCompleta(direcc));
            });
          arrStrDirecciones = arrStrDirecciones.filter((element) => element !== "");
          strCompDirec = arrStrDirecciones.length > 0 ? arrStrDirecciones.join(", ") : "";
        } else {
          // Si no nos llega idComunidad tendré que recorrer todas a las que pertenezca el usuario
          let instanciasMergeadas = [];
          comuComus.map((comu) => {
            const insts = comu.Instancias && comu.Instancias;

            arrDirecciones =
              Array.isArray(insts) &&
              insts.filter(
                (instancia) =>
                  instancia.Usuario === childKey && instancia.Tipo !== "empleadocomunidad"
              );
            instanciasMergeadas = instanciasMergeadas.concat(arrDirecciones);
          });
          arrDirecciones = instanciasMergeadas;
          Array.isArray(arrDirecciones) &&
            arrDirecciones.map((direcc) => {
              arrStrDirecciones.push(getDireccionCompleta(direcc));
            });

          arrStrDirecciones = arrStrDirecciones.filter((element) => element !== "");
          strCompDirec = arrStrDirecciones.length > 0 ? arrStrDirecciones.join(", ") : "";
        }

        // Siempre incluyo el/los administrador/es y el usuario logeado en la web
        if (
          (childData.Comunidades && Object.keys(childData.Comunidades).includes("desk")) ||
          (userReg && childKey === userReg)
        ) {
          datos.push({
            label: nombreProp + " | " + strCompDirec,
            value: childKey,
            nombre: childData.Nombre,
            apellido: childData.Apellido,
            idInterno: childData.idInterno,
          });
          //} else if (idComunidad && childData.Comunidad !== idComunidad) {
        } else if (
          idComunidad &&
          childData.Comunidades &&
          Object.keys(childData.Comunidades).includes(idComunidad)
        ) {
          datos.push({
            label: nombreProp + " | " + strCompDirec,
            value: childKey,
            nombre: childData.Nombre,
            apellido: childData.Apellido,
            idInterno: childData.idInterno,
            Direcciones: arrDirecciones,
          });
          //} else if (idComunidad && childData.Comunidad !== idComunidad) {
        } else if (
          idComunidad &&
          childData.Comunidades &&
          !Object.keys(childData.Comunidades).includes(idComunidad)
        ) {
          // No hago nada
        } else if (!idComunidad) {
          datos.push({
            label: nombreProp + " | " + strCompDirec,
            value: childKey,
            nombre: childData.Nombre,
            apellido: childData.Apellido,
            idInterno: childData.idInterno,
          });
        }
      }
    });

    return datos;
  } catch (error) {
    console.error("Error al cargar propietarios", error);
  }
};

export const getListaEstados = (diccionario, fixAbierta = false) => {
  return [
    { label: diccionario.ABIERTA, value: "ABIERTA", isFixed: fixAbierta },
    { label: diccionario.CERRADA, value: "CERRADA" },
    { label: diccionario.CANCELADA, value: "CANCELADA" },
    { label: diccionario.ENCURSO, value: "EN CURSO" },
  ];
};

export const getListaTipos = (diccionario) => {
  return [
    { label: diccionario.INCIDENCIA, value: "INCIDENCIA" },
    { label: diccionario.SINIESTRO, value: "SINIESTRO" },
    { label: diccionario.SOLICITUD, value: "SOLICITUD" },
    { label: diccionario.CONSULTA, value: "CONSULTA" },
  ];
};

export const traerComunidadesConActivGremios = async (firebase, disableComunidadesBaja = false) => {
  const snapshot = await get(firebase.getReferencia("Comunidad", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    if (!childData.Borrada) {
      datos.push({
        label: childData.Nombre,
        value: childKey,
        // Deshabilito de las opciones las comunidades que no están de alta en caso de recibir el parámetro disableComunidadesBaja
        isDisabled: disableComunidadesBaja && childData.Alta === false ? true : false,
        activGremios: childData.ActivGremios,
      });
    }
  });
  return datos;
};
