import React from 'react';

export const getCustomToastOptions = (toast, tipo = 'success' , autoClose = 5000) => {
  return {
    title: 'Modificar',
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    autoClose: autoClose, // Tiempo en milisegundos antes de cerrar automáticamente la tostada
    style: {
      // Estilos CSS para la tostadagree'
      borderRadius: '8px',
      color: 'white',
      background: tipo === 'success' ? 'green' : tipo === 'info' ? 'blue' : 'red',
    },
    className: 'tostada-c635', // Clase CSS adicional para la tostada
  };
};
export default getCustomToastOptions;
