export const diccionario_cat = {
  hola: "Hola",
  empleados: "Empleats",
  empleado: "Empleat",
  organizacion: "Organització",
  estructura: "Estructura",
  organizativa: "organitzativa",
  crear: "Crear",
  visualizar: "Visualitzar",
  modificar: "Modificar",
  listados: "Llistats",
  listado: "Llistad",
  recursos: "Recursos Humans",
  resumen: "Resum",
  evaluados: "Avaluats",
  evaluado: "Avaluat",
  evaluacion: "Avaluació",
  empezar: "començar",
  empezada: "començada",
  evaluaciones: "Avaluacions",
  evaluada: "Avaluada",
  pruebas: "Proves",
  areas: "Àrees",
  evainiciadas: "Ava. iniciades",
  evarealizadas: "Ava. realitzades",
  ranking: "Ranking empleats per avaluació",
  nombre: "Nom",
  Nombre: "Nom",
  apellidos: "Cognoms",
  area: "Àrea",
  estado: "Estat",
  fecha: "Data",
  resultado: "Resultat",
  todas: "Totes",
  año: "any",
  competencias: "competències",
  destacadas: "destacades",
  buenas: "bones",
  normales: "normals",
  alejadas: "allunyades",
  submitida: "submesa",
  submitidas: "submeses",
  submitir: "submetre",
  completas: "completes",
  periodos: "Períodes",
  fomulario: "Formulari",
  actualizar: "Actualitzar",
  busqueda: "Camps de búsqueda",
  rellenar: "Omple un camp amb el valor de l'empleat que vols recuperar",
  rellenar2: "Omple un camp amb el valor de l'estructura que vols recuperar",
  limpiarcampos: "Buidar camps",
  seleccionado: "seleccionat",
  hombre: "Home",
  mujer: "Dona",
  otros: "Altres",
  genero: "Gènere",
  cargo: "Càrrec",
  acceso: "Accés",
  herramienta: "eina",
  registro: "registre",
  telefono: "Telèfon",
  inicio: "inici",
  Bueno: "Bo",
  Destacado: "Destacat",
  Alejado: "Allunyat",
  Normal: "Normal",
  sin: "Sense",
  finalizar: "finalitzar",
  aviso0: "Has de respondre almenys alguna pregunta del qüestionari per començar l'avaluació",
  aviso1: "Per poder finalitzar l'avaluació has de respondre totes les qüestions",
  aviso2: "Per submetre l'avaluació has d'activar el botó 'Submetre'",
  aviso3: "AVALUACIÓ SOMESA, qualsevol modificació posa't en contacte amb l'administrador",
  gerente: "Gerent",
  observaciones: "Observacions",
  aviso4: "¿Vols Voleu reobrir l'avaluació?",
  aviso5: "Vols submetre l'avaluació? Un cop submesa ja no la podràs modificar",
  deLaOrganizacion: "DE LA ORGANITZACIÓ (CORE)",
  PERSONALES: "PERSONALS",
  delPuesto: "DEL LLOC",
  funcionales: "(FUNCIONALS)",
  INTERPERSONALES: "INTERPERSONALS",
  delPuestoFuncionales: "DEL LLOC (FUNCIONALS)",
  DetalleIncidencia: "Detall de la incidència",
  DetalleVotacion: "Detall de la votació",
  Email: "Correu electrònic",
  Fecha_Creacion: "Data d'alta",
  Id: "Identificador",
  SOSBloqueo: "SOS",
  Codigo_seguridad: "Codi de seguretat",
  Codigo_vecino: "Codi de veí",
  Cp: "C.P.",
  Poblacion: "Població",
  Seguro_nombre: "Companyia d'assegurances",
  tooltip: "Companyia d'assegurances associada a la comunitat",
  Poliza: "Pòlissa",
  IBAN: "Compte IBAN",
  Vecinos: "Núm. de veïns",
  abstencion: "Abstenció",
  Pago_App: "Pagament mensual de l'aplicació",
  Cif: "CIF",
  tip_comunidad: "Companyia d'assegurances associada a la comunitat",
  listaComunidades: "Llista de comunitats",
  Direccion: "Adreça",
  Provincia: "Província",
  estadoComunidad: "Estat",
  Altac365: "Alta c365",
  Fecha_Baja: "Data de baixa",
  Bloqueada: "Bloquejada",
  tip_Bloqueada: "Premeu per bloquejar/desbloquejar l'accés dels usuaris a l'aplicació",
  tip_EnvioComunidades: "Envia avisos a les comunitats prèviament seleccionades",
  ENVIAR_AVISO: "ENVIAR AVÍS",
  Buscar: "Cercar",
  Buscador: "Cercador",
  Inicio: "Inici",
  Comunidades: "Comunitats",
  Incidencias: "Incidències",
  Gremios: "Gremis",
  Roles: "Rols",
  Notificaciones: "Notificacions",
  Despacho: "Despatx",
  Documentos: "Documents",
  Usuarios: "Usuaris",
  DescripcionMenuInicial: "Descripció del menú inicial",
  CIF: "CIF",
  Administracion: "Administració",
  AvisoLegal: "Avís Legal",
  PoliticaPrivacidad: "Política de Privadesa",
  PoliticaCookies: "Política de Cookies",
  DescComunidades: "Gestió de comunitats",
  DescIncidencias: "Gestió d'incidències",
  DescGremios: "Gestió de gremis",
  DescRoles: "Gestió de rols",
  DescUsuarios: "Gestió d'usuaris",
  DescNotificaciones: "Gestió de notificacions",
  DescAdministracion: "Gestió d'administració",
  Juntas: "Juntes",
  Votaciones: "Votacions",
  ZonasComunes: "Zones Comunes",
  Documentacion: "Documentació",
  Actividades: "Activitats",
  DescJuntas: "Gestió de Juntes",
  DescVotaciones: "Gestió de Votacions",
  DescZonasComunes: "Gestió de Zones Comunes",
  DescDocumentacion: "Gestió de Documentació",
  DescActividades: "Gestió d'Activitats",
  Detalle: "Detalle",
  Mostrando: "Mostrando",
  a: "a",
  de: "de",
  resultados: "resultados",
  HasSeleccionado: "Has seleccionat",
  comunidades: "comunitats",
  paraAviso: "per a l'enviament d'AVÍS",
  comunidad: "comunitat",
  Comunidad: "Comunitat",
  exportarPdf: "Exportar a PDF",
  exportarCsv: "Exportar a CSV",
  filasPagina: "Files per pàgina",
  comunidadesSeleccionadas: "comunitat/s seleccionada/des per avís.",
  CERRAR: "TANCAR",
  Restaurar: "Restaurar",
  tituloFormAvisos: "Enviament avisos",
  asunto: "Assumpte",
  textoAviso: "Text de l'avís",
  URLopcional: "URL opcional",
  diagoloTitulo: "Baixa de la comunitat",
  diagoloBody: "Està segur que voleu donar de baixa la comunitat?",
  cerrar: "Tancar",
  guardarCambios: "Guardar canvis",
  diagoloBodyAviso: "Esteu segur que voleu enviar l'avís?",
  diagoloTituloAviso: "Enviament d'avís",
  Guardar: "Guardar",
  CNAE: "CNAE",
  TlfnPrincipal: "Telèfon principal",
  TlfnSecundario: "Telèfon secundari",
  Email1: "Email 1",
  Email2: "Email 2",
  idProveedor: "Id Proveïdor",
  idComunidad: "Id Comunitat",
  fechaInicioContrato: "Data inici contracte",
  fechaFinContrato: "Data fi contracte",
  observaciones: "Observacions",
  sinresultados: "No s'han trobat resultats.",
  cancelar: "cancel·lar",
  aceptar: "Acceptar",
  Grupos: "Grups",
  AccesoATodo: "Accés a tot",
  Documentacion: "Documentació",
  ZonasReserva: "Zones de reserva",
  JuntasYVotaciones: "Juntes i votacions",
  Observaciones: "Observacions",
  Opciones: "Opcions",
  Acciones: "Acciones",
  tip_BorrarRol: "Premeu per esborrar un rol",
  confirmarBorradoRol: "Has marcat un rol per donar-lo de baixa, vols continuar?",
  tituloConfirmarBorradoRol: "Confirmar esborrat",
  Anadir: "Afegir",
  Proveedores: "Proveïdors",
  SoloPara: "Només per a:",
  Roles: "Roles",
  Numero: "Número",
  Portal: "Portal",
  Escalera: "Escala",
  Piso: "Pis",
  Letra: "Lletra",
  Inquilino: "Inquilí",
  Multipropiedad: "Multipropietat",
  Bloqueado: "Bloquejat",
  Usuario: "Usuari",
  AdministradorFincaColegiado: "Administrador de Finques Col·legiat",
  EmpleadoOficina: "Empleat d'Oficina",
  RegistroHorario: "Fichajes",
  Ano: "Any",
  SeleccionaAno: "Selecciona un any",
  Entrada: "Entrada",
  Salida: "Sortida",
  Centro: "Centre",
  Horas: "Hores",
  Semana: "Setmana",
  Fecha: "Data",
  TotalHoras: "Total Hores",
  Minutos: "Minuts",
  Modificar: "Modificar",
  Eliminar: "Eliminar",
  Filtrar: "Filtrar",
  tituloNuevoRegistroHorario: "Nou registre horari",
  confirmarBorradoRegistroHorario: "Voleu esborrar el registre horari?",
  tituloConfirmarBorradoRistroHorario: "Esborrar registre horari",
  EnviarCheckEmail: "Enviar Emails de verificació",
  tituloModificarRegistroHorario: "Modificar registre horari",
  MiCarpeta: "La meva Carpeta",
  ReseteoContrasena: "Reseteo Contrasenya",
  TextoReseteoContrasena:
    "Es resetejarà la contrasenya de l'usuari, en el moment que es faci l'actualització l'usuari haurà d'accedir a l'aplicació amb la nova contrasenya",
  AnadeContrasena: "Afegeix la contrasenya nova (almenys 6 dígits)",
  Borrar: "Esborrar",
  Todo: "Tot",
  Reservas: "Reserves",
  Despachos: "Despatxos",
  hasCambiadoDespacho: "Ara ets al despatx",
  enviarAviso: "Enviar Avís",
  DNI: "DNI",
  descripcion: "Descripció",
  tipo: "Tipus",
  TextoAyudaActividades:
    "Selecciona les activitats sobre les que vulguis que els teus veïns puguin enviar incidències. Només les icones de les activitats seleccionades apareixeran al mòbil dels teus veïns. Els administradors rebran sempre les incidències per activitat, si vols que les rebi també un proveïdor en concret associat des del detall de l'activitat.",
  EliminarUsuario: "Eliminar Usuari",
  ConfirmarEliminarUsuario: "Voleu eliminar l'usuari?",
  DetalleActividad: "Detall de l'actividat",
  AnadirActividad: "Afegir activitat",
  tip_BorrarActividad: "Fes clic per esborrar una activitat",
  confirmarBorradoActividad: "Has marcat una activitat per donar-la de baixa, vols continuar?",
  tituloConfirmarBorradoActividad: "Confirmar esborrat",
  Icono: "Icona",
  Si: "Si",
  No: "No",
  cuerpoDesBloquearUsuario: "Voleu desbloquejar l'usuari ",
  tituloDesBloquearUsuario: "Desbloquejar usuari ",
  cuerpoBloquearUsuario: "Voleu bloquejar l'usuari ",
  tituloBloquearUsuario: "Bloquejar usuario ",
  Porcentaje: "Percentatge",
  Proveedor: "Proveïdor",
  SeleccionaProveedor: "Cerca i selecciona un proveïdor...",
  UsuariosRegistradores: "Usuaris registradors",
  FalloPago: "El pagament ha fallat, intenta-ho de nou més tard.",
  Gobierno: "Junta de govern",
  Administración: "Administració",
  Mantenimiento: "Manteniment",
  tituloEnvioCorrecto: "Resultat de l'enviament",
  mensajeResultados: "Avís enviat correctament a ${var1} destinataris",
  tip_BorrarGremio: "Prem per esborrar un gremi",
  confirmarBorradoGremio: "Has marcat un gremi per donar-lo de baixa, vols continuar?",
  tituloConfirmarBorradoGremio: "Confirmar esborrat",
  preciosSinIva: "Tots els preus són sense IVA.",
  preciosPorVecino:
    "Els preus varien depenent del nombre de veïns de la comunitat. (Aquesta comunitat té ${var1} veïns)",
  preciosPorVecinoHistorico:
    "Els preus varien depenent del nombre de veïns de la comunitat. (Aquesta comunitat tenia ${var1} veïns en el moment de la contractació)",
  CreadoPor: "Creat per",
  estadoContrato: "Estat",
  asociarActividad: "L'activitat ara sortirà al mòbil dels usuaris de la comunitat.",
  desasociarActividad: "L'activitat ja no sortirà al mòbil dels usuaris de la comunitat.",
  asociarTodasActividades:
    "Ara sortiran totes les activitats al mòbil dels usuaris de la comunitat",
  desasociarTodasActividades: "Ara no sortirà cap activitat al mòbil dels usuaris de la comunitat",
  EnvioAvisoEmail: "A més de l'avís, enviar també per email als implicats",
  Codigo: "Codi",
  Tipo: "Tipus",
  Actividad: "Activitat",
  Registrador: "Registrador",
  tip_BorrarIncidencias: "Fes clic per esborrar la incidència",
  tip_compartirIncidencia: "Compartir/publicar la incidència",
  tip_ModificarRegistroHorario: "Fes clic per editar el registre",
  tip_BorrarRegistroHorario: "Premeu per esborrar el registre",
  Compartir: "Compartir",
  red: "Rojo",
  yellow: "Groc",
  green: "Verd",
  incidenciaGreen: "Incidència tancada o cancel·lada",
  incidenciaYellow: "Incidència en curs",
  incidenciaRed: "Incidència sense tancar des de fa 8 dies o més",
  ENVIAR_AVISO_EMAIL: "Enviar Email de Regristro",
  adminDesk: {},
  SeleccionaComunidad: "Selecciona una comunitat",
  SeleccionaEstado: "Selecciona un estat",
  SeleccionaActividad: "Selecciona una activitat",
  SeleccionaProveedor2: "Selecciona un proveïdor",
  Estado: "Estat",
  ABIERTA: "OBERTA",
  CERRADA: "TANCADA",
  CANCELADA: "CANCEL·LADA",
  ENCURSO: "EN CURS",
  SeleccionaTipo: "Selecciona tipus",
  INCIDENCIA: "AVERIA",
  SINIESTRO: "SINIESTRE",
  SOLICITUD: "SOL·LICITUD",
  CONSULTA: "CONSULTA",
  NuevaIncidencia: "Nova incidència",
  DetalleIncidencia: "Detall de la incidència",
  DetalleVotacion: "Detall de la votació",
  CodigoDespacho: "Codi de despatx",
  CodigoComunidad: "Codi de comunitat",
  SeleccionaRegistrador: "Selecciona un registrador",
  DescripcionIncidencia: "Descripció de la incidència (visible pels usuaris de l'app)",
  DatosGuardadosCorrectamente: "Dades desades correctament.",
  ErrorGuardarDatos: "Error en desar les dades.",
  mensajePublicarIncidencia:
    "En activar aquesta opció la incidència serà publicada per a tots els veïns de la comunitat. Qualsevol modificació i posterior enviament d'avís es realitzarà a tots els propietaris registrats.",
  Fecha_Modificacion: "D. modificació",
  EmailProveedor: "Email proveïdor",
  TelefonoProveedor: "Telèfon proveïdor",
  ActualizaDescripcionInterna:
    "Notes internes de la incidència (visibles només pels administradors i empleats d'oficina)",
  HistoricoDescripcionInterna: "Històric de la incidència",
  TituloBorrarIncidencia: "Confirmar esborrat d'incidència",
  CuerpoBorrarIncidencia: "Per marcar la incidència com a esborrada prem acceptar.",
  IncidenciaBorradaCorrectamente: "Incidència esborrada correctament",
  errorBorrarIncidencia: "Error en esborrar la incidència",
  incidenciaBorrada: "Incidència esborrada",
  datosRefrescados: "Dades refrescades",
  Documentacion: "Documentació",
  DocumentacionAdjunta: "Documentació adjunta",
  DocumentacionAdjuntaDesc: "Documents associats a la incidència",
  arrastraArchivos: "Arrossega i deixa anar els teus fitxers per pujar-los a la carpeta",
  TextoPropiedadDocumentos:
    "* Tots els documents pujats a C365 són propietat de la comunitat, i seran traspassats en cas de canvi d'administrador. Si consideres que un document no ha de ser traspassat, si us plau, indica-ho a l'opció “Modificar” d'aquest document",
  textoConfirmarBorradoCarpeta: "Estàs segur que vols esborrar la carpeta?",
  tituloConfirmarBorradoCarpeta: "Esborrar carpeta:",
  textoConfirmarBorradoFichero: "Estàs segur que vols esborrar el fitxer?",
  tituloConfirmarBorradoFichero: "Esborrar fitxer:",
  ExitoBorradoFichero: "Fitxer esborrat correctament",
  FalloBorradoFichero: "Fallada en esborrar el fitxer",
  Descargar: "Descarregar",
  SeleccionaCompaniaSeguros: "Selecciona CIA d'Assegurances",
  PolizaSeguros: "Pòlissa d'Assegurances",
  Direccion_es: "Direcció/ca",
  PorcentajeTotalPropiedad: "% Total",
  TotalReservas: "Total Reserves",
  PorcPropiedad: "% Propietat",
  ViviendaInquilino: "Habitatge amb Inquilí",
  ViviendaRepLegal: "Habitatge amb Rep.legal",
  propietario: "Propietari",
  inquilino: "Inquilí",
  representantelegal: "Representant Legal",
  empresa: "Empresa",
  ViviendasTipo: "Habitatges / Tipus",
  SinViviendas: "Sense habitatges",
  AltaCorrecta: "Dat de baixa correctament.",
  FalloBajaComunidad: `Fallada en donar de baixa la comunitat`,
  ComunidadNoAlta: "La comunitat no està alta.",
  ErrorGuardadoDatos: "Error en desar les dades.",
  ErrorDatosExistentes: "L'activitat ${var1} ja existeix, si us plau utilitza un altre nom.",
  DatosActualizadosCorrectamente: "Dades actualitzades correctament",
  ActividadBorradaCorrectamente: "Activitat esborrada correctament",
  ActividadExistente: "L'activitat ${var1} ja existeix, si us plau utilitza un altre nom.",
  FechaAlta: "Data d'alta",
  CodigoVecino: "Codi Veí",
  CodigoPostal: "Codi Postal",
  NumeroVecinos: "Nombre de Veïns",
  Siniestros: "Sinistres",
  ModificacionClientes: "Modificació clients",
  ConsultasAdmin: "Consultes admin",
  ConsultasSeguros: "Consultes assegurances",
  Categoria: "Categoria",
  Alta: "Alta",
  ConfirmarBajaComunidad:
    "Estàs segur que vols donar de baixa la comunitat amb id ${var1} de C365?",
  MaximoArchivos: "Només pots pujar fins a un màxim de ${var1} fitxers",
  TipoArchivoNoPermitido: "Tipus de fitxer no permès: ${var1}",
  ArchivoTamañoMaximoSuperado: "El fitxer ${var1} excedeix la mida màxima permesa de ${var2} MB",
  ArchivosSubidosCorrectamente: "Fitxers pujats correctament.",
  NombreCarpetaVacio: "El nom de la carpeta no pot estar buit.",
  MismoNombreArchivoMismoNivel: "No pots posar el mateix nom a un document del mateix nivell",
  CarpetaCreadaCorrectamente: "Carpeta creada correctament.",
  ErrorCrearCarpeta: "Error en crear la carpeta.",
  CarpetaMovidaCorrectamente: "Fitxer/Carpeta mogut correctament.",
  ErrorMoverCarpeta: "Error en moure el fitxer/carpeta.",
  Carpeta03NoBorrar: "La carpeta 03 Juntes de Propietaris no es pot esborrar/modificar",
  GuardarCambiosModificar: "Per desar els canvis has de modificar el fitxer",
  ErrorCambiarTransferibilidad: "Error en canviar la transferibilitat",
  NombreCambiadoCorrectamente: "Nom canviat correctament.",
  ErrorCambiarNombre: "Error en canviar el nom.",
  TipoArchivoNoPermitido: "Tipus de fitxer no permès",
  DocumentosComunidad: "Documents de la comunitat",
  Busqueda: "Cerca",
  CarpetaActual: "Carpeta actual :",
  NombreCarpeta: "Nom de la carpeta",
  Descargar: "Descarregar",
  Borrar: "Esborrar",
  EnviarAviso: "Enviar avís",
  AvisoDocumentosSubidosC365:
    "Tots els documents pujats a C365 són propietat de la comunitat, i seran traspassats en cas de canvi d'administrador. Si consideres que un document no ha de ser traspassat, si us plau, indica-ho a l'opció “Modificar” d'aquest document",
  SeleccionarCarpetaMostrarDetalle: "Selecciona una carpeta per mostrar-ne el detall",
  TipoArchivoNoPermitido: "Tipus de fitxer no permès: ${var1}",
  TodoOk: "tot ok",
  GremioCreadoConecta: "El gremi ha estat creat per Connecta o no té associada activitat",
  ProveedorBaja: "El proveïdor ha estat donat de baixa",
  CasoNoCompletado: "cas no contemplat",
  FechaAlta: "Data Alta",
  FechaBaja: "Data Baixa",
  GremioBorradoCorrectamente: "Gremi esborrat correctament",
  Comus: "comus",
  CambioEstado: "Canvi d'estat",
  NotificacionUsuarioAceptarOCancelar:
    "Pots enviar notificació a l'usuari registrador, a l'administrador i un e-mail al proveïdor sobre l'actualització de la incidència (EN CURS) o (OBERTA) prem ACCEPTAR o CANCEL·LAR",
  CambioEstadoIncidencia: "Canvi d'estat d'incidència",
  CambioEstadoIncidenciaCodigo:
    "S'ha canviat d'estat la incidència amb codi ${var1} de ${var2} a ${var3}",
  CambioEstadoIncidenciaCodigoCerrada:
    "S'ha canviat d'estat la incidència amb codi ${var1} ha estat tancada ",
  ArchivoExcedeTamano: "El fitxer excedeix la mida màxima permesa de ${var1} MB",
  CambioTipo: "Canvi de tipus",
  NotificacionIncidenciaAceptarOCancelar:
    "Pots enviar notificació a l'usuari registrador, a l'administrador i un e-mail al proveïdor sobre el canvi del tipus d'incidència a SINISTRE, prem “ACCEPTAR” o “CANCEL·LAR”.",
  CambioTipoIncidencia: "Canvi de tipus d'incidència",
  CambiadoTipoIncidenciaCodigo: "S'ha canviat el tipus de la incidència amb codi ",
  CambiadoTipoAveriaCodigo:
    "Pots enviar notificació a l'usuari registrador, a l'administrador i un correu electrònic al proveïdor sobre el canvi del tipus d'icidència a AVARIA, prem “ACCEPTAR” o “CANCEL·LAR”.",
  FechaModificacion: "Data modificació",
  FechaCreacion: "Data creació",
  NombreRegistrador: "Nom Registrador",
  ApellidoRegistrador: "Cognom Registrador",
  RolExiste: "El rol ${var1} ja existeix, si us plau n'utilitza un altre.",
  DatosRefrescados: "Dades refrescades",
  RolBorradoCorrectamente: "Rol esborrat correctament",
  LimpiarBusqueda: "Netejar cerca",
  LimpiarSeleccionado: "Netejar seleccionat",
  NoHayRoles: "No hi ha rols",
  SeleccionarTodos: "Seleccionar tots",
  SeleccionarTodosFiltrado: "Seleccionar tots (filtrat)",
  RolSeleccionado: "Rol seleccionat...",
  EmailYaExiste: "L'Email ja existeix",
  DNIYaExiste: "El DNI ja existeix",
  CompletaCamposObligatiorio: "Si us plau, completa tots els camps obligatoris.",
  NoValidoIntroduceCorrecto: "El DNI no és vàlid, n'introdueix un de correcte",
  FotoBorradaCorrectamente: "Foto esborrada correctament.",
  FotoGuardadaCorrectamente: "Foto guardada correctament.",
  AnadirUsuario: "Afegir Usuari",
  FechaIncorrecta: "La data és incorrecta.",
  NoFechasVacias: "No es pot deixar una de les dates buides",
  HorasMinutos: "Hores minuts",
  RegistroHorarioBorrado: "Registre horari esborrat correctament",
  ErrorBorrandoRegistro: "Error esborrant registre",
  HoraSalidaEntradaIncorrecta: "L'hora de sortida no pot ser menor que la d'entrada",
  TodosRolesSeleccionados: "Tots els rols han estat seleccionats.",
  SeleccionarRoles: "Seleccionar rols",
  RegistroInquilinoCambiado: "Registre d'inquilí canviat.",
  ErrorGuardarDatos: "Error en desar les dades",
  UsuarioEliminadoCorrectamente: "Usuari eliminat correctament.",
  DNIExisteOtroUsuario: "El DNI ja existeix en un altre usuari",
  EmailExisteOtroUsuario: "L'Email ja existeix en un altre usuari",
  ContrasenaActualizadaNuevaContrasena: "La contrasena ha estat actualitzada. Nova contrasena:",
  ErrorCambiarContrsena: "Error en canviar la contrasenya.",
  ErrorEnviandoEmailConfirmacion: "Error enviant l'email de verificació: ",
  EnviadoCorreoConfirmacionA: "S'ha enviat el correu de verificació a ",
  AltaC365: "AltaC365",
  CodigoCompletisimo: "codigocompletíssim",
  VotoBorradoCorrectamente: "Voto esborrat correctament",
  TipoVotacion: "Tipus de votació",
  Publicar: "Publicar",
  AperturaVotacion: "Obertura votació",
  ResultadoPublicado: "Resultat publicat",
  PublicadoPorPorciento: "Publicat per %",
  AltaComunidadConfigurar:
    "Ja tens d'alta aquesta comunitat a c365, segueix els passos següents per configurar la teva comunitat:",
  BienvenidoC365: "Benvingut a c365!",
  GeneraCodigoVecinos: "Genera un codi perquè els teus veïns puguin registrar-se a l'app.",
  CompletaDatosProveedoresGestionIncidencias:
    "Completa les dades dels teus proveïdors contractats per gestionar incidències i sinistres.",
  SubeDoucmentacionComparte:
    "Puja la documentació que vulguis i comparteix-la amb els propietaris.",
  PreparaVotacionesJuntas: "Prepara votacions o juntes quan sigui necessari.",
  ConfiguraZonasComunes:
    "Configura les zones comunes (si tens contractat el Pack de Zones Comunes).",
  HabilitaEmleadosFichar:
    "Habilita els empleats perquè puguin fitxar (si tens contractat el Pack Control Horari).",
  MarcarEnviarAviso: "Marcar per enviar avís",
  MarcarEnviarAvisoRegistroVecino: "Marcar per enviar avís o enviar codi registre veí",
  UsuariosActivosComunidades: " Usuaris Actius / Comunitats",
  UsuariosComunidad: "Usuaris Comunitat",
  ComunidadesActivasUsuarios: "Comunitats Actives / Usuaris",
  ComusUsuarios: "Comus Usuaris",
  IncidenciasEstados: "Incidències/Estats ",
  EstadoNumero: "Estat/Nombre",
  IncidenciasAverias: "Incidències Avaries/Tipus",
  IncidenciasTodas: "Incidències totes ",
  CodigoDespacho: "Codi de despatx",
  Refrescar: "Refrescar",
  Volver: "Tornar",
  AltaDesde: "Alta des de",
  AltaHasta: "Alta fins",
  NOTIFICACIONES: "NOTIFICACIONS",
  CUADRODEMANDOS: "QUADRE DE COMANDAMENTS",
  TODAS: "TOTES",
  ACTIVAS: "ACTIVES",
  TODOS: "TOTS",
  ACTIVOS: "ACTIUS",
  ABIERTA: "OBERTA",
  ENCURSO: "EN CURS",
  CANCELADA: "CANCEL·LADA",
  CERRADA: "TANCADA",
  Desde: "Des de",
  Hasta: "Fins",
  CerrarSesion: "Tancar sessió",
  ErrorDemasiadosIntentos: "Massa intents, si us plau espereu una estona abans de repetir l'acció.",
  ErrorCamposRequeridos:
    "Els camps ressaltats en vermell són obligatoris. Per desar els canvis han d'estar farcits.",
  ValorAntiguo: "Valor anterior",
  ValorActual: "Valor actual",
  Estado: "Estat",
  Tipo: "Tipus",
  Actividad: "Activitat",
  Proveedor: "Proveïdor",
  NombreRegistrador: "Registrador",
  Texto_publicar: "Descripció",
  Email: "Email",
  Email2: "Email 2",
  Telefono1: "Telèfon",
  Telefono2: "Telèfon 2",
  Notas: "Notes internes",
  DerechoDeReservas: "Dret de Reserves",
  RazonSocial: "Raó social",
  Correo: "Correu",
  PersonaDeContacto: "Persona de contacte",
  DetalleDespacho: "Dades del despatx",
  ResultadoPublicadoPorPorciento: "Resultat publicat per %",
  ErrorNoArchivoBorrar: "Error, el fitxer a suprimir no ha arribat",
  PUBLICADA: "PUBLICAT",
  NOPUBLICADA: "NO PUBLICAT",
  SINOABS: "SÍ/NO/ABS",
  OPCIONES: "OPCIONS",
  NuevaOpcionDeVotacion: "Nova opció de votació",
  ElFicheroExisteNoModificar: "El fitxer ${var1} ja existeix i no es pot modificar",
  EnviarCambiosRealizados: "S'enviaran els canvis realitzats a l'usuari/s i adminsitrador",
  CambiarEstadoCerradaNoModificar:
    "Vols canviar l'estat a TANCADA?, si el canvies ja no podràs modificar la incidència",
  IncidenciaActualizadaCorrectamente: "La incidència s'ha actualitzat correctament",
  IncidenciaCerradaCambioAdministrador:
    "La incidència està tancada, si vols fer algun canvi posa't en contacte amb l'administrador, gràcies",
  IncidenciaCanceladaCambioAdministrador:
    "La incidència està cancel·lada, si vols fer algun canvi posa't en contacte amb l'administrador, gràcies",
  BorrarEstadoAbierta: "Per poder esborrar l'estat de la incidència heu d'estar OBERTA",
  ArrastraFicheroCargarloVentana:
    "Arrossega el fitxer o fes clic aquí per carregar-lo des de la finestra",
  CargarFicheroAdjuntarFoto: "Fes clic aquí per carregar un fitxer o adjuntar una foto",
  AnadeComentariosEnvialo: "Afegeix comentaris i envia'ls",
  TextoAnadir: "Text a afegir",
  NombreZona: "Nom zona",
  DescripcionIncidencia: "Descripció de la Incidència",
  UltimaModificacion: "Última Modificació",
  GremioResponsable: "Gremi Responsable",
  ImagenAsociada: "Imatge associada: ",
  Gremio: "Gremi: ",
  Incidencia: "Incidència: ",
  CentrosTrabajo: "Centres de treball",
  DocumentacionGeneral: "Documentació general",
  TodosCorreos: "Tots els correus",
  RecibirIncidencias: "Rebre incidències",
  RecibirSiniestros: "Rebre sinistres",
  RecibirModificacionClientes: "Rebre Modificació de Clients",
  RecibirConsultaAdmin: "Rebre Consulta Administrador",
  RecibirConsultaSeguros: "Rebre Consulta Assegurances",
  CategoriaUsuarioAdministrador: "Categoria Usuari-Administrador",
  Rol: "Rol",
  Fichar: "Fitxa",
  ResetearContrasena: "Resetear Contrasenya",
  VerificarEmail: "Verificar correu",
  Eliminar: "Eliminar",
  DetallesUsuario: "Detalls Usuari",
  FechaUltimoAcceso: "Data Últim Accés",
  FechaCreacion: "Data Creació",
  AnadirRegistro: "Afegir registre",
  HorasEntrada: "Hores Entrada",
  HorasSalida: "Hores Sortida",
  Latitud: "Latitud",
  Longitud: "Longitud",
  Distancia: "Distància",
  VotacionaBorradaCorrectamente: "Votació esborrada correctament",
  AnadirCentroDeTrabajo: "Afegir Centre de treball",
  CodigoAdministrador: "Codi d'administrador",
  DatosCentroDeTrabajo: "Dades Centre de treball",
  CentrosTrabajoDespacho: "Centres de treball del despatx",
  UsuariosDespacho: "Usuaris del despatx",
  CentroTrabajoCerradoCorrectamente: "Centre de treball esborrat correctament",
  tip_BorrarCentroTrabajo: "Pulsa per esborrar el centre de treball",
  centroDeTrabajoBorrado: "Centre de treball esborrat",
  CuerpoBorrarCentroTrabajo: "Per esborrar el centre de treball prem acceptar.",
  TituloBorrarCentroTrabajo: "Confirmar esborrat del centre de treball",
  Administrador: "Administrador",
  Oficial: "Oficial",
  Auxiliar: "Auxiliar",
};

export const tableLocation_cat = {
  pagination: {
    labelRowsSelect: "Files per pàgina",
    labelDisplayedRows: "{from}-{to} de {count}",
    firstAriaLabel: "Primera pàgina",
    firstTooltip: "Primera pàgina",
    previousAriaLabel: "Pàgina anterior",
    previousTooltip: "Pàgina anterior",
    nextAriaLabel: "Següent pàgina",
    nextTooltip: "Següent pàgina",
    lastAriaLabel: "Última pàgina",
    lastTooltip: "Última pàgina",
  },
  toolbar: {
    searchTooltip: "Cercar",
    searchPlaceholder: "Cercar",
    nRowsSelected: "{0} fila(es) seleccionada(es)",
  },
  header: {
    actions: "Accions",
  },
  body: {
    emptyDataSourceMessage: "No hi ha dades disponibles",
    filterRow: {
      filterTooltip: "Filtrar",
    },
    editRow: {
      saveTooltip: "Guardar",
      cancelTooltip: "Cancel·lar",
      deleteText: "Estàs segur de voler suprimir aquesta fila?",
    },
    addTooltip: "Afegir fila",
    deleteTooltip: "Suprimir fila",
  },
  edit: {
    saveTooltip: "Guardar",
    cancelTooltip: "Cancel·lar",
    deleteText: "Estàs segur de voler suprimir aquesta fila?",
  },
  group: {
    deleteTooltip: "Suprimir grup",
    editTooltip: "Editar grup",
    addTooltip: "Afegir grup",
  },
};
