import React from "react";
import { SiHtml5, SiJavascript, SiCss3, SiMarkdown } from "react-icons/si";
import { FaFileExcel, FaFileWord, FaFilePdf, FaFileImage } from "react-icons/fa";
import { AiFillFile } from "react-icons/ai";
import { FiFolder } from "react-icons/fi";

export const obtenerIconoPorExtension = (extension, modoEmulador = false) => {
  const colorEmulador = "#2a4c94";

  if (extension !== "") {
    switch (extension) {
      case "json":
        return <SiHtml5 color={colorEmulador} />;
      case "csv":
        return <FaFileExcel color={modoEmulador === false ? "green" : colorEmulador} />;
      case "html":
        return <SiHtml5 color={colorEmulador} />;
      case "js":
        return <SiJavascript color={colorEmulador} />;
      case "css":
        return <SiCss3 color={colorEmulador} />;
      case "md":
        return <SiMarkdown color={colorEmulador} />;
      case "txt":
      case "doc":
      case "docx":
        return <FaFileWord color={modoEmulador === false ? "blue" : colorEmulador} />;
      case "xls":
      case "xlsx":
        return <FaFileExcel color={colorEmulador} />;
      case "pdf":
        return <FaFilePdf color={modoEmulador === false ? "red" : colorEmulador} />;
      case "jpg":
      case "jpeg":
      case "png":
        return <FaFileImage color={modoEmulador === false ? "orange" : colorEmulador} />;
      case "mp3":
      case "wav":
      case "mp4":
      case "avi":
      case "zip":
      case "rar":
        return <AiFillFile color={colorEmulador} />;
      default:
        return <AiFillFile color={colorEmulador} />;
    }
  } else {
    return <FiFolder color={colorEmulador} />;
  }
};

export default obtenerIconoPorExtension;
