import {lenguaje} from '../../../lenguajes/index'

import { createSlice } from '@reduxjs/toolkit';

const initialState =
{
    diccionario:lenguaje.ESP.diccionario,
    lenguaje:lenguaje.ESP.lenguaje,
    traduccionTabla:lenguaje.ESP.traduccionTabla,
};




export const LenguajeReducerSlice = createSlice({
  name: 'LenguajeReducerSlice',
  initialState:{...initialState},
  reducers: {
    cambiarIdioma: (state, action) => {
      state.diccionario= action.payload.diccionario;
      state.lenguaje= action.payload.lenguaje;
      state.traduccionTabla= action.payload.traduccionTabla;
   
    },

  }
});

// Action creators are generated for each case reducer function
export const {cambiarIdioma} = LenguajeReducerSlice.actions

export default LenguajeReducerSlice.reducer


