import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { authRoles } from "../../auth/authRoles";
import AdminAnadirCentroDeTrabajo from "./AdminAnadirCentroDeTrabajo";
import { element } from "prop-types";

const Despacho = Loadable(lazy(() => import("./adminDesk")));
const DespachoDetalle = Loadable(lazy(() => import("./AdminDetalle")));
const DespachoCentrosDeTrabajo = Loadable(lazy(() => import("./AdminCentrosDeTrabajo")));
const AnadirDespachoCentroDeTrabajo = Loadable(lazy(() => import("./AdminAnadirCentroDeTrabajo")));
const DespachoDetalleCentroDeTrabajo = Loadable(
  lazy(() => import("./AdminDetalleCentrosDeTrabajo"))
);
const AdminAnadirEmpleado = Loadable(lazy(() => import("./AdminAnadirEmpleado")));

const despachoRoutes = [
  { path: "/adminDesk", element: <Despacho /> },
  { path: "/detalle_despacho/:Identificador", element: <DespachoDetalle /> },
  { path: "/centros_de_trabajo", element: <DespachoCentrosDeTrabajo /> },
  { path: "/centros_de_trabajo/:Identificador", element: <DespachoCentrosDeTrabajo /> },
  { path: "/anadir_centro_de_trabajo", element: <AnadirDespachoCentroDeTrabajo /> },
  { path: "/anadir_centro_de_trabajo/:Identificador", element: <AnadirDespachoCentroDeTrabajo /> },
  { path: "/detalle_centro_trabajo/:Identificador", element: <DespachoDetalleCentroDeTrabajo /> },
  { path: "/detalle_centro_trabajo/:Identificador/:IdComu", element: <DespachoDetalleCentroDeTrabajo /> },
  { path: "/anadir_empleado", element: <AdminAnadirEmpleado /> },
];

export default despachoRoutes;
