import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { authRoles } from "../../auth/authRoles";

const Home = Loadable(lazy(() => import("./Home")));
const Gigante = Loadable(lazy(() => import("./Gigante")));

const homeRoutes = [
  { path: "/home", element: <Home /> },
  { path: "/gigante", element: <Gigante /> },
];

export default homeRoutes;
