import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { authRoles } from "../../auth/authRoles";
import { Tutorial } from "./Tutorial";

const Home = Loadable(lazy(() => import("./Tutorial")));

const TutorialRoutes = [
  { path: "/tutorial", element: <Tutorial /> },
  { path: "/tutorial/:Identificador", element: <Tutorial /> },
];

export default TutorialRoutes;
