import { useEffect, useState, useRef } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { get, onValue } from "firebase/database";
import * as ROUTES from "../../constants/routes";
import useAuth from "app/hooks/useAuth";
import { useStore } from "react-redux";
import { useFirebase } from "../../../app/firebase/context";
import React from "react";
import "font-awesome/css/font-awesome.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useParams } from "react-router-dom";
import { initialiseJitsi } from "app/components/Jitsi/FuncionesJistsi";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { traerUsuariosComunidad } from "app/views/incidencias/FuncionesIncidencias";
import { toast } from "react-toastify";

const VideoJitsi = () => {
  const [loading, setLoading] = useState(true);
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  let [juntasLista, setJuntasLista] = useState([]);
  let [conexionAbierta, setConexionAbierta] = useState(false);

  const stateInicial = {
    mostrarAyuda: false,
    entroPrimeravez: true,
    carpetas: [],
    Pdfs: [],
    jitsi: "",
    dentro: false,
    despacho: [],
    openSesion: false,
    votaciones: [],
    usuariosSesion: [],
    uid: "",
    fecha_publicacion: "",
    añadir: true,
    incis: [],
    identIncial: "",
    comus: [],
    open: false,
    comunidad: "",
    tipo: "guardar",
    votacion: "",
    mostrarTabla: false,
    jitsiContainerId: "jitsi-container-id",
    email: "",
    password: "",
    error: null,
    error2: null,
    modificar: true,
    sis: "",
    nos: "",
    abstenciones: "",
    total: "",
    listaComunidades: [],
    ComunidadesAsig: [],
    ComunidadesNoAsig: [],
    tenemosLista: false,
    label_publicar: "SIN PUBLICAR",
    label_abrir: "CERRADA",
    usuariosComu: [],
    usuarios: [],
    snack: false,
    snack2: false,
    vertical: "top",
    horizontal: "center",
    publicar: false,
    abrir: false,
  };

  let [state, setState] = useState(stateInicial);
  const { user } = useAuth();
  let [tituloGeneral, setTituloGeneral] = useState("");
  let [nombreComu, setNombreComu] = useState("");
  let [numRtdos, setNumRtdos] = useState([]);
  let [pageSelected, setPageSelected] = useState();
  const [expanded, setExpanded] = useState(true);
  const firebase = useFirebase();
  const datosIniciales = useRef([]);
  const [refrescar, setRefrescar] = useState(false);
  const { IdComu, IdJunta, NumeroJunta } = useParams();
  let [delJunta, setDelJunta] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const routeAnadir = ROUTES.ANADIR_JUNTA;
  const [currentSizePerPage, setCurrentSizePerPage] = useState(10);

  const routeDetalle = ROUTES.DETALLE_JUNTA;

  const getComunidadOnValue = (idComunidad) => {
    try {
      const dataRef = firebase.getReferencia(`Comunidad/${idComunidad}`, null);
      const unsubscribe = onValue(dataRef, (snapshot) => {
        const comunidad = snapshot.val();
        let estadoAbierta = false;
        const nombreJunt = comunidad.Nombre;
        if (IdJunta !== "null") {
          estadoAbierta =
            comunidad.Juntas[IdJunta] && comunidad.Juntas[IdJunta].Estado
              ? comunidad.Juntas[IdJunta].Estado
              : false;
        } else {
          estadoAbierta =
            comunidad.Junta && comunidad.Junta.Estado ? comunidad.Junta.Estado : false;
        }
        if (estadoAbierta === false) {
          toast.error(
            diccionario.JuntaCerradaPestanaCerrandose,
            getCustomToastOptions(toast, "error", 9000)
          );
          setTimeout(() => {
            window.close();
          }, 10000);
        }

        setNombreComu(nombreJunt);
        setState({ ...state, comunidad: comunidad });
        setTituloGeneral(nombreJunt);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    } catch (error) {
      console.error("Error al cargar los datos de comunidad", error);
    }
  };

  useEffect(async () => {
    setLoading(true);
    const usuariosComunidad = await traerUsuariosComunidad(firebase, IdComu);

    getComunidadOnValue(IdComu);

    const nombreCompleto = user.datosUsuarioBD
      ? user.datosUsuarioBD.Nombre + " " + user.datosUsuarioBD.Apellido
      : user.displayName;

    setState({ ...state, usuariosComu: usuariosComunidad });

    initialiseJitsi(
      firebase,
      user.despacho,
      IdComu,
      state,
      setState,
      "",
      nombreCompleto,
      IdJunta,
      usuariosComunidad,
      NumeroJunta
    );

    setLoading(false);
  }, [refrescar]);

  const formatFecha = (fecha) => {
    let retFecha = "";
    if (fecha) {
      fecha = new Date(fecha).toLocaleDateString("en-GB").replace(/\//g, "-");
    }
    return fecha;
  };

  if (loading) {
    return (
      <div>
        <ClipLoader
          size={100}
          color={"#123abc"}
          loading={loading}
          cssOverride={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          speedMultiplier={1}
        />
      </div>
    );
  } else {
    return <div id="jitsi-container-id" style={{ height: "100%", width: "100%" }} />;
  }
};

export default VideoJitsi;
