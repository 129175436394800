import { get } from "firebase/database";
import React from "react";

const dateStringToTimestamp = (dateString) => {
  try {
    let timestamp = "";
    if (dateString) {
      // Split the date string into parts
      const [datePart, timePart] = dateString.split(" ");
      const [day, month, year] = datePart.split("-");
      const [hours, minutes] = timePart.split(":");

      // Create a Date object with the parsed parts
      const dateObject = new Date(year, month - 1, day, hours, minutes); // Month is 0-based

      // Get the timestamp in milliseconds
      timestamp = dateObject.getTime();
    }
    return timestamp;
  } catch (error) {
    console.log("ERROR:" + error);
  }
};

export const cargarVotaciones = async (IdComu = null, firebase, diccionario, IdJunta = null) => {
  try {
    const snapshotJuntas = await get(firebase.getReferencia(`Comunidad/${IdComu}/Juntas`, null));
    let nombresJuntas = {};
    snapshotJuntas.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      let childData = childSnapshot.val();
      nombresJuntas[childKey] = childData.Titulo;
    });

    const snapshot = await get(firebase.getReferencia("Comunidad/" + IdComu + "/Votaciones", null));
    let datos = [];
    const data = snapshot.val();
    if (data) {
      let votacionesList = [];
      Object.keys(data).map((key) => {
        if (!IdJunta || (data[key].Junta && data[key].Junta === IdJunta)) {
          votacionesList.push({
            ...data[key],
            idInterno: key,
            fechaTimeStamp: dateStringToTimestamp(data[key].Fecha_Modificacion),
            fechaTimeStampPublicacion: dateStringToTimestamp(data[key].Fecha_Publicacion),
            Codigo: data[key].Codigo ? data[key].Codigo : " ",
            Tipo:
              data[key].Tipo == "principal" || data[key].Tipo == "SI/NO/ABS"
                ? diccionario.SINOABS
                : diccionario.OPCIONES,
            TituloJunta: data[key].Junta ? nombresJuntas[data[key].Junta] : "SinJuntaAsociada",
          });
        }
      });

      return votacionesList;
    }
    return [];
  } catch (error) {
    console.error("Error al cargar las incidencias", error);
  }
};

export const traerActividades = async (firebase) => {
  const snapshot = await get(firebase.getReferencia("Actividad", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    datos.push({
      label: childData.Nombre,
      value: childData.Nombre,
      idActividad: childKey,
    });
  });

  return datos;
};

export const traerProveedores = async (firebase) => {
  const snapshot = await get(firebase.getReferencia("Gremio", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    datos.push({
      label: childData.Nombre,
      value: childData.Nombre,
      idProveedor: childKey,
      email: childData.email,
      email2: childData.email2,
      Telefono1: childData.Telefono1,
      Telefono2: childData.Telefono2,
    });
  });

  return datos;
};

export const traerUsuariosTablaGeneral = async (firebase, idComunidad = null) => {
  try {
    const snapshot = await get(firebase.getReferencia("Usuarios", null));
    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;
      if (!childData.Borrado) {
        const nombreProp =
          childData.Roles && childData.Roles.length > 0
            ? childData.Nombre + " " + childData.Apellido + " " + " | " + childData.Roles.join(",")
            : childData.Nombre + " " + childData.Apellido;

        if (idComunidad && childData.Comunidad === idComunidad) {
          datos.push({
            label: nombreProp,
            value: childKey,
          });
        } else if (idComunidad && childData.Comunidad !== idComunidad) {
          // No hago nada
        } else {
          datos.push({
            label: nombreProp,
            value: childKey,
            nombre: childData.Nombre,
            apellido: childData.Apellido,
            idInterno: childData.idInterno,
          });
        }
      }
    });

    return datos;
  } catch (error) {
    console.error("Error al cargar propietarios", error);
  }
};

export const traerUsuarioInstancia = async (firebase, idComunidad = null) => {
  try {
    const snapshot = await get(
      firebase.getReferencia("Comunidad/" + idComunidad + "/Instancias", null)
    );
    const data = snapshot.val();
    if (data) {
      return data;
    }

    return false;
  } catch (error) {
    console.error("Error al cargar propietarios", error);
  }
};

export const traerUsuario = async (firebase, idusuario) => {
  try {
    const snapshot = await get(firebase.getReferencia("Usuarios/" + idusuario, null));
    const data = snapshot.val();
    if (data) {
      return data;
    }
    return false;
  } catch (error) {
    console.error("Error al cargar propietarios", error);
  }
};

export const traerTiposMayoria = (diccionario) => {
  const tiposMayoria = [
    { label: diccionario.Escrutinio2, value: "mayoria_simple" },
    { label: diccionario.Escrutinio1, value: "un_tercio" },
    { label: diccionario.Escrutinio3, value: "mayoria_reforzada" },
    /* { label: diccionario.Escrutinio4, value: "tres_quintos" }, */
    { label: diccionario.Escrutinio5, value: "unanimidad" },
  ];
  return tiposMayoria;
};

export const traerTiposResultado = (diccionario) => {
  const tiposResultado = [
    { label: diccionario.Aprobada, value: "aprobada" },
    { label: diccionario.NoAprobada, value: "no_aprobada" },
  ];
  return tiposResultado;
};

export const calcularEstadisticasVotacion = (
  todosVotos,
  datosVotacion,
  usuariosDerechoVoto,
  totalVotosPosibles,
  totalEnterosPosibles
) => {
  //console.log("Los votos", todosVotos, datosVotacion, listaUsuarios);
  let estadisticas = null;
  let resultados = {};
  // Elimino los borrados del conteo
  const votos = todosVotos.filter((voto) => voto.Borrada !== true);
  const votosTotales = votos.length;

  if (datosVotacion.Tipo === "SI/NO/ABS") {
    const cuantosSI = votos.filter((voto) => voto.SI === "X").length;
    const cuantosNO = votos.filter((voto) => voto.NO === "X").length;
    const cuantosABS = votos.filter((voto) => voto.ABS === "X").length;

    resultados = { SI: cuantosSI, NO: cuantosNO, ABS: cuantosABS };
  } else if (datosVotacion.Tipo === "OPCIONES") {
    datosVotacion.Criterios &&
      datosVotacion.Criterios.map((opcion) => {
        resultados[opcion] = votos.filter((voto) => voto[opcion] === "X").length;
      });
  }

  const { biggestProperty, percentages, winnerPercentage } = findBiggestAndPercentages(resultados);

  estadisticas = {
    Conteo: resultados,
    Ganador: biggestProperty,
    PorcentajeGanador: winnerPercentage,
    Porcentajes: percentages,
  };

  const votosASumarAMayoria = votosTotales > 0 ? totalVotosPosibles - votosTotales : 0;

  // Actualizo la lista de resultados según las reglas de la mayoría reforzada
  let resultadosMayoriaReforzada = { ...resultados };
  resultadosMayoriaReforzada[biggestProperty] = resultados[biggestProperty] + votosASumarAMayoria;

  const { biggestProperty2, percentages2, winnerPercentage2 } = findBiggestAndPercentages(
    resultadosMayoriaReforzada,
    true
  );
  const estadisticasMayoriaReforzada = {
    Conteo: resultadosMayoriaReforzada,
    Ganador: biggestProperty2,
    PorcentajeGanador: winnerPercentage2,
    Porcentajes: percentages2,
  };

  return { estadisticas, estadisticasMayoriaReforzada };
};
export const getPropietariosDerechosYEnteros = async (firebase, listaUsuarios, IdComu) => {
  let propietariosDerecho = [];
  const snapshotInstancias = await get(firebase.getReferencia(`Comunidad/${IdComu}/Instancias`));
  const instancias = snapshotInstancias.val();

  listaUsuarios.map((usuario) => {
    let enterosTotales = 0;
    let numeroPisos = 0;
    let otrosUsuariosMismaVivienda = [];
    // Busco otras viviendas iguales para ver si hay inquilino o similar
    usuario.direcciones.map((direcc) => {
      const { Calle, Portal, Escalera, Piso, Letra, Tipo, Usuario, Enteros } = direcc;

      // Convertir a número
      const enterosConvertidos = Number(Enteros);

      // Recupero misma vivienda pero distinto usuario
      otrosUsuariosMismaVivienda =
        instancias &&
        Array.isArray(instancias) &&
        instancias.filter(
          (instancia) =>
            instancia.Calle === Calle &&
            instancia.Portal === Portal &&
            instancia.Escalera === Escalera &&
            instancia.Piso === Piso &&
            instancia.Letra === Letra &&
            instancia.Usuario !== Usuario
        );

      switch (Tipo) {
        case "propietario":
        case "empresa":
          if (otrosUsuariosMismaVivienda.length > 0) {
            if (otrosUsuariosMismaVivienda[0].Tipo === "inquilino") {
              // Verificar si el resultado es un número válido
              if (!isNaN(enterosConvertidos)) {
                enterosTotales += enterosConvertidos;
                numeroPisos++;
              }
            }
          } else {
            // Verificar si el resultado es un número válido
            if (!isNaN(enterosConvertidos)) {
              enterosTotales += enterosConvertidos;
              numeroPisos++;
            }
          }

          break;
        case "representantelegal":
          // Verificar si el resultado es un número válido
          if (!isNaN(enterosConvertidos)) {
            enterosTotales += enterosConvertidos;
            numeroPisos++;
          }
          break;
      }
    });

    // Si tiene vivienda de la que es propietario y no hay otra instancia igual con un representante legal entonces lo añado
    if (enterosTotales > 0) {
      propietariosDerecho.push({
        id: usuario.idInterno,
        nombre: usuario.Nombre + " " + usuario.Apellido,
        pisos: numeroPisos,
        enteros: enterosTotales,
      });
    }
  });

  return propietariosDerecho;
};
// Function to find the biggest property, its percentage, and the percentages of all properties
const findBiggestAndPercentages = (obj, mayoriaReforzada = false) => {
  let biggestProperty = null;
  let maxValue = -Infinity;
  let totalSum = 0;

  // Calculate the total sum of the values
  for (const value of Object.values(obj)) {
    totalSum += value;
  }

  // Find the biggest property, calculate its percentage and the percentages of all properties
  const percentages = {};
  let winnerPercentage = 0;

  for (const [key, value] of Object.entries(obj)) {
    const percentage = totalSum > 0 ? (value / totalSum) * 100 : 0;
    percentages[key] = parseFloat(percentage.toFixed(2)); // Round to 2 decimal places

    if (value > maxValue) {
      maxValue = value;
      biggestProperty = key;
      winnerPercentage = parseFloat(percentage.toFixed(2)); // Round to 2 decimal places
    }
  }

  if (!mayoriaReforzada) {
    return { biggestProperty, percentages, winnerPercentage };
  } else {
    const biggestProperty2 = biggestProperty;
    const percentages2 = percentages;
    const winnerPercentage2 = winnerPercentage;
    return { biggestProperty2, percentages2, winnerPercentage2 };
  }
};
