// Step2.jsx
import React, { useState, useEffect } from 'react';
import { getDesprecio, CurrencyFormatter } from 'app/components/helpers/Views';
import { useStore } from "react-redux";

const circleBadgeStyle = {
  display: 'inline-block',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: '#172983', // Puedes cambiar el color según tus preferencias
  color: '#fff',
  textAlign: 'center',
  lineHeight: '30px',
};

export const Step2 = ({ onPrev, onNext, setPlan, plan , planHistorico }) => {
  const [paymentOption, setPaymentOption] = useState('');
  const [precioSelected, setPrecioSelected] = useState(null);
  const [precios, setPrecios] = useState({ mensual: '', anual: '' });
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  
  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.id);
    const tabPrecios = plan.tablaPrecios;
    let selectedPrecio = tabPrecios.filter((elem) => elem.idPack === plan.plan);
    const selectedPrecio2 = selectedPrecio[0].precios.filter(
      (elem) => elem.tipo === event.target.id
    );
    const desPrecio = getDesprecio(plan.vecinos);
    const selectedPrecio3 = selectedPrecio2[0].valores.filter((elem) => elem.vecinos === desPrecio);
    setPrecioSelected(selectedPrecio3[0].precio);
    setPlan({ ...plan, formapago: event.target.id, precioSelected: selectedPrecio3[0].precio });
  };
  const obtenerPrecios = () => {
    let planSeleccionado = plan.tablaPrecios.filter((elem) => elem.idPack === plan.plan);
    let precioMensual = null;
    let precioAnual = null;
    planSeleccionado[0].precios.map((elem) => {
      let getPreciosvecions = getDesprecio(plan.vecinos);
      let precioCurrent = elem.valores.filter((prec) => prec.vecinos === getPreciosvecions);
      if (elem.tipo === 'mensual') {
        precioMensual = precioCurrent[0].precio;
      } else {
        precioAnual = precioCurrent[0].precio;
      }
    });

    setPrecios({ mensual: precioMensual, anual: precioAnual });
  };
  
  
  useEffect(() => {
    if(planHistorico.formapago != '' &&planHistorico.formapago == 'mensual' && planHistorico.plan == plan.plan){
      setPaymentOption('mensual')
      setPlan({
        ...plan,
        formapago:'mensual',
      })
    } else if (planHistorico.formapago != '' &&planHistorico.formapago == 'anual'&& planHistorico.plan == plan.plan) {
      setPaymentOption('anual')
      setPlan({
        ...plan,
        formapago:'anual',
      })
    }
    obtenerPrecios();
  }, []);
  return (
    <div className="alta365">
      <div className="container mt-4">
        <div className="d-flex align-items-center">
          <div style={circleBadgeStyle} className="mr-2">
            2
          </div>

          <h5 style={{ marginLeft: '15px', marginBottom: '0px' }}>Selecciona la forma de pago:</h5>
        </div>
      </div>
      <hr></hr>

      <div className="alta-formapago">
        <div className="container mt-4">
          <div className="form-check">
            <input
            checked={paymentOption === 'mensual'} 
              type="radio"
              className="form-check-input"
              id="mensual"
              name="paymentOption"
              onChange={handlePaymentOptionChange}
            />
            <label className="form-check-label" htmlFor="monthlyPayment">
              Pago Mensual {CurrencyFormatter(precios.mensual)}
            </label>
          </div>

          <div className="form-check">
            <input
       checked={paymentOption === 'anual'} 
              type="radio"
              className="form-check-input"
              id="anual"
              name="paymentOption"
              onChange={handlePaymentOptionChange}
            />
            <label className="form-check-label" htmlFor="annualPayment">
              Pago Anual {CurrencyFormatter(precios.anual)}
            </label>
          </div>
          <br></br>
          <div className="precio-total">
            <p>
              Has seleccionado el plan <strong>{plan.plan}</strong>{' '}
              {paymentOption && (
                <>
                  con la forma de pago <strong>{plan.formapago}</strong>. El precio total es de:{' '}
                  {CurrencyFormatter(precioSelected)} al{' '}
                  <strong>{plan.formapago === 'mensual' ? 'mes' : 'año'}</strong>
                </>
              )}
            </p>
          </div>
        </div>
        <i><h5>* {diccionario.preciosSinIva}</h5></i>
      </div>
      <div className="alta365-planes-botones">
        <button className="btn btn-outline-secondary mr-2" onClick={onPrev}>
          Volver
        </button>
        <button className="btn btn-outline-primary" onClick={onNext} disabled={!paymentOption}>
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Step2;
