import React from "react";
import { ListItem, List, Collapse } from "@material-ui/core";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import * as ROUTES from "app/constants/routes";

const activaOpcion = (cual) => {
  const borrar = document.getElementsByClassName("active");
  if (borrar && borrar.length > 0) {
    borrar[0].classList.remove("active");
  }
  cual.add("active");
};

const MatxVerticalNav = ({ items }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState([]);
  const location = useLocation();
  const currentPath = location.pathname;
  const pathSegments = currentPath.split("/");
  const firstSegment = pathSegments[1];
  const secondSegment = pathSegments[2];

  const handleClick = (clickedIndex) => {
    if (open.includes(clickedIndex)) {
      const openCopy = open.filter((element) => {
        return element !== clickedIndex;
      });
      setOpen(openCopy);
    } else {
      const openCopy = [...open];
      openCopy.push(clickedIndex);
      setOpen(openCopy);
    }
  };

  const renderLevels = (data) => {
    return data.map((item, index) => {
      if (item.children) {
        return (
          <>
            <ListItem
              key={index}
              component="li"
              className="nav-item"
              button
              onClick={(e) => {
                handleClick(index);
                activaOpcion(e.currentTarget.children[0].classList);
              }}
            >
              <nav className="nav-link has-children">
                <i
                  aria-hidden="true"
                  title={item.name}
                  className={!open.includes(index) ? "icon-add" : "icon-menos"}
                ></i>
                <span>{item.name}</span>
              </nav>
            </ListItem>
            <Collapse in={open.includes(index)} timeout="auto" unmountOnExit>
              {renderLevels(item.children)}
            </Collapse>
          </>
        );
      } else {
        let classNameActual = "";

        if (
          item.name === "INICIO" ||
          "/" + firstSegment === item.path ||
          (item.otherRoutes &&
            item.otherRoutes.includes("/" + firstSegment) &&
            !("/" + firstSegment === ROUTES.LISTA_INCIDENCIAS && pathSegments[2]))
        ) {
          if (
            !("/" + firstSegment === ROUTES.LISTA_INCIDENCIAS && pathSegments[2]) &&
            !(
              item.idMenu === "COMUNIDADES" &&
              "/" + firstSegment === ROUTES.DOCUMENTOS &&
              pathSegments[2] === "documentaciongeneral"
            ) &&
            !(
              item.idMenu === "DESPACHO" &&
              "/" + firstSegment === ROUTES.DOCUMENTOS &&
              pathSegments[2] !== "documentaciongeneral"
            )
          ) {
            classNameActual = "nav-link active";
          } else {
            classNameActual = "nav-link";
          }
        } else {
          classNameActual = "nav-link";
        }

        // Excepciones
        if (
          item.path === ROUTES.LISTA_COMUNIDADES &&
          "/" + firstSegment === ROUTES.LISTA_INCIDENCIAS &&
          pathSegments[2]
        ) {
          classNameActual = "nav-link active";
        }
/*
        ROUTES.CENTROS_DE_TRABAJO,
        ROUTES.ANADIR_CENTRO_DE_TRABAJO,
        ROUTES.DETALLE_CENTRO_TRABAJO,
*/

        if (
          item.path == ROUTES.DESPACHO && "/" + firstSegment === ROUTES.CENTROS_DE_TRABAJO && pathSegments[2]
        ) {
          classNameActual = "nav-link";
        }

        if (
          item.path == ROUTES.LISTA_COMUNIDADES && "/" + firstSegment === ROUTES.CENTROS_DE_TRABAJO && !pathSegments[2]
        ) {
          classNameActual = "nav-link";
        }

        if (
          item.path == ROUTES.DESPACHO && "/" + firstSegment === ROUTES.ANADIR_CENTRO_DE_TRABAJO && pathSegments[2]
        ) {
          classNameActual = "nav-link";
        }

        if (
          item.path == ROUTES.DESPACHO && "/" + firstSegment === ROUTES.DETALLE_CENTRO_TRABAJO && pathSegments[2] && pathSegments[3]
        ) {
          classNameActual = "nav-link";
        }

        if (
          item.path == ROUTES.LISTA_COMUNIDADES && "/" + firstSegment === ROUTES.ANADIR_CENTRO_DE_TRABAJO && !pathSegments[2]
        ) {
          classNameActual = "nav-link";
        }

        if (
          item.path == ROUTES.LISTA_COMUNIDADES && "/" + firstSegment === ROUTES.DETALLE_CENTRO_TRABAJO && pathSegments[2] && !pathSegments[3]
        ) {
          classNameActual = "nav-link";
        }
        // SEGUIR POR AQUI PONIENDO LAS DOS EXCEPCIONES DEL REGISTRO HORARIO

        console.log("que horror",firstSegment,secondSegment,ROUTES.DESPACHO);
        if (
          item.path == ROUTES.LISTA_COMUNIDADES && secondSegment==="despacho"
        ) {
          classNameActual = "nav-link";
        }

        if (
          item.path == ROUTES.DESPACHO && secondSegment!=="despacho"
        ) {
          classNameActual = "nav-link";
        }

       

        
        return (
          <ListItem
            key={index}
            component="li"
            className="nav-item"
            button
            onClick={(e) => {
              activaOpcion(e.currentTarget.children[0].classList);
            }}
          >
            <nav
              onClick={(e) => {
                navigate(item.path);
              }}
              className={classNameActual}
            >
              <i aria-hidden="true" title={item.name} className={"icon-" + item.icon}></i>
              <span>{item.name}</span>
            </nav>
          </ListItem>
        );
      }
    });
  };

  return (
    <List component="ul" className="nav">
      {renderLevels(items)}
    </List>
  );
};

export default React.memo(MatxVerticalNav);
