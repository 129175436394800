import React from "react";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getDesprecio, CurrencyFormatter } from "app/components/helpers/Views";
import { toast } from "react-toastify";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useStore } from "react-redux";
import { useEffect, useState } from "react";

const circleBadgeStyle = {
  display: "inline-block",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "#172983", // Puedes cambiar el color según tus preferencias
  color: "#fff",
  textAlign: "center",
  lineHeight: "30px",
};

export const Step1 = ({ onNext, plan, planHistorico }) => {
  const planesPrecios = plan.tablaPrecios;
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  let [textoVecinos, setTextoVecinos] = useState("");

  const handleOpenPdf = () => {
    const fileUrl = "/assets/files/tabla_de_precios.pdf"; // Reemplaza con la ruta correcta de tu archivo
    const newWindow = window.open(fileUrl, "_blank");

    if (newWindow) {
      newWindow.focus();
    } else {
      toast.error(
        "Error al abrir la nueva ventana, asegurate de tener permisos para abrir vetanas emergentes.",
        getCustomToastOptions(toast, "error")
      );
    }
  };

  useEffect(() => {
    setTextoVecinos(
      diccionario.preciosPorVecino
        ? diccionario.preciosPorVecino.replace("${var1}", plan.vecinos)
        : plan.vecinos
    );
  }, []);

  return (
    <div className="alta365">
      <div className="container mt-4">
        <div className="d-flex align-items-center paso1">
          <div style={circleBadgeStyle} className="mr-2">
            1
          </div>

          <h5 style={{ marginLeft: "15px", flex: "1", marginBottom: "0px" }}>
            Selecciona el plan que más se ajuste a tus necesidades
          </h5>
          <div>
            <Button variant="outline-primary" onClick={handleOpenPdf}>
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: "5px" }} />
              Descargar precios
            </Button>
          </div>
        </div>
      </div>
      <hr></hr>

      <div className="alta365-planes">
        {planesPrecios &&
          planesPrecios.map((planPrecios, index) => {
            if (planHistorico.plan !== planPrecios.idPack) {
              return (
                <Card style={{ width: "20rem" }} key={index}>
                  <Card.Body>
                    <Card.Title>{planPrecios.nombre}</Card.Title>
                    <Card.Text>{planPrecios.descripcion}</Card.Text>
                    <Card.Text>
                      Precios:
                      {planPrecios.precios.map((tipo, index2) => {
                        const desPrecio = getDesprecio(plan.vecinos);
                        const selectedPrecio = tipo.valores.filter(
                          (elem) => elem.vecinos === desPrecio
                        );
                        return (
                          <div key={index + "_" + index2}>
                            {tipo.tipo} - Precio: {CurrencyFormatter(selectedPrecio[0].precio)}
                          </div>
                        );
                      })}
                    </Card.Text>
                    <Button variant="outline-primary" value={planPrecios.idPack} onClick={onNext}>
                      Contratar
                    </Button>
                  </Card.Body>
                </Card>
              );
            } else {
              return (
                <Card style={{ width: "20rem" }} key={index}>
                  <Card.Body style={{ background: "#00bdff33" }}>
                    <Card.Title>{planPrecios.nombre}</Card.Title>
                    <Card.Text>{planPrecios.descripcion}</Card.Text>
                    <Card.Text>
                      Precios:
                      {planPrecios.precios.map((tipo, index2) => {
                        const desPrecio = getDesprecio(plan.vecinos);
                        const selectedPrecio = tipo.valores.filter(
                          (elem) => elem.vecinos === desPrecio
                        );
                        return (
                          <div key={index + "_" + index2}>
                            {tipo.tipo} - Precio: {CurrencyFormatter(selectedPrecio[0].precio)}
                          </div>
                        );
                      })}
                    </Card.Text>
                    <Button variant="outline-primary" value={planPrecios.idPack} onClick={onNext}>
                      Modificar
                    </Button>{" "}
                    <i>
                      <strong>* Pack contratado actual</strong>
                    </i>
                  </Card.Body>
                </Card>
              );
            }
          })}
      </div>

      <br />
      <i>
        <h5>* {textoVecinos}</h5>
      </i>
      <i>
        <h5>* {diccionario.preciosSinIva}</h5>
      </i>
    </div>
  );
};

export default Step1;
