import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { authRoles } from "../../auth/authRoles";

//import Inci_gremios  from './inci_gremios';

const NotificacionBuscador = Loadable(lazy(() => import("./NotificacionBuscador")));
const Notificaciones = Loadable(lazy(() => import("./Notificaciones")));
const NotificacionDetalle = Loadable(lazy(() => import("./NotificacionDetalle")));
const AnadirNotificaciones = Loadable(lazy(() => import("./AnadirNotificacion")));

const NotificacionesRoutes = [
  { path: "/notificaciones", element: <Notificaciones /> },
  { path: "/detalle_notificaciones/:Identificador", element: <NotificacionDetalle /> },
  { path: "/detalle_notificaciones/:Identificador/:volver", element: <NotificacionDetalle /> },
  { path: "/addnotificaciones/:IdComu", element: <AnadirNotificaciones /> },
  { path: "/addnotificaciones", element: <AnadirNotificaciones /> },
  { path: "/notificaciones/buscador", element: <NotificacionBuscador /> },
  // { path: '/inci_gremios', element: <Inci_gremios /> },
];

export default NotificacionesRoutes;
