import { get } from "firebase/database";
import { traerComunidades } from "app/views/gremios/FuncionesGremios";

import { getFunctions, httpsCallable } from "firebase/functions";

function getSubJson(obj, key) {
  return obj[key] || null;
}

export const obtenerMetas = async (Identificador, firebase) => {
  const functions = getFunctions(firebase.app, process.env.REACT_APP_FIREBASE_ZONE);
  const obtenerMetadatosUsuario = httpsCallable(functions, "getOwnerMetadata");

  await obtenerMetadatosUsuario({
    id: Identificador,
    mode: "user",
  }).then((data) => {
    const usuarioData = data.data[Identificador];
    if (usuarioData) {
      return usuarioData;
    }
  });
};

export const loadAdminUsers = async (firebase, fichar = false, metadatos = false) => {
  try {
    const functions = getFunctions(firebase.app, process.env.REACT_APP_FIREBASE_ZONE);
    const obtenerMetadatosUsuario = httpsCallable(functions, "getOwnerMetadata");
    let snapshot = await get(firebase.getReferencia("Usuarios", null));
    let datos = [];
    const comunidadeslista = await traerComunidades(firebase);

    const snapFichajes = await get(firebase.getReferencia("Fichar"));

    const snapFlujosCorreo = await get(firebase.getReferencia("Flujocorreos"));
    const flujoCorreos = snapFlujosCorreo.val();

    const fichajes = snapFichajes.val();

    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;

      let childData = childSnapshot.val();
      childData.idInterno = childKey;

      if (fichar) {
        childData.fichajes = fichajes && getSubJson(fichajes, childKey);
      }
      // childData.metas = obtenerMetas(childKey, firebase, functions);
      if (
        childData &&
        childData["PerfilEmpleadoOficina"] &&
        childData["PerfilEmpleadoOficina"] !== ""
      ) {
        // Si no tiene la propiedad flujocorreos todo por defecto a que sí
        childData.Flujocorreos = flujoCorreos && flujoCorreos[childKey]?flujoCorreos[childKey]: {
          "AdminCon": true,
          "Cambios": true,
          "Consultas": true,
          "Incidencias": true,
          "Seguros": true,
          "Siniestros": true,
          "Todo": true
        };

        childData.Todo = childData.Flujocorreos && childData.Flujocorreos.Todo;
        childData.Cambios = childData.Flujocorreos && childData.Flujocorreos.Cambios;
        childData.Consultas = childData.Flujocorreos && childData.Flujocorreos.Consultas;
        childData.Incidencias = childData.Flujocorreos && childData.Flujocorreos.Incidencias;
        childData.Siniestros = childData.Flujocorreos && childData.Flujocorreos.Siniestros;


        const listaPreparada = [];
        for (let comunidadId in childData.Comunidades) {
          if (comunidadeslista.some((nombre) => nombre.value === comunidadId)) {
            // Busca el nombre de la comunidad en comunidadeslista y lo agrega a listaPreparada
            const nombreComunidad = comunidadeslista.find(
              (comunidad) => comunidad.value === comunidadId
            )?.label;
            if (nombreComunidad) {
              listaPreparada.push(nombreComunidad);
            }
          }
          if (comunidadId === "desk") {
            listaPreparada.push("Todas las comunidades");
          }
        }
        childData.Comunidadeslista = listaPreparada;
        datos.push(childData);
      }
    });

    datos = datos.map((usuario) => {
      const Flujocorreos = snapshot.val()[usuario.Flujocorreos];
      return { ...usuario, ...Flujocorreos };
    });
    if (metadatos) {
      for (const datetes of datos) {
        await obtenerMetadatosUsuario({
          id: datetes.idInterno,
          mode: "user",
        }).then((data) => {
          const usuarioData = data.data[datetes.idInterno];
          if (usuarioData.emailVerified) {
            datetes.AltaC365 = true;
          }
        });
      }
    }
    return datos;
  } catch (error) {
    console.error("Error al cargar usuarios administradores", error);
    throw error; // Puedes lanzar nuevamente el error para que se maneje en el nivel superior si es necesario
  }
};

export const getCategoriasEmpleado = (diccionario) => {
  return [
    { label: diccionario.Administrador, value: "Administrador" },
    { label: diccionario.Oficial, value: "Oficial" },
    { label: diccionario.Auxiliar, value: "Auxiliar" },
  ];
};

export const transformData = (data) => {
  return data.reduce((acc, item) => {
    acc[item.value] = {
      Roles: item.Roles.length > 0 ? item.Roles : [""],
    };
    return acc;
  }, {});
};

export const transformDataDesk = (rolesSeleccionados) => {
  return {
    desk: {
      Roles: rolesSeleccionados.length > 0 ? rolesSeleccionados : [""],
    },
  };
};
