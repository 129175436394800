import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import {  Route, Routes } from 'react-router-dom';
import { authRoles } from '../../auth/authRoles';
export const EmpleadosTabla = Loadable(lazy(() => import('./empleados/EmpleadosTabla')));

export const EmpleadoForm = Loadable(lazy(() => import('./empleados/EmpleadoForm')));
export const EstructuraForm = Loadable(lazy(() => import('./estructura/EstructuraForm')));

const EmpleadoFormModificar = Loadable(lazy(() => import('./empleados/EmpleadoFormModificar')));

const EstructuraFormModificar = Loadable(lazy(() => import('./estructura/EstructuraFormModificar')));
const EstructuraTabla = Loadable(lazy(() => import('./estructura/EstructuraTabla')));

const EcharSideco = Loadable(lazy(() => import('./informes/EcharSideco')));

const EcharEstructura = Loadable(lazy(() => import('./informes/EcharEstructura')));

const PruebasTabla = Loadable(lazy(() => import('./pruebas/PruebasTabla')));

const PruebaForm = Loadable(lazy(() => import('./pruebas/PruebaForm')));

const PruebaFormModificar = Loadable(lazy(() => import('./pruebas/PruebaFormModificar')));
const PruebaFormulario = Loadable(lazy(() => import('./pruebas/PruebaFormulario')));


const EvaluacionFormModificar = Loadable(lazy(() => import('./evaluaciones/EvaluacionFormModificar')));

const EvaluacionesTabla = Loadable(lazy(() => import('./evaluaciones/EvaluacionesTabla')));
const EvaluadosTabla = Loadable(lazy(() => import('./evaluaciones/EvaluadosTabla')));

const EvaluacionForm = Loadable(lazy(() => import('./evaluaciones/EvaluacionForm')));



const sidecoRoutes = [
  { path:'/listadoevaluaciones'  ,element: <EvaluacionesTabla />},
  { path:'/listadoevaluados',  element: <EvaluadosTabla />},
  
  { path:'/evaluacion', element:<EvaluacionForm/>},
  { path:'/evaluacionmodificar',  element: <EvaluacionFormModificar/>},
  { path:'/listadopruebas', element: <PruebasTabla />},
  { path:'/prueba', element: <PruebaForm />},
  { path:'/pruebamodificar',  element: <PruebaFormModificar/>},
  { path:'/pruebaformulario', element:<PruebaFormulario />},
  
  { path:'/listausuarios',  element: <EmpleadosTabla />},
  { path:'/empleado',  element:<EmpleadoForm /> ,auth:authRoles.sa},
  { path:'/empleadomodificar',  element: <EmpleadoFormModificar/>},
  { path:'/estructura',  element: <EstructuraForm />},
  { path:'/estructuramodificar',  element: <EstructuraFormModificar/>},
  { path:'/informes',  element: <EcharSideco/>},
  { path:'/info_estructura',  element: <EcharEstructura/>},
  { path:'/listaestructuras',  element: <EstructuraTabla/>}
]





export default sidecoRoutes;
