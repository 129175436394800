import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { Store } from "./redux/Store";
import routes from "./routes";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { LenguageStateProvider } from "./contexts/LenguageContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  const content = useRoutes(routes);
  const persistor = persistStore(Store);
  return (
    <PersistGate persistor={persistor}>
      <Provider store={Store}>
        <SettingsProvider>
          <MatxTheme>
            <AuthProvider>
              <ToastContainer />
              <LenguageStateProvider>{content}</LenguageStateProvider>
            </AuthProvider>
          </MatxTheme>
        </SettingsProvider>
      </Provider>
    </PersistGate>
  );
};

export default App;
