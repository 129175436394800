import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const GremiosTabla = Loadable(lazy(() => import('./GremiosTabla')));
const GremioDetalle = Loadable(lazy(() => import('./GremioDetalle')));
const AnadirGremio = Loadable(lazy(() => import('./AnadirGremio')));

const gremiosRoutes = [
  { path: '/proveedores', element: <GremiosTabla /> },
  { path: '/detalle_proveedores', element: <GremioDetalle /> },
  { path: '/detalle_proveedor/:Identificador', element: <GremioDetalle /> },
  { path: '/detalle_proveedor/:Identificador/:volver', element: <GremioDetalle /> },
  { path: '/addgremio', element: <AnadirGremio /> },
];

export default gremiosRoutes;
