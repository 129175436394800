import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { authRoles } from '../../auth/authRoles';
//import Inci_gremios  from './inci_gremios';

const Incidencias = Loadable(lazy(() => import('./Incidencias')));
const IncidenciaDetalle = Loadable(lazy(() => import('./IncidenciaDetalle')));
const AnadirIncidencia = Loadable(lazy(() => import('./AnadirIncidencia')));


const incidenciasRoutes = [
  { path: '/incidencias', element: <Incidencias /> },
  { path: '/incidencias/:IdComu', element: <Incidencias /> },
  { path: '/detalle_incidencia/:IdComu/:Identificador', element: <IncidenciaDetalle /> }, 
  { path: '/detalle_incidencia/:IdComu/:Identificador/:volver', element: <IncidenciaDetalle /> },
  { path: '/addincidencia/:IdComu', element: <AnadirIncidencia /> },
  { path: '/addincidencia', element: <AnadirIncidencia /> },
 // { path: '/inci_gremios', element: <Inci_gremios /> },
];

export default incidenciasRoutes;
