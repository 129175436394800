import Tooltip from "react-tooltip-lite";
import { useStore } from "react-redux";


export const Semaforito = ({
  rowdata,
  colorSeleccionado = null,
  textoExplicativo = null,
}) => {

  let activeLight = "";
  const randomNumber = Math.random();
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;


  if (
    colorSeleccionado===null ||
    (colorSeleccionado !== "red" &&
      colorSeleccionado !== "yellow" &&
      colorSeleccionado !== "green")
  ) {
    if (randomNumber < 0.33) {
      activeLight = "red";
    } else if (randomNumber < 0.66) {
      activeLight = "yellow";
    } else {
      activeLight = "green";
    }
    textoExplicativo = diccionario[activeLight];
  } else {
    activeLight = colorSeleccionado;
    textoExplicativo = textoExplicativo!=null
      ? `${diccionario[activeLight]}: ${textoExplicativo}`
      : diccionario[activeLight];
  }

  return (
    <Tooltip content={textoExplicativo}>
      <div className="traffic-light-container">
        <div className="traffic-light">
          <div
            id="stopLight"
            className={activeLight === "red" ? "bulb active" : "bulb"}
          ></div>
          <div
            id="slowLight"
            className={activeLight === "yellow" ? "bulb active" : "bulb"}
          ></div>
          <div
            id="goLight"
            className={activeLight === "green" ? "bulb active" : "bulb"}
          ></div>
        </div>
      </div>
    </Tooltip>
  );
};
