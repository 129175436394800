import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { authRoles } from '../../auth/authRoles';

const Usuarios = Loadable(lazy(() => import('./Usuarios')));
const UsuarioDetalle = Loadable(lazy(() => import('./UsuarioDetalle')));
const AnadirUsuario = Loadable(lazy(() => import('./AnadirUsuario')));
const RegristroHorario = Loadable(lazy(() => import('./RegristroHorario')));
const DocumentosUsuario = Loadable(lazy(() => import('../documentos/Documentos')));
const usuariosRoutes = [
  { path: '/usuarios', element: <Usuarios /> },
  { path: '/usuarios/:Identificador', element: <Usuarios /> },
  { path: '/detalle_usuario', element: <UsuarioDetalle /> },
  { path: '/detalle_usuario/:IdComu/:Identificador', element: <UsuarioDetalle /> },
  { path: '/detalle_usuario/:IdComu/:Identificador/:volver', element: <UsuarioDetalle /> },
  { path: '/addusuario/:Identificador', element: <AnadirUsuario /> },
  { path: '/registro_horario/:IdComu/:Identificador', element: <RegristroHorario /> },
  { path: '/detalle_usuario/:IdComu/:Identificador/documentos/:docusUsuario', element: <DocumentosUsuario /> },
];

export default usuariosRoutes;
