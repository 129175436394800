import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import {  Route, Routes } from 'react-router-dom';
import { authRoles } from '../../auth/authRoles';

const Logout = Loadable(lazy(() => import('./Logout')));

const logoutRoutes = [

  { path:'/logout',  element: <Logout/>},


]

export default logoutRoutes;