import React from 'react';
import ReactDOM from 'react-dom';
import html2pdf from 'html2pdf.js';

export const ListaContratos = ({ contrato, contratoActivo }) => {

  const handleDescargarContrato = (contrato) => {
    const Contenido = `
      <div className="contract">
        <div className="contract-header">
          <h1>Contrato de Servicios</h1>
          <p>Fecha: ${contrato.fechaInicio}</p>
        </div>
  
        <div className="contract-body">
          <p>
            Este contrato se celebra entre [Nombre del Cliente], en adelante denominado el "Cliente",
            y [Nombre de la Empresa], en adelante denominado el "Proveedor".
          </p>
  
          <p>1. Descripción de los servicios:</p>
          <p>El Proveedor se compromete a proporcionar los siguientes servicios al Cliente:</p>
          <ul>
            <li>${contrato.plan}</li>
            <li>${contrato.formapago}</li>
            <li>${contrato.precioSelected}</li>
            <li>${contrato.vecinos}</li>
          </ul>
  
          <p>2. Condiciones de pago:</p>
          <p>El Cliente pagará al Proveedor de la siguiente manera: [Detalles de pago].</p>
  
          <p>3. Duración del contrato:</p>
          <p>
            Este contrato tendrá una duración de [Número de meses/años] y entrará en vigencia a partir
            de [Fecha de inicio].
          </p>
        </div>
  
        <div className="signature">
          <p>Firma del Cliente: ________________________</p>
          <p>Firma del Proveedor: ______________________</p>
        </div>
      </div>
    `;

    const htmlElement = document.createElement('div');
    htmlElement.innerHTML = Contenido;

    html2pdf(htmlElement, {
      margin: 10,
      filename: 'Contrato.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    });
  };

  return (
    <div className="container mt-4">
      <h2 className="titulo-contratos">Contrato Actual:</h2>
      <hr />
      {contrato && (
        <div className="card my-2">
          <div className="card-body contratos" style={{ backgroundColor: 'rgba(0, 189, 255, 0.2)' }}>
            <p className="card-text">Plan: {contratoActivo.plan}</p>
            <p className="card-text">Precio: {contratoActivo.precioSelected}</p>
            <p className="card-text">Fecha de inicio: {contratoActivo.fechaInicio}</p>
            <p className="card-text">Fecha de fin: {contratoActivo.fechaFin ? contratoActivo.fechaFin : 'Indefinida'}</p>
            <p className="card-text">Forma de pago: {contratoActivo.formapago}</p>
            <p className="card-text">Vecinos: {contratoActivo.vecinos}</p>
            <p className="card-text">IBAN: {contratoActivo.iban}</p>

            <button
              className="btn btn-primary"
              onClick={() => handleDescargarContrato(contratoActivo)}
            >
              Descargar
            </button>
          </div>
        </div>
      )}

      <h2 className="titulo-contratos">Lista de Contratos Históricos</h2>
      <hr />
      {contrato && contrato.map((contrato, index) => (
        <div key={index} className="card my-2">
          <div className="card-body contratos">
            <p className="card-text">Plan: {contrato.plan}</p>
            <p className="card-text">Precio: {contrato.precioSelected}</p>
            <p className="card-text">Fecha de inicio: {contrato.fechaInicio}</p>
            <p className="card-text">Fecha de fin: {contrato.fechaFin}</p>
            <p className="card-text">Forma de pago: {contrato.formapago}</p>
            <p className="card-text">Vecinos: {contrato.vecinos}</p>
            <p className="card-text">IBAN: {contrato.iban}</p>

            <button
              className="btn btn-primary"
              onClick={() => handleDescargarContrato(contrato)}
            >
              Descargar
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

